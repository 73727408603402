import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Button,
  Label,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row, 
  Col,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, Field, change, formValueSelector, getFormValues, reset } from 'redux-form'
import {
  IoMdClose,
} from 'react-icons/io'
import moment from 'moment'
import { isEmpty, isEmptyObject } from '../../../util/common'
import { actions as locationActions } from '../../../modules/location'
import Product from '../../../components/molecules/Product'
import { actions as reservationActions } from '../../../modules/reservation'
import { actions as productDetailActions } from '../../../modules/productDetail'
import { actions as favoritesActions } from '../../../modules/favorites'
import { actions as notificationsAction } from '../../../modules/informations'
import DatePicker from 'react-datepicker'
import { scroller } from 'react-scroll'
import { IMG_URL } from '../../../constants/api-config'
import { media } from '../../../components/atomics/Media'
import { withRouter } from 'react-router'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'

class Reservation extends Component {
  componentDidMount() {
    const { favorite_id } = this.props.match.params
    this.props.set('product_id', favorite_id)

    // 店舗情報、商品情報取得
    this.props.flowStock(favorite_id)
  }

  getStoreLink = (store) => {
    const url = store.dept_hp_url
    return window.location.assign(url)
  }

  render() {
    const {
      product,
      stores,
      stocked_stores,
      set,
      formValues,
      department_id,
      schedule_date,
      judgeReservationLimit,
      link,
      resetForm,
      isWeb,
      location,
      i18n,
    } = this.props

    // トップ画面に遷移
    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    // 商品検索画面に遷移
    const onClickBack = () => {
      link('/items')
    }

    // 商品詳細画面に遷移
    const onProduct = product => e => {
      this.props.link(`/items/${product.product_cd}`)
      e.stopPropagation()
    }

    if (isEmptyObject(product)) {
      return ''
    }
    let schedule_dates = []
    for (let i = 0; i < 7; i++) {
      schedule_dates.push(moment().add(i, 'days'))
    }

    // 取り置き依頼ボタン 非活性 有効無効関数
    const isDisabledReserveButton = () => {
      if (isEmpty(department_id) || moment().format('YYYY-MM-DD') > schedule_date) {
        return true
      } 
      return false
    }

    const isStocked = (store) => {
      return stocked_stores.find(stocked_store => stocked_store.id === store.id) !== void(0)
    }

    // 営業日判定
    const isBusinessDay = (store) => {
      return store.department_daily_budgets.length > 0
    }

    const scrollCalendar = () =>{
      scroller.scrollTo('scheduled-date', 
        {
          duration: 800,
          delay: 0,
          smooth: 'true',
          offset: -70,
        }
      )
    }

    // 営業日をセットする
    const setIncludeDates = (store) =>{
      let budget_dates = store.department_daily_budgets.map(item => moment(item.budget_date).toDate())
      set('include_dates', budget_dates)
    }
    // 取り置き日をセットする
    const setScheduleDates = (store) =>{
      let sortArray = [2, 3, 4, 5, 6, 7, 1, 0];
      let schedule_date = store.department_daily_budgets.sort((a, b) => {
        for (let i = 0; i <= 7; i++) {
          let aI = moment(a.budget_date).isSame(moment().add(sortArray[i], 'days').format('YYYY-MM-DD'))
          let bI = moment(b.budget_date).isSame(moment().add(sortArray[i], 'days').format('YYYY-MM-DD'))
          aI = Number(aI)
          bI = Number(bI)
          if (aI > bI) return -1
          if (aI < bI) return 1
        }
        return 0
      })[0].budget_date
      // 日にち選択
      set('schedule_date', moment(schedule_date).format('Y-MM-DD'))
    }

    // 支店をクリックした処理
    const onClickStore = (store) =>{
      setIncludeDates(store)
      setScheduleDates(store)
      scrollCalendar()
    }

    // カレンダーの選択
    const onChangeScheduleDates = (date) =>{
      if (date === null) date = schedule_date
      set('schedule_date', moment(date).format('Y-MM-DD'))
    }

    // 在庫のある店舗
    return (
      <Content className="reservation">
        <div  className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && <BreadcrumbItem><a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a></BreadcrumbItem>}
            <BreadcrumbItem><a onClick={onProduct(product)}>商品詳細</a></BreadcrumbItem>
            <BreadcrumbItem active>在庫確認</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">STOCK CHECKING</div>
        <div className="contents-subtitle">在庫確認</div>
        <Container className="contents-wrap">
          <Row xs={1} lg={2}>
            <Col>
              <Product
                product={product}
                headder={(
                  <div className="close">
                    <IoMdClose size="2em"/>
                  </div>
                )}
              />
              <div className="caption">
                店舗の在庫状況は常に変動しております、実際の在庫数と異なる場合がございますので、あらかじめご了承ください。<br/>
                <br/>
                在庫がある店舗ではお取り置きサービスのご利用が可能です。<br/>
                お取り置きのお申込み後、商品確認を行います。<br/>
                お取り置きは1週間を上限とさせていただきます。また、お取り置きは2本以内とさせていただきます。<br/>
                <br/>
                詳細な在庫検索やお取り寄せは、ご希望の店舗までお問い合わせください。
              </div>
            </Col>
            <Col>
              <Label className="heading-stores-title">お取り置きを希望する店舗</Label>
              <div className="stores">
                {stores.map(store => (
                  <div key={store.id}>
                    {/* {isStocked(store) ? (
                      <Field id={`store_${store.id}`} name="department_id" component="input" type="radio" value={store.id} format={(value, name) => Number(value)} onClick={scrollCalendar}/>
                    ) : ''} */}
                    {isStocked(store) && isBusinessDay(store) ? (
                      <Field id={`store_${store.id}`} name="department_id" component="input" type="radio" value={store.id} format={(value, name) => Number(value)} onClick={() =>onClickStore(store)}/>
                    ) : ''}
                    <label htmlFor={`store_${store.id}`}> 
                      {store.display_name}
                    </label>
                    <button onClick={() => this.getStoreLink(store)} className="icon-text">店舗詳細</button>
                    <a onClick={() => this.getStoreLink(store)}><img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/></a>
                  </div>
                ))}
              </div>
              <div className="scheduled-date">
                <Label className="heading-plans-title">ご来店予定日</Label>
                <div className="caption-plans">
                  （ご来店予定日まで商品をお取り置きいたします）<br/>
                </div>
                <div className="date">
                  <div>
                    <DatePicker
                      selected={formValues && formValues.schedule_date && moment(formValues.schedule_date).toDate()}
                      onChange={date => onChangeScheduleDates(date)}
                      locale="ja"
                      includeDates={formValues && formValues.include_dates}
                      dateFormatCalendar="yyyy年M月"
                      dateFormat="yyyy/MM/dd"
                      className="date-picker"
                    />
                  </div>
                  <div className="date-icon-position">
                    <img src="/assets/menu/Icon feather-calendar.svg" className="date-icon"/>
                  </div>
                </div>
              </div>
              <div className="request">
                <Button color="primary" block onClick={e => judgeReservationLimit(formValues, product)} disabled={isDisabledReserveButton()}>
                  取り置き依頼をする
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <hr className="list-pc-line"></hr>
        <div  className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && <BreadcrumbItem><a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a></BreadcrumbItem>}
            <BreadcrumbItem><a onClick={onProduct(product)}>商品詳細</a></BreadcrumbItem>
            <BreadcrumbItem active>在庫確認</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { favorite_id } = props.match.params
  const selector = formValueSelector('reservation')

  return {
    department_id: selector(state, 'department_id'),
    schedule_date: selector(state, 'schedule_date'),
    formValues: getFormValues('reservation')(state),
    product: state.reservation.registrationProduct || {},
    stores: state.master.stores && state.master.stores.filter(store => store.mypage_stock_search_flag),
    stocked_stores: state.reservation.stores,
    isWeb: state.location.isWeb,
    referencePath: state.location.referencePath || '',
    referenceName: state.location.referenceName || '',
  }
}
const mapDispatchToProps = dispatch => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    flowStock: (id) => {
      dispatch(reservationActions.flowStock(id))
    },
    set: (key, value) => {
      dispatch(change('reservation', key, value))
    },
    reservationProductStorage: (formValues) => {
      dispatch(reservationActions.reservationProductStorage(formValues))
    },
    judgeReservationLimit: (formValues, product) => {
      dispatch(favoritesActions.add(product))
      dispatch(reservationActions.judgeReservationLimit({formValues, product}))
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'reservation',
    initialValues: {
      product_id: '',
      department_id: '',
      schedule_date: null,
      include_dates: [],
      situation: '0',
    }
  })(
    withTranslation()(
      Reservation
    )
  )
)

const Content = styled.div`
  margin: 0 5px;

  .caption{
    font-size: 14px;
    margin: 0 20px 20px;
    padding-top: 20px;
    text-align: left;
    border-top: 1px solid #707070;
  }

  .contents-wrap {
    background-color: #ffffff;
    margin-bottom: 200px;
  }

  .product {
    .close {
      text-align: right;
    }
  }

  .stores {
    background-color: #ffff;
    position:relative
    .icon {
      cursor:pointer;
      font-size: 19px;
      margin-right: 20px;
      color: #939393;
    }
  }
  .icon-text {
    display:none;
  }

  .stores {
    border-top: 1px solid #707070;

    > div {
      font-size: 15px;
      border-bottom: solid 1px #707070;
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        margin: 0;
        padding: 0;
        padding-left: 43px;
        vertical-align: middle;
        position: relative;
        width: 85%;
      }
      input[type="radio"] {
        display: none;

        + label:before {
          content: "";
          display: block;
          position: absolute;
          top: 2px;
          left: 0;
          width: 18px;
          height: 18px;
          border: 1px solid #707070;
          border-radius: 50%;
          cursor:pointer;
        }

        &:checked + label:after {
          content: "";
          display: block;
          position: absolute;
          top: 7px;
          left: 5px;
          width: 8px;
          height: 8px;
          background: #707070;
          border-radius: 50%;
          cursor:pointer;
        }
      }
    }
  }

  .btn {
    height: 60px;
  }

  .scheduled-date {
    margin-top: 30px;
    text-align: center;

    label {
      margin-bottom: 20px;
      display: block;
      font-size: 19px;
    }

    .react-datepicker {
      font-size: 19px;
      background-color: #EBEBEB;
      border-radius: 0;
      width: 100%;
      left: -45px;
    }
    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__header {
      border: 0px;
      background-color: #FFFFFF;
    }
    .react-datepicker__current-month {
      background-color: #FFFFFF;
      font-size: 21px;
      font-weight: normal;
      margin-top: 5px;
      margin-bottom: 13px;
    }
    .react-datepicker__navigation {
      top: 22px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 12vw;
      max-width: calc(576px / 8);
      height: 12vw;
      max-height: calc(576px / 8);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 1px;
    }

    .react-datepicker__day--selected{
      background-color: #C1C1B8;
    }
    .react-datepicker__day--disabled{
      color: black;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: #C1C1B8;
    }
    .react-datepicker__day--outside-month {
      color: #AAAAAA;
    }
  }

  .date {
    position: relative;
    width: 250px;
    margin: 0 auto;
  }

  .date-picker {
    border: 1px solid #DDDDDB;
    width: 250px;
    height: 50px;
    z-index: 1;
    text-indent: 20px;
  }

  .date-icon {
    width: 20px;
    height: 20px;
    z-index: 2;
  }

  .date-icon-position {
    position: absolute;
    right: 13px;
    bottom: 16px;
  }

  .heading-stores-title {
    display: none;
  }

  ${media.sm`
    .contents-wrap {
      .col {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  `}

  ${media.md`
  .stores {
    display: inline;
    .icon {
      display:none;
    }
  }

  .scheduled-date {
    padding-top: 40px;
    .react-datepicker {
    font-size: 19px;
    background-color: #EBEBEB;
    border-radius: 0;
    width: 100%;
    left: -110px;
    }
  }

  .icon-text {
    display: inline;
    font-size: 13px;
    width: 92px;
    height: 40px;
    border: 1px solid #FFFFFF;
    background: #FFFFFF;
    color: #C1C1B8;
    margin: 0px -30px;
  }
  `}

  ${media.lg`
    .contents-wrap {
      width: 1280px;

      .row {
        padding: 80px 80px 60px 60px;
        margin-bottom: -60px;
      }

      .col {
        width: 520px;
        padding-right: 60px;
        padding-left: 60px;
      }
    }

    .stores {
        > div {
          font-size: 13px;
          height: 37px;
          padding: 22px 0;
          border-bottom-width: thin;
          border-color: #DDDDDB;
        }

        &:checked + label:after {
          left: 4px;
          width: 9px;
          height: 9px;
          background: #000000;
        }
      }

      .icon-text {
        padding-right: 20px;
      }

    .heading-stores-title {
      font-size: 16px;
      font-weight:bolder; 
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      
      .stores {
        width: 520px;
      }
    }

    .date {
      position: relative;
      width: 340px;
      margin: 0px;
    }
  
    .date-picker {
      position: fix;
      border: 1px solid #DDDDDB;
      width: 340px;
      height: 50px;
      z-index: 1;
      margin-right: auto;
      text-indent: 20px;
    }

    .date-icon {
      width: 20px;
      height: 20px;
      z-index: 2;
    }
  
    .date-icon-position {
      position: absolute;
      right: 20px;
      bottom: 16px;
    }

    .caption{
      border-top: none;
      font-size: 13px;
      padding-top: 30px;
    }

    .caption-plans{
      text-align: left;
      --display: none;
    }

    .scheduled-date {
      .heading-plans-title {
        font-size: 16px;
        font-weight:bolder; 
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        text-align: left;
        margin-bottom: 30px;
      }
    }
    .request {
      padding-top: 40px;
    }

    .btn-primary {
      cursor:pointer;
      color: #EFEFEF;
      background-color: #707070;
      border-color: #707070;
      margin: 0px;
      width: 100%;
    }
    .btn-primary:not(:disabled):not(.disabled).active {
      background-color: #707070;
      border-color: #707070;
      color: #ffffff;
    }
    .btn-primary:not(:disabled):not(.disabled):active {
      background-color: #707070;
      border-color: #707070;
      color: #ffffff;
    }
    .btn-primary:not(:disabled):not(.disabled).hover {
      background-color: #707070;
      border-color: #707070;
      color: #ffffff;
    }
    .btn-primary:not(:disabled):not(.disabled):hover {
      background-color: #707070;
      border-color: #707070;
      color: #ffffff;
    }
    .btn-primary:not(:disabled):not(.disabled).focus {
      background-color: #707070;
      border-color: #707070;
      color: #ffffff;
    }
    .btn-primary:not(:disabled):not(.disabled):focus {
      background-color: #707070;
      border-color: #707070;
      color: #ffffff;
    }
    
    .btn-primary.disabled, .btn-primary:disabled {
      cursor:pointer;
      color: #EFEFEF;
      background-color: #707070;
      border-color: #707070;
      margin: 0px;
      width: 100%;
    }
  `}
`
