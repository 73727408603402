import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, call } from 'redux-saga/effects'
import api from './api'
import { SELLS } from '../constants/api-url'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    getPurchaseHistory: payload => payload,
    getPurchaseHistoryNext: payload => payload,
    addPurchaseHistory: (histories, meta) => ({histories, meta}),
  },
  { prefix: '9999/purchaseHistory'},
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  purchaseHistories: [],
  purchaseHistoriesMeta: {
    current_page: 0,
    last_page: 1,
  },
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.getPurchaseHistory]: (state, action) => ({
    ...state,
    purchaseHistories: initialState.purchaseHistories,
    purchaseHistoriesMeta: initialState.purchaseHistoriesMeta,
  }),
  [actions.addPurchaseHistory]: (state, action) => ({
    ...state,
    purchaseHistories: [
      ...state.purchaseHistories,
      ...action.payload.histories
    ],
    purchaseHistoriesMeta: action.payload.meta,
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* purchaseHistorySaga() {
//  yield takeEvery(actions.loadStoreMaster, loadStoreMaster)
  yield takeEvery(actions.getPurchaseHistory, getPurchaseHistory)
  yield takeEvery(actions.getPurchaseHistoryNext, getPurchaseHistory)
}

// 購入履歴取得処理
function* getPurchaseHistory(action) {
  const { 
    purchaseHistory: {
      purchaseHistoriesMeta,
    }
  } = yield select()

  let page = purchaseHistoriesMeta.current_page + 1;
  if (page > purchaseHistoriesMeta.last_page) {
    yield put(actions.addPurchaseHistory([], {}))
    return;
  }

  const result = yield call(api, {
    url: SELLS,
    method: 'GET',
    request: {
      page,
    }
  })
  if (!result.isSuccess) {
    yield put(actions.addPurchaseHistory([], {}))
    return
  }

  yield put(actions.addPurchaseHistory(result.json.sells, result.json.meta))
}



