import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Button,
  Row,
  Col
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { isEmptyObject } from '../../../util/common'
import {
  IoMdClose,
} from 'react-icons/io'
import * as Common from '../../../constants/common'
import { actions as locationActions } from '../../../modules/location'
import Product from '../../../components/molecules/Product'
import { actions as reservationActions } from '../../../modules/reservation'
import { actions as productDetailActions } from '../../../modules/productDetail'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'

class ReservationNotlogged extends Component {
  componentDidMount() {
    const { favorite_id } = this.props.match.params
    // 店舗情報、商品情報読み込み
    this.props.flowStock(favorite_id)
  }

  // ショップリスト　デバイスによってリンク変更
  linkShopList = () => {
    let url
    if(this.props.i18n === 'en'){
      url = `${HP_EN_URL}/shops/four-nines-shop/list/`
    }else{
      url = `${HP_URL}/shops/four-nines-shop/list/`
    }
    return window.location.assign(url)
  }

  render() {
    const {
      productDetail,
      link,
      stocked_stores,
    } = this.props

    const storeTel = (store) => {
      return `tel:${store.phone_num1}${store.phone_num2}${store.phone_num3}`
    }

    if (isEmptyObject(productDetail)) {
      return ''
    }

    return (
      <Content className="reservation-not-logged">
        <div className="title">在庫のある店舗リスト</div>
        <div className="caption">
          店舗の在庫状況は常に変動しており、<br />
          実際の在庫状況とは異なる場合がございます。<br />
          <br />
          詳細な在庫確認やお取り置きは、<br />
          ご希望の店舗までお問い合わせください。
        </div>
        <Product
          product={productDetail}
          headder={(
            <div className="close">
              <IoMdClose size="2em"/>
            </div>
          )}
        />
        <div className="stores">
          {stocked_stores.map(store => (
            <div key={store.id}>
              <div className="store" >
                <a href={store.dept_hp_url}>{store.display_name}</a></div>
              <div><a className='phone' href={storeTel(store)}>{store.phone_num1}{'-'}{store.phone_num2}{'-'}{store.phone_num3}</a></div>
            </div>
          ))}
        </div>
        <div className='message'>ご希望の店舗にお電話にてお取り置きを申し付けてください</div>
        <div className='message'>上記の店舗以外でもお取り置きを承れる場合がございます。ショップリストよりお問い合わせください。</div>
        <div className='message'><a className="shopList" onClick={() => this.linkShopList()}>ショップリスト</a></div>
        <div className="footer">
          {/* <div className="footer-text">
            上記期間内までにご来店の無い場合<br/>
            お取り置きはキャンセルとさせていただきます。
          </div> */}
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { favorite_id } = props.match.params
  return {
    productDetail: state.favorites.products.find(product => product.id == favorite_id),
    stores: state.master.stores || [],
    stocked_stores: state.reservation.stores,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    flowStock: (id) => {
      dispatch(reservationActions.flowStock(id))
    },
    set: (key, value) => {
      dispatch(change('not_logged', key, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'not_logged',
    initialValues: {
      id: '',
    }
  })(
    withTranslation()(
      ReservationNotlogged
    )
  )
)

const Content = styled.div`
  margin: 0 5px;

  .title{
    font-size: 1.5rem;
    text-align: center;
    margin: 0 15px;
    padding: 14px;
    border-bottom: 1px solid ${Common.COLOR_LIGHT_GLAY};
  }
  .caption{
    font-size: 0.8rem;
    margin: 1rem 15px;
    text-align: center;
  }

  .product {
    margin-bottom: 20px;

    .close {
      text-align: right;
    }
  }
  .phone{
    padding-left: 10px;
  }

.message{
  padding: 10px 25px;
  text-align: center;
}

  .stores {
    padding: 0 10px;
    label {
      padding: 0;
      margin: 0;
    }
    > div {
      font-size: 1rem;
      border-bottom: solid 1px ${Common.COLOR_LIGHT_GLAY};
      padding: 15px;
    }
  }

  .footer{
    padding: 20px;
  }

  .btn {
    margin-top: 20px;
    padding: 1rem 0;
    font-size: 1.2rem;
    background-color: ${Common.COLOR_WHITE};
    border-color: ${Common.COLOR_GLAY};
    color: ${Common.FONT_COLOR};
}
.shopList {
  cursor: pointer;
}
`
