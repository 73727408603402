import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap'
import {actions as errorAction} from '../../modules/error'
import {isEmpty} from '../../util/common'
import {FaArrowAltCircleRight} from 'react-icons/fa'
import nl2br from 'react-nl2br'

/**
 * エラー表示モーダル
 */
class Error extends Component {
  
  render() {
    const {isOpen, errorMessages, note = ''} = this.props
    let errorString = ''

    //エラーメッセージが配列の場合、一度文字列に変換
    if (Array.isArray(errorMessages)) {
      errorMessages.map(error => {
        errorString += error + '\n'
      })
    } else {
      errorString = errorMessages
    }

    return (
      <section id="error">
        <Modal
          backdrop="static"
          className="error-modal"
          isOpen={isOpen}
          toggle={() => {
            this.props.close()
          }}
          style={{minHeight: '50vh', minWidth: '80vw', paddingTop: '15vh'}}
        >
          <ModalBody style={{minHeight: '50vh', minWidth: '80vw'}}>
            <div
              className={'text-center'}
              style={{
                fontSize: '1.5rem',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                paddingTop: '15vh'
              }}
            >
              <div style={{display: 'block', paddingTop: '4vh'}}>
                {nl2br(errorString)}
              </div>
              <hr/>
              <div>
                {!isEmpty(note) &&
                  <span>
                    <FaArrowAltCircleRight/>{note}
                  </span>
                }
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{textAlign: 'center', borderTop: '0 solid'}}>
            <Button block style={{boxShadow:'0px 4px ' + '#707070', padding: '0.5rem 1rem', fontSize:'1.2rem'}} onClick={() => this.props.close()}>閉じる</Button>
          </ModalFooter>
        </Modal>
      </section>
    )
  }
}

Error.propTypes = {
  error: PropTypes.shape({
    isOpen: PropTypes.bool,
    errorMessages: PropTypes.string,
    note: PropTypes.string,
  }),
}

const mapStateToProps = state => {
  return {
    isOpen: state.error.isOpen,
    errorMessages: state.error.errorMessages,
    note: state.error.note,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    close: () => {
      dispatch(errorAction.close())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error)
