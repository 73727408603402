import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import styled from 'styled-components'
import {
  IoMdClose,
} from 'react-icons/io'

class ComponentModal extends Component {
  render() {
    const {
      isOpen = false,
      onClose = () => {},
      className = '',
      size = 'lg',
      children
    } = this.props

    return (
      <StyledModal isOpen={isOpen} toggle={onClose} className={className} size={size}>
        <ModalHeader toggle={onClose}></ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
      </StyledModal>
    )
  }
}

export default ComponentModal

const StyledModal = styled(Modal)`
  .modal-header {
    padding: 0;

    .close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      visibility: visible;
      margin: 0;
    }
  }

  .modal-body {
    background-color: #F5F5F2;
    padding: 0;
    margin-top: -1px;
  }
`
