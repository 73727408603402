import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import {
  Container,
  Badge,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { IMG_URL } from '../../constants/api-config'

class ShopListModal extends Component {

  // 店舗リンク　デバイスによってリンク変更
  storeLink = (param) => {
    const url = 'https://www.fournines.co.jp'

    switch(param){
      // フォーナインズ 銀座本店
      case 'ginza':
        url +=　'/shoplist/detail268.html';
        break;
      // フォーナインズ 銀座並木通り Salon
      case 'ginznamikidoori':
        url +=　'/shoplist/detail269.html';
        break;
      // フォーナインズ GINZA SIX店
      case 'ginzasix':
        url +=　'/shoplist/detail638.html';
        break;
      // フォーナインズ 伊勢丹新宿店メンズ館
      case 'isetan':
        url +=　'/shoplist/detail271.html';
        break;
      // フォーナインズ　丸の内 イセタンサローネ メンズ店 
      case 'marunouti':
        url +=　'/shoplist/detail485.html';
        break;
      // フォーナインズ　青山店
      case 'aoyama':
        url +=　'/shoplist/detail273.html';
        break;
      // フォーナインズ 渋谷スクランブルスクエア店
      case 'scramblesquare':
        url +=　'/shoplist/detail908.html';
        break;
      // フォーナインズ 玉川髙島屋S・C店
      case 'tamakawatakashima':
        url +=　'/shoplist/detail272.html';
        break;
      // フォーナインズ 横浜タカシマヤ店
      case 'yokohamatakashimaya':
        url +=　'/shoplist/detail275.html';
        break;
      // フォーナインズ 大宮店
      case 'oomiya':
        url +=　'/shoplist/detail450.html';
        break;
      // 999.9 selected by HASHIMOTO
      case 'hashimoto':
        url +=　'/shoplist/detail277.html';
        break;
      // 999.9 selected by GALA
      case 'gala':
        url +=　'/shoplist/detail795.html';
        break;
      // フォーナインズジェイアール名古屋タカシマヤ店
      case 'nagoyatakashimaya':
        url +=　'/shoplist/detail279.html';
        break;
      // 999.9 selected by OPTIQUE S.S 城西
      case 'optiquejousai':
        url +=　'/shoplist/detail278.html';
        break;
      // 999.9 selected by OPTIQUE S.S 栄
      case 'optiquesakae':
        url +=　'/shoplist/detail641.html';
        break;
      // 999.9 selected by Stella OIKE
      case 'stellaoike':
        url +=　'/shoplist/detail281.html';
        break;
      // 999.9 selected by Stella KARASUMA
      case 'stellakarasuma':
        url +=　'/shoplist/detail282.html';
        break;
      // フォーナインズ 大阪タカシマヤ店
      case 'osakatakashimaya':
        url +=　'/shoplist/detail285.html';
        break;
      // フォーナインズ 梅田 ルクアイーレ店
      case 'umeda':
        url +=　'/shoplist/detail283.html';
        break;
      // フォーナインズ 心斎橋店
      case 'shinsaibashi':
        url +=　'/shoplist/detail284.html';
        break;
      // 999.9 selected by SOCIE HASHIZUME
      case 'sociehashizume':
        url +=　'/shoplist/detail280.html';
        break;
      // 999.9 selected by KAIROS
      case 'kairos':
        url +=　'/shoplist/detail286.html';
        break;
      // 999.9 selected by HAYASHI-MEGANE
      case 'hayashimegane':
        url +=　'/shoplist/detail287.html';
        break;
      // 999.9 selected by GLABBISH
      case 'glabbish':
        url +=　'/shoplist/detail288.html';
        break;
      // 999.9 selected by JOC
      case 'joc':
        url +=　'/shoplist/detail289.html';
        break;
      // 999.9 feelsun イセタン羽田ストア（メンズ）ターミナル1
      case 'haneda1':
        url +=　'/shoplist/detail274.html';
        break;
      // 999.9 feelsun イセタン羽田ストア（メンズ）ターミナル2
      case 'haneda2':
        url +=　'/shoplist/detail428.html';
        break;
      // フォーナインズ シンガポール髙島屋S.C.店
      case 'singapore':
        url +=　'/shoplist/detail477.html';
        break;
      // フォーナインズ THE GALLERYキャピトル ピアッツァ店
      case 'thegallery':
        url +=　'/shoplist/detail858.html';
        break;
    }
    return window.location.assign(url)
  }

  render() {
    const {
    } = this.props

    return (
      <Content>
        <div>
          <div className="title">
            Four Nines Shop List
          </div>
          <div className="note">
            <Badge color="secondary">FNM</Badge> Four Nines Membersの全ての機能、（WEBからのお取り置き、購入履歴閲覧など）が使用できる店舗です。
          </div>

          <div className="store">
            中央区
            <div className="storename">
            フォーナインズ 銀座本店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('ginza')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-3535-4949">03-3535-4949</a></div>
          </div>

          <div className="store">
            中央区
            <div className="storename">
            フォーナインズ 銀座並木通り Salon
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('ginznamikidoori')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-6218-4949">03-6218-4949</a></div>
          </div>

          <div className="store">
            中央区
            <div className="storename">
              フォーナインズ GINZA SIX店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('ginzasix')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/> 
              </a>
            </div>
            <div><a href="tel:03-6264-5049">03-6264-5049</a></div>
          </div>

          <div className="store">
            新宿区
            <div className="storename">
            フォーナインズ 伊勢丹新宿店メンズ館
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('isetan')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-3350-7649">03-3350-7649</a></div>
          </div>

          <div className="store">
            千代田区
            <div className="storename">
            フォーナインズ　丸の内 イセタンサローネ メンズ店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('marunouti')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-6273-4749">03-6273-4749</a></div>
          </div>

          <div className="store">
            港区
            <div className="storename">
            フォーナインズ　青山店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('aoyama')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-6419-1349">03-6419-1349</a></div>
          </div>

          <div className="store">
            渋谷区
            <div className="storename">
            フォーナインズ 渋谷スクランブルスクエア店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('scramblesquare')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-6712-5949">03-6712-5949</a></div>
          </div>

          <div className="store">
            世田谷区
            <div className="storename">
            フォーナインズ 玉川髙島屋S・C店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('tamakawatakashima')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-5491-2649">03-5491-2649</a></div>
          </div>

          <div className="store">
            横浜市
            <div className="storename">
            フォーナインズ 横浜タカシマヤ店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('yokohamatakashimaya')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:045-313-3049">045-313-3049</a></div>
          </div>

          <div className="store">
            さいたま市
            <div className="storename">
            フォーナインズ 大宮店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('oomiya')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:048-782-4449">048-782-4449</a></div>
          </div>

          <div className="store">
            つくば市
            <div className="storename">
            999.9 selected by HASHIMOTO
              <a onClick={() => this.storeLink('hashimoto')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:029-852-4949">029-852-4949</a></div>
          </div>

          <div className="store">
            中央区
            <div className="storename">
            999.9 selected by GALA
              <a onClick={() => this.storeLink('gala')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-6262-2839">03-6262-2839</a></div>
          </div>

          <div className="store">
            名古屋市
            <div className="storename">
            フォーナインズジェイアール名古屋タカシマヤ店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('nagoyatakashimaya')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:052-566-3949">052-566-3949</a></div>
          </div>

          <div className="store">
            名古屋市
            <div className="storename">
              999.9 selected by OPTIQUE S.S 城西
              <a onClick={() => this.storeLink('optiquejousai')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:052-524-4100">052-524-4100</a></div>
          </div>

          <div className="store">
            名古屋市
            <div className="storename">
            999.9 selected by OPTIQUE S.S 栄
              <a onClick={() => this.storeLink('optiquesakae')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:052-265-9549">052-265-9549</a></div>
          </div>

          <div className="store">
            京都市
            <div className="storename">
            999.9 selected by Stella OIKE
              <a onClick={() => this.storeLink('stellaoike')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:075-257-0064">075-257-0064</a></div>
          </div>

          <div className="store">
            京都市
            <div className="storename">
            999.9 selected by Stella KARASUMA
              <a onClick={() => this.storeLink('stellakarasuma')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:075-257-5353">075-257-5353</a></div>
          </div>

          <div className="store">
            大阪市
            <div className="storename">
            フォーナインズ 大阪タカシマヤ店
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('osakatakashimaya')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:06-6630-8649">06-6630-8649</a></div>
          </div>

          <div className="store">
            大阪市
            <div className="storename">
            フォーナインズ 梅田 ルクアイーレ店
              <a onClick={() => this.storeLink('umeda')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:06-6485-7949">06-6485-7949</a></div>
          </div>

          <div className="store">
            大阪市
            <div className="storename">
            フォーナインズ 心斎橋店
              <a onClick={() => this.storeLink('shinsaibashi')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:06-6244-4949">06-6244-4949</a></div>
          </div>

          <div className="store">
            金沢市
            <div className="storename">
            999.9 selected by SOCIE HASHIZUME
              <a onClick={() => this.storeLink('sociehashizume')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:076-263-0049">076-263-0049</a></div>
          </div>
    
          <div className="store">
            岡山市
            <div className="storename">
            999.9 selected by KAIROS
              <a onClick={() => this.storeLink('kairos')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:086-227-4949">086-227-4949</a></div>
          </div>
   
          <div className="store">
            防府市
            <div className="storename">
            999.9 selected by HAYASHI-MEGANE
              <a onClick={() => this.storeLink('hayashimegane')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:0835-24-8849">0835-24-8849</a></div>
          </div>

          <div className="store">
            松山市
            <div className="storename">
            999.9 selected by GLABBISH
              <a onClick={() => this.storeLink('glabbish')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:089-941-2115">089-941-2115</a></div>
          </div>

          <div className="store">
            福岡市
            <div className="storename">
            999.9 selected by JOC
              <a onClick={() => this.storeLink('joc')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:092-718-3077">092-718-3077</a></div>
          </div>

          <div className="store">
            大田区
            <div className="storename">
            999.9 feelsun イセタン羽田ストア（メンズ）ターミナル1
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('haneda1')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-5757-8700">03-5757-8700</a></div>
          </div>

          <div className="store">
            大田区
            <div className="storename">
            999.9 feelsun イセタン羽田ストア（メンズ）ターミナル2
              <Badge color="secondary" className="fnm">FNM</Badge>
              <a onClick={() => this.storeLink('haneda2')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:03-6428-8800">03-6428-8800</a></div>
          </div>

          <div className="store">
            シンガポール
            <div className="storename">
            フォーナインズ シンガポール髙島屋S.C.店
              <a onClick={() => this.storeLink('singapore')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:+65-6694-0049">+65-6694-0049</a></div>
          </div>

          <div className="store">
            シンガポール
            <div className="storename">
            フォーナインズ THE GALLERYキャピトル ピアッツァ店
              <a onClick={() => this.storeLink('thegallery')}>
                <img src={`${IMG_URL}/img/ico/yajirushi_2.png`} className="icon"/><br/>
              </a>
            </div>
            <div><a href="tel:+65-6245-8949">+65-6245-8949</a></div>
          </div>

        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(ShopListModal)
  )
)

const Content = styled.div`
.fnm {
  size: 14px;
  position: absolute;
  right: 49px;
}

.icon {
  cursor:pointer;
  position: absolute;
  size: 2px;
  right: 22px;
  color: #939393;
}
+ .shoplist {
  border-top: 0;
}

.store {
  border-top: 1px solid #D3D3D3;
  padding: 12px 22px;
  font-size: 10px;
  color: #606060;
}

.storename {
  font-size: 13px;
  font-family: ヒラギノ角ゴ Pro
  width: 78%;
  color: #000000;
}

.title {
  padding: 10px;
  text-align: center;
  font-size: 20px;
  font-family: ヒラギノ角ゴ Pro
}

.note {
  margin-left:7px
  margin-bottom: 22px;
  text-align: center;
  font-size: 10px;
  font-family: ヒラギノ角ゴ Pro
  display: inline-block
  width: 95%;
}

.comment {
  border-top: 1px solid #D3D3D3;
  padding: 24px 32px 40px;
  font-size: 14px;
}
}
`
