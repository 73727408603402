import { API_URL } from './api-config'

export const SSO_API = `${API_URL}/makeshop/api/openid`
export const SSO_CLIENT_ID = '290c608d13e93724a39730cb736bb9f908300c3c'
export const SSO_CLIENT_SECRET = 'b60b1b867408496c3f9e3a75304c2518c184e1e7'
export const SSO_CALLBACK_URL = `${window.location.protocol}//${window.location.host}/login/callback`

export const SSO_LOGIN = 'https://shop21.makeshop.jp/api/openid/login/'
export const SSO_ACCESS_TOKEN = `${SSO_API}/access_token.html`
export const SSO_USER_LOGIN_INFO = `${SSO_API}/user_login_info.html`


