import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, call, takeLatest, take } from 'redux-saga/effects'
import api from './api'
import { WP_URL } from '../constants/api-config'
import {
  WP_TOP,
  WP_MODEL_CATALOG,
} from '../constants/api-url'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions({
  top: () => ({}),
  modelWordpress: () => ({}),
  set: (key, value) => ({key, value}),
})

/*********************************
 * REDUCER
 ********************************/
const initialState = {
  top: [],
  modelWordpress: [],
}
const reducer = handleActions({
  [actions.set]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value
  })
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* wpSaga() {
  yield takeLatest(actions.top, top)
  yield takeLatest(actions.modelWordpress, modelWordpress)
}

/**
 * トップページコンテンツを取得
 * @param {*} action 
 */
function* top(action) {
  const {
    wp
  } = yield select()
  
  const result = yield call(api, {
    url: WP_URL + WP_TOP,
    method: 'GET',
    isLoading: wp.top.length === 0,
    isDisplayError: false,
  })
  
  if (!result.isSuccess) {
    return put(actions.set('top', []))
    
  }
  yield put(actions.set('top', result.json))
  
}

/**
 * カタログ2Wordpress記事を取得
 * @param {*} action 
 */
function* modelWordpress(action) {
  const {
    wp
  } = yield select()

  const result = yield call(api, {
    url: WP_URL + WP_MODEL_CATALOG,
    method: 'GET',
    isLoading: wp.modelWordpress.length === 0,
    isDisplayError: false,
  })
  
  if (!result.isSuccess) {
    return put(actions.set('modelWordpress', []))
    
  }
  yield put(actions.set('modelWordpress', result.json))
}
