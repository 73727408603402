import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Row, Col, Container, Badge } from 'reactstrap'
import styled from 'styled-components'
import { actions as locationActions } from '../../modules/location'
import { reduxForm, change, reset, formValueSelector } from 'redux-form'
import * as Common from '../../constants/common'
import { isEmpty, isEmptyObject } from '../../util/common'
import { actions as authActions, redirectSso } from '../../modules/auth'
import { HP_URL, HP_EN_URL, ONLINE_HEADER_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import { actions as notificationsAction } from '../../modules/informations'
import { ENVIRONMENT } from '../../constants/api-config'
import Modal from '../../components/molecules/Modal'
import { withTranslation } from 'react-i18next'
import ShopListModal from '../../components/molecules/ShopListModal'
import classNames from 'classnames'

class WebHeader extends Component {
  render() {
    const { name, onlineUrl, link, i18n, t } = this.props

    const isEc = !!this.props.history.location.pathname.match(/\/ec\//)

    return (
      <Content className="g-header is-sticky">
        <div className="g-header__inner">
          <a href={!isEc ? (i18n.language === 'en' ? HP_EN_URL : HP_URL) : onlineUrl}>
            <div className="c-logo">
              <img className="g-header__i--b" src="/assets/ui/logo.svg" alt="999.9" />
              <img className="g-header__i--w" src="/assets/ui/logo.w.svg" alt="999.9" />
            </div>
            {isEc === true && <div className="ec">ONLINE STORE</div>}
          </a>
        </div>
        <div className="g-header__menu">
          <div className="g-header__menu__btn">
            <svg viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg">
              <path d="m0 14h46v2h-46z" />
              <path d="m0 22h46v2h-46z" />
              <path d="m0 30h20v2h-20z" />
            </svg>
          </div>
        </div>
        <div className="g-header__ln__wrapper">
          <div className="g-header__lng c-sans">
            <a
              className={classNames('g-header__lng__a g-header__lng__a--ja', {
                'is-current': i18n.language !== 'en',
              })}
              href={HP_URL}
            >
              JP
            </a>
            <a
              className={classNames('g-header__lng__a g-header__lng__a--en', {
                'is-current': i18n.language === 'en',
              })}
              href={HP_EN_URL}
            >
              EN
            </a>
          </div>
          <a className="g-header__ln" href="javascript:void(0)" onClick={(e) => link('/items')}>
            <i className="g-header__ln__icon g-header__ln__icon--search">
              <img className="g-header__i--b" src="/assets/ui/search.svg" alt="" />
              <img className="g-header__i--w" src="/assets/ui/search.w.svg" alt="" />
            </i>
            <span>{t('検索')}</span>
          </a>
          <a className="g-header__ln" href={i18n.language === 'en' ? `${HP_EN_URL}/shops/` : `${HP_URL}/shops/`}>
            <i className="g-header__ln__icon g-header__ln__icon--shop">
              <img className="g-header__i--b" src="/assets/ui/pin.svg" alt="" />
              <img className="g-header__i--w" src="/assets/ui/pin.w.svg" alt="" />
            </i>
            <span>{t('店舗検索')}</span>
          </a>
        </div>

        {/** ページトップへ戻るアイコンの差し替え。 FNM単体スマホのときに位置調整をするためにHeaderに書いている。 */}
        <div className="c-b2t">
          <img src="/assets/ui/svg_float.svg" />
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('webHeader')
  return {
    name: !isEmptyObject(state.member.member)
      ? `${state.member.member.customer_family_name} ${state.member.member.customer_first_name || ''}`
      : !isEmptyObject(state.member.ssoUser)
      ? state.member.ssoUser.name
      : null,
    onlineUrl: state.menus.onlineUrl,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    set: (key, value) => {
      dispatch(change('webHeader', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'webHeader',
    initialValues: {},
  })(withTranslation()(WebHeader))
)

const Content = styled.header`
  .ec {
    text-align: center;
    font-size: 12px;
  }
`
