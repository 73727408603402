import React, { Component } from 'react'
import styled from 'styled-components'
import { media } from '../atomics/Media'
import { HP_URL } from '../../constants/api-config'
import { isEmptyObject, isEmpty } from '../../util/common'

class EcCartForm extends Component {
  render() {
    const { product_code, product_price, ec_stocks, lens_flag, colorProduct, product } = this.props

    let is_in_stock

    for (let i = 0; i < ec_stocks.length; i++) {
      if (ec_stocks[i].product.product_cd === product_code && ec_stocks[i].free_stock > 0) {
        is_in_stock = 1
      }
    }

    return (
      <CartFormWrap>
        <div className="model-name">{product.model && product.model.display_name}</div>
        {!isEmpty(colorProduct) && colorProduct.color !== null && (
          <div>
            <span className="color-code">
              {'col.'} {colorProduct.color.color_cd}
            </span>
            <span className="color-name"> {colorProduct.color.display_name} </span>
          </div>
        )}
        {product_price && <div className="price-section">{`希望小売価格${product_price}円(税込)`}</div>}
        {is_in_stock && (
          <form action="https://www.9999store.jp/api/basket/add.html" method="post">
            <input type="hidden" name="brand_info" value={`2,${product_code},1`} />
            {isEmptyObject(colorProduct) ? (
              <div className="disabledButton">
                <input type="submit" value="カートに入れる" className="addCart" disabled />
                <div disabled>(商品を指定してください)</div>
              </div>
            ) : (
              <input type="submit" value="カートに入れる" className="addCart" />
            )}
          </form>
        )}
        {!is_in_stock && <div className="addCart">sold out</div>}
        {lens_flag === '1' && (
          <div className="link">
            <a href={`${HP_URL}/about/four-nines-lens/`}>度付きレンズ購入の方はこちらへ</a>
          </div>
        )}
      </CartFormWrap>
    )
  }
}

export default EcCartForm

const CartFormWrap = styled.div`
  ${media.md`
background: #EBEBEB 0% 0% no-repeat padding-box;
opacity: 1;
width: 550px;
min-height: 200px;
margin:20px auto;

.addCart {
    width:80%;
    margin:0 50px
    color:white;
    padding:20px;
    font-size:1px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.link {
  margin-top:2%;
}
`}
  background: #EBEBEB 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 70%;
  height: 230px;
  margin: 20px auto;
  min-width: 270px;

  .model-name {
    padding-top: 15px;
    font-size: 15px;
  }

  .price-section {
    padding-bottom: 15px;
    font-size: small;
  }

  .addCart {
    width: 80%;
    margin: 0 10%;
    color: white;
    padding: 20px;
    font-size: 13px;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .disabledButton {
    position: relative;
    font-size: 13px;

    div {
      position: absolute;
      bottom: 5px;
      width: 100%;
      color: #ffffff;
    }
  }

  .link {
    margin-top: 15px;
  }

  .link a {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 4px;
    font-size: small;
  }
`
