import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, Field, change, formValueSelector, getFormValues, } from 'redux-form'
import {
  Container,
  Row, 
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
} from 'reactstrap'
import SizeImage from '../../constants/image/size.png'
import SizeImage2 from '../../constants/image/size2.png'

class Size extends Component {
  // componentDidMount() {
  //   this.props.start()
  // }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const {
      modelType
    } = this.props
    const modelName = modelType ? 'メタルフレーム': 'ネオプラスチックフレーム'
    const modelImg = modelType ? SizeImage : SizeImage2

    /**
     * 詳細検索のボタンを押した時の処理
     */
    const onClickModel = () => {
      this.props.set('modelType', !modelType)
    }
    return (
      <Content>
        <div className="result">
          <Container fluid={true} className="products">
            <Card>
              <CardHeader>
                <div className="title"> 各部名称・サイズについて</div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col><div className="more" onClick={onClickModel}>メタルフレーム</div></Col>
                  <Col><div className="more" onClick={onClickModel} >ネオプラスチックフレーム</div></Col>
                </Row>
                <CardTitle>{modelName}</CardTitle>
                <CardSubtitle>サイズ表記</CardSubtitle>
                <CardImg top width="100%" src={modelImg} />
              </CardBody>
            </Card>
          </Container>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector('size')
  return {
    modelType: selector(state, 'modelType'),
  }
}
const mapDispatchToProps = dispatch => {
  return {
    set: (key, value) => {
      dispatch(change('size', key, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'size',
    initialValues: {
      modelType: true,
    }
  })(
    withTranslation()(
      Size
    )  
  )
)

const Content = styled.div`
  > .toggle-tab {
    > .toggle-tab-title {
      font-size: 1.2rem;
    }
  }

  .search-products {
    padding: 10px;
  }
  .favorites {
    padding: 10px;
  }
  .thumbnail{
      img{
        height: 4rem;
      }
  }
  .different-color{
    img{
      height: 4rem;
    }
  }
  .more {
      border: solid 1px #AAAAAA;
      margin: 10px;
      padding: 5px;
      color: #666666;
      text-align: center;
      position: relativevertical-align;
      height: 60px;
    }
  .title{
    font-size: 1.3rem;
  }
  .card-header {
    text-align:center; 
  }
  .card-title {
    text-align:center;
    font-size: 1.2rem;
  }
  .card-subtitle {
    text-align:center;
    font-size: 1.2rem;
    border-style: outset;
  }
`
