import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { actions as locationActions } from '../../modules/location'
import QrCode from 'qrcode.react'
import { isEmptyObject } from '../../util/common'
import { actions as authActions } from '../../modules/auth'
import { IoIosArrowForward } from 'react-icons/io'
import Modal from '../../components/molecules/Modal'
import TermsOfServiceModal from '../../components/molecules/TermsOfServiceModal'
import PrivacyPolicyModal from '../../components/molecules/PrivacyPolicyModal'
import WithdrawalModal from '../../components/molecules/WithdrawalModal'
import ShopListModal from '../../components/molecules/ShopListModal'
import LoginOutConfirmModal from '../../components/molecules/LoginOutConfirmModal'
import { reduxForm, change, formValueSelector } from 'redux-form'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import liff from '../../modules/liff'
import { actions as notificationsAction } from '../../modules/informations'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'

class Member extends Component {
  componentDidMount() {
    this.props.start()
  }

  render() {
    const {
      member,
      ssoUser,
      link,
      logout,
      termsOfServiceModal,
      privacyPolicyModal,
      withdrawalModal,
      shopListModal,
      loginOutConfirmModal,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if (isWeb) {
        if (i18n.language === 'en') {
          window.location.href = HP_EN_URL
        } else {
          window.location.href = HP_URL
        }
      } else {
        link('/')
      }
    }

    const onClickMember = () => {
      link('/member')
    }

    if (isEmptyObject(member) && isEmptyObject(ssoUser)) {
      return ''
    }

    // モーダルウィンドウ開閉
    const onClickModel = (modal, value) => {
      this.props.set(modal, value)
    }

    return (
      <Content className="member">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>トップページ</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>マイアカウント</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">MY ACCOUNT</div>
        <div className="contents-subtitle">マイアカウント</div>
        {!isEmptyObject(member) && (
          <div className="member-card">
            <div className="title">Four Nines Members</div>
            <div className="qr">
              <QrCode value={`9999members.customer_num.${member.customer_num}`} />
            </div>
            <div className="no">No.{member.customer_num}</div>
            <div className="text">ショップスタッフにご提示ください</div>
          </div>
        )}
        <div className="member-menu">
          <div className="member-contents" onClick={() => link('/member/info')}>
            <button className="member-contents-title">お客様情報の確認・変更</button>
          </div>
          {!isEmptyObject(member) && (
            <>
              <div className="member-contents" onClick={() => link('/histories')}>
                <button className="member-contents-title">購入履歴</button>
              </div>
              <div href="#" className="member-contents" onClick={() => link('/reservations')}>
                <button className="member-contents-title">お取り置き</button>
              </div>
            </>
          )}
          {!isWeb && (
            <div className="member-contents">
              <a
                target="_blank"
                rel="noopener"
                href={i18n.language === 'en' ? `${HP_EN_URL}/shops/list/` : `${HP_URL}/shops/list/`}
              >
                <button className="member-contents-title">Shop List</button>
              </a>
            </div>
          )}
          {!isWeb && (
            <div className="member-contents">
              <a
                target="_blank"
                rel="noopener"
                href={
                  i18n.language === 'en'
                    ? `${HP_EN_URL}/shops/four-nines-shop/list/`
                    : `${HP_URL}/shops/four-nines-shop/list/`
                }
              >
                <button className="member-contents-title">Four Nines Shop List</button>
              </a>
            </div>
          )}
          {!isWeb && (
            <div className="member-contents">
              <a
                target="_blank"
                rel="noopener"
                href={i18n.language === 'en' ? `${HP_EN_URL}/user-policy/` : `${HP_URL}/user-policy/`}
              >
                <button className="member-contents-title">利用規約</button>
              </a>
            </div>
          )}
          {!isWeb && (
            <div className="member-contents">
              <a
                target="_blank"
                rel="noopener"
                href={i18n.language === 'en' ? `${HP_EN_URL}/privacy-policy/` : `${HP_URL}/privacy-policy/`}
              >
                <button className="member-contents-title">プライバシーポリシー</button>
              </a>
            </div>
          )}
          {/* <div href="https://www.fournines.co.jp/" className="member-contents">
            <button className="member-contents-title">
            Four Nines OFFICIAL WEB SITE
            </button>
          </div>
          <div href="https://www.9999store.jp/" className="member-contents">
            <button className="member-contents-title">
            Four Nines OFFICIAL ONLINE STORE
            </button>
          </div> */}
          {!liff.isInClient() && (
            <>
              <div className="member-contents" onClick={() => onClickModel('withdrawalModal', true)}>
                <button className="member-contents-title">退会する</button>
              </div>
              <div className="member-contents" onClick={() => onClickModel('loginOutConfirmModal', true)}>
                <button className="member-contents-title">ログアウト</button>
              </div>
            </>
          )}
        </div>
        {!isWeb && (
          <Container className="sns">
            <Row xs={4}>
              <Col>
                <a href="https://www.facebook.com/FourNines.co.jp/">
                  <FaFacebook />
                </a>
              </Col>
              <Col>
                <a href="https://www.instagram.com/999.9_fournines/">
                  <FaInstagram />
                </a>
              </Col>
              <Col>
                <a href="https://www.youtube.com/channel/UCMsksMVNqQdUeCGkAAjpTMA">
                  <FaYoutube />
                </a>
              </Col>
              <Col>
                <a href="https://twitter.com/9999_PLAIDe">
                  <FaTwitter />
                </a>
              </Col>
            </Row>
          </Container>
        )}
        <Modal
          className="width90"
          isOpen={termsOfServiceModal}
          onClose={() => onClickModel('termsOfServiceModal', false)}
        >
          <TermsOfServiceModal />
        </Modal>
        <Modal
          className="width90"
          isOpen={privacyPolicyModal}
          onClose={() => onClickModel('privacyPolicyModal', false)}
        >
          <PrivacyPolicyModal />
        </Modal>
        <Modal className="width90" isOpen={withdrawalModal} onClose={() => onClickModel('withdrawalModal', false)}>
          <WithdrawalModal />
        </Modal>
        <Modal className="width90" isOpen={shopListModal} onClose={() => onClickModel('shopListModal', false)}>
          <ShopListModal />
        </Modal>
        <Modal
          className="width90"
          isOpen={loginOutConfirmModal}
          onClose={() => onClickModel('loginOutConfirmModal', false)}
        >
          <LoginOutConfirmModal onCancel={() => onClickModel('loginOutConfirmModal', false)} />
        </Modal>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>トップページ</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>マイアカウント</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('member')
  return {
    member: state.member.member,
    ssoUser: state.member.ssoUser,
    authGlaviss: state.auth.glaViss,
    location: state.auth.location,
    termsOfServiceModal: selector(state, 'termsOfServiceModal'),
    privacyPolicyModal: selector(state, 'privacyPolicyModal'),
    withdrawalModal: selector(state, 'withdrawalModal'),
    shopListModal: selector(state, 'shopListModal'),
    loginOutConfirmModal: selector(state, 'loginOutConfirmModal'),
    isWeb: state.location.isWeb,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    start: () => {
      dispatch(authActions.shouldAuth())
    },
    set: (key, value) => {
      dispatch(change('member', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    setLocation: (url) => {
      dispatch(authActions.setLocation(url))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'member',
    initialValues: {
      termsOfServiceModal: false,
      privacyPolicyModal: false,
      WithdrawalModal: false,
      shopListModal: false,
    },
  })(withTranslation()(Member))
)

const Content = styled.div`
  text-align: center;
  background-color: #F5F5F2;

  .member-menu{
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .member-card {
    background-color: #FFFF;
    height: 400px;
    padding: 15px;
    div {
      margin: 2rem 0;
    }
    .title {
      font-size: 19px;
      font-family: ヒラギノ角ゴ Pro
      letter-spacing: 2.03px
      margin: 55px 0px 0px 0px;
    }
    .no {
      font-size: 12px;
      font-family: ヒラギノ角ゴ Pro
      letter-spacing: 2.03px
    }
    .text {
      font-size: 12px;
      font-family: ヒラギノ角ゴ Pro
      margin: -20px 0px 35px 0px;
      letter-spacing: 2.03px
    }
    .qr {
  
    }
  }

  .member-contents {
    font-size: 12px;
    font-family: ヒラギノ角ゴ Pro;
    color: #000000;
    padding-bottom: 15px;
    margin: 0;
    padding: 8px 0;

    &:hover {
      text-decoration: none;
    }

    img{
      top: 548px;
      left: 40px;
      height: 20px;
      margin-right: 10px;
    }

    .member-contents-title {
      top: 528px;
        left: 20px;
        width: 100px;
        height: 70px;
        width: 80%;
        font-size:12px;
        text-align:left;
        background: #FFFF;
        border: 1px solid #DDDDDB;
        border-radius: 5px;
        padding-left: 20px;

      .icon {
        position: absolute;
        font-size: 19px;
        right: 20px;
        color: #939393;
      }
    }
  }

  .sns {
    background-color: #FFFF;
    padding-top: 22px;
    padding-bottom: 25px;
    color: #A7A7A7;
    font-size: 37px;
    line-height: 37px;
    text-align: center;
    border-bottom: 1px solid #E8E8E8;

    a, a:link, a:visited, a:hover, a:active {
      color: #A7A7A7;
    }
  }
`
