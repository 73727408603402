export default {
  お名前: 'Name',
  トップページ: 'top page',
  詳細検索: 'DETAILED SEARCH',
  商品検索: 'item search',
  '品番・フリーワードで検索': 'SEARCH BY PRODUCT / FREE TEXT',
  商品一覧: 'item',
  商品詳細: 'item detail',
  一覧へ: 'BACK TO ITEM SEARCH',
  店舗在庫を確認する: 'check stock',
  お気に入りに追加済み: 'added favorite',
  お気に入りに追加する: 'add favorite',
  '各種名称・サイズについて': 'PARTS AND SIZE INFORMATION',
  商品説明: 'PRODUCT INFORMATION',
  仕様: 'SPECIFICATION',
  サイズ: 'Size',
  ボックスサイズ: 'Box size',
  レンズ天地幅: 'Lens height',
  枠幅: 'Frame width',
  素材: 'Material',
  フロント: 'Front',
  テンプル: 'Temple',
  モダン: 'Temple end tip',
  レンズ: 'Lens',
  付属品: 'Accessories',
  店舗検索: 'SHOP LOCATOR',
  '該当の商品はありませんでした。': 'NO MATCHES FOUND',
  カテゴリ: 'category',
  閉じる: 'CLOSE',
  ショップリスト: 'SHOP LIST',
  'オンラインストアに関するご利用ガイド、お問い合わせはこちら': 'Guide and contact about Online store',
  ご利用ガイド: 'GUIDE',
  お問い合わせ: 'CONTACT',
  検索: 'SEARCH',
}
