import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import styled from 'styled-components'
import { actions as locationActions } from '../../modules/location'
import { withTranslation } from 'react-i18next'
import { isEmptyObject } from '../../util/common'
import { actions as informationDetailActions } from '../../modules/informationDetail'
import { change, reduxForm, formValueSelector } from 'redux-form'
import { IMG_URL } from '../../constants/api-config'
import { actions as notificationsAction } from '../../modules/informations'
import { actions as authActions } from '../../modules/auth'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media' 

class Detail extends Component {
  componentDidMount() {
    this.loadInfo()
  }
  componentDidUpdate() {
    
  }

  loadInfo = () => {
    
    // お知らせ詳細取得
    const { information_id } = this.props.match.params
    
    // 既読する処理も含む
    this.props.getInformationDetail(information_id)
    
  }

  render() {

    const {
      informationDetail,
      link,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickInformation = () => {
      link('/informations')
    }

    if (isEmptyObject(informationDetail)) {
      return ''
    }

    const adjustIframeHeight = e => {
      this.iframe.style.height = this.iframe.contentWindow.document.body.scrollHeight + 16 + 'px'
    }

    return (
      <Content className="informations">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickInformation()}>メッセージ</a></BreadcrumbItem>
            <BreadcrumbItem active>詳細</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">MESSAGE</div>
        <div className="contents-subtitle">メッセージ</div>
        <div className="information-wrap">
          {/* <div className="back">
            <Button onClick={e => link('/informations')}>
              <img src={`${IMG_URL}/img/ico/allow_left.png`}/>
            </Button>
          </div> */}
          <h1>{informationDetail.title}</h1>
          <div className="body">
            <iframe srcDoc={informationDetail.body} ref={ref => this.iframe = ref} onLoad={adjustIframeHeight}/>
          </div>
        </div>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickInformation()}>メッセージ</a></BreadcrumbItem>
            <BreadcrumbItem active>詳細</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    informationDetail: state.informationDetail.informationDetail,
    isWeb: state.location.isWeb,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getInformationDetail: (id) => {
      dispatch(authActions.shouldAuth(() => {
        dispatch(informationDetailActions.getInformationDetail(id))
      }))
    },
    set: (key, value) => {
      dispatch(change('information_detail', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    informationUpdate: (id) => {
      dispatch(informationDetailActions.informationUpdate(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'information_detail',
    initialValues: {
    }
  })(
    withTranslation()(
      withRouter(Detail)
    )
  )
)

const Content = styled.div`

.information-wrap {
  background-color: #ffffff;
  padding: 10px 0;
}

h1{
  margin: 12px auto;
}
.body{
  margin: 0 20px;

  iframe {
    width: 100%;
  }
}

${media.lg`
  .information-wrap {
    margin-bottom: 100px;
  }
`}
`
