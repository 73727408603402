import React, { Component } from 'react'
import styled from 'styled-components'
import { animateScroll as scroll } from 'react-scroll'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'

class TopScroll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollTop: this.getScrollTop(),
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateScrollTop)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollTop)
  }

  getScrollTop = () => {
    // なんかブラウザによってとり方が違うようなので全部もってきてMaxをとる
    return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
  }

  updateScrollTop = () => {
    this.setState({ scrollTop: this.getScrollTop() })
  }

  render() {
    const { scrollTop } = this.state

    const {
      visiblePos = 350,
      opacity = 0.5,
      onClick = (e) => scroll.scrollToTop(),
      children = <img src={`${IMG_URL}/img/ico/scroll_button.svg`} />,
      className = '',
    } = this.props

    return (
      <Content
        className={`top-scroll ${className}`}
        onClick={onClick}
        style={{ opacity: scrollTop < visiblePos ? 0 : opacity }}
      >
        {children}
      </Content>
    )
  }
}

export default TopScroll

const Content = styled.div`
  position: fixed;
  right: 24px;
  bottom: 24px;
  text-align: center;
  width: 32px;
  height: auto;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  ${media.sm`
  right: calc(50% - 576px / 2 + 24px);
`}
  ${media.lg`
  right: calc(50% - 992px / 2 + 24px);
`}
  ${media.xl`
  right: calc(50% - 1280px / 2 + 24px);
`}
`
