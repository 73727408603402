import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as form } from 'redux-form'
import notice from './notice'
import error from './error'
import loading from './loading'
import auth from './auth'
import master from './master'
import products from './products'
import favorites from './favorites'
import productDetail from './productDetail'
import reservation from './reservation'
import member from './member'
import informations from './informations'
import purchaseHistory from './purchaseHistory'
import memberInfo from './memberInfo'
import informationDetail from './informationDetail'
import wp from './wp'
import series from './series'
import models from './models'
import location from './location'
import menus from './menus'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  notice,
  error,
  loading,
  master,
  form,
  products,
  favorites,
  productDetail,
  reservation,
  member,
  informations,
  purchaseHistory,
  memberInfo,
  informationDetail,
  wp,
  series,
  models,
  location,
  menus,
})
