import {all, call} from 'redux-saga/effects';
import {authSaga} from './auth'
import {loadingSaga} from './loading'
import {masterSaga} from './master'
import {productsSaga} from './products'
import {favoritesSaga} from './favorites'
import {productDetailSaga} from './productDetail'
import {reservationSaga} from './reservation'
import {memberSaga} from './member'
import {informationsSaga} from './informations'
import {purchaseHistorySaga} from './purchaseHistory'
import {informationDetailSaga} from './informationDetail'
import {wpSaga} from './wp'
import {seriesSaga} from './series'
import {modelsSaga} from './models'
import {locationSaga} from './location'
import {menusSaga} from './menus'

export default function* indexSaga() {
  yield all([
    call(authSaga),
    call(loadingSaga),
    call(masterSaga),
    call(productsSaga),
    call(favoritesSaga),
    call(productDetailSaga),
    call(reservationSaga),
    call(memberSaga),
    call(informationsSaga),
    call(purchaseHistorySaga),
    call(informationDetailSaga),
    call(wpSaga),
    call(seriesSaga),
    call(modelsSaga),
    call(locationSaga),
    call(menusSaga),
  ])
}
