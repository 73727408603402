import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Button,
  Label,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import styled from 'styled-components'
import { actions as locationActions } from '../../modules/location'
import { withTranslation, } from 'react-i18next'
import { reduxForm, Field, getFormValues } from 'redux-form'
import { actions as authActions } from '../../modules/auth'
import { IMG_URL } from '../../constants/api-config'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'

class ChangePassword extends Component {

  render() {
    const {
      formValues,
      changePassword,
      link,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickMember = () => {
      link('/member')
    }

    // アカウント情報
    return (
      <Content>
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => link('/member/info')}>お客様情報の確認</a></BreadcrumbItem>
            <BreadcrumbItem active>パスワード変更</BreadcrumbItem>
          </Breadcrumb>
        </div>
        {/* <div className="back">
          <Button onClick={() => link('/member/info')}>
            <img src={`${IMG_URL}/img/ico/allow_left.png`}/>
          </Button>
        </div> */}
        <div className="contents-title">PASSWORD</div>
        <div className="contents-subtitle">パスワード変更</div>
        <div className="info-body">
          <Container>
            <Row>
              <Col xs="12">
                <Label>現在のパスワード</Label>
                <Field name="old_password" type="password" component="input" className="form-control" />
              </Col>
              <Col xs="12">
                <Label>新しいパスワード</Label>
                <Field name="password" type="password" component="input" className="form-control" />
              </Col>
              <Col xs="12">
                <Label>新しいパスワード確認</Label>
                <Field name="password_confirmation" type="password" component="input" className="form-control" />
              </Col>
            </Row>
          </Container>
        </div>
        <Button className="btn-large" block onClick={() => changePassword(formValues)}>登録情報を変更する</Button>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => link('/member/info')}>お客様情報の確認</a></BreadcrumbItem>
            <BreadcrumbItem active>パスワード変更</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    formValues: getFormValues('change_password')(state),
    isWeb: state.location.isWeb,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (formValues) => {
      dispatch(authActions.changePassword({
        ...formValues
      }))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'change_password',
    initialValues: {
      old_password: '',
      password: '',
      password_confirmation: ''
    }
  })(
    withTranslation()(
      ChangePassword
    )
  )
)

const Content = styled.div`
  margin: 0;
  margin-bottom: 30px;
  background-color: #F5F5F2;
  height: 650px;
.h1{
  margin-top: 20px;
}

  .title{
    text-align: center;
    line-height: 4px;
  }

  .button{
    font-size: 10px;
    color: white;
    background-color: black;
  }

  .info-body {
    margin: 0 26px;

    .row > div {
    border-bottom: 1px solid;
    border-color: #D8D8D8;
    padding-bottom: 20px;

      margin-bottom: 21px;
    }
    .col-6:nth-child(2n+1) {
      padding-right: 2px;
    }
    .col-6:nth-child(2n) {
      padding-left: 2px;
    }

    label {
      font-size: 15px;
      margin-bottom: 10px;
    }
    input {
      font-size: 18px;
      background-color: #FFFF;
      border-radius: 4px;
      height: 46px;
      border: 1px solid;
      border-color: #DDDDDB;
    }
  }

  .list-sp {
    .breadcrumb {
      padding: 10px 2px 2px 8px;
    }
  }
`
