import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { push, replace as router_replace } from 'connected-react-router'
import i18n from './i18n'
import { isEmptyObject, isEmpty } from '../util/common'

/***********************************************************
 * Action Creators
 ***********************************************************/
export const actions = createActions(
  {
    link: (pathname) => pathname,
    replace: (pathname) => pathname,

    set: (key, value) => ({ key, value }),
  },
  { prefix: '9999/location' }
)
/***********************************************************
 * Reducer
 ***********************************************************/
const initialState = {
  isWeb: false,
  referer: '',
  referencePath: '',
  referenceName: '',
  referenceScroll: 0,

  isReturnItems: false,
}
const reducer = handleActions(
  {
    [actions.set]: (state, action) => ({
      ...state,
      [action.payload.key]: action.payload.value,
    }),
  },
  initialState
)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* locationSaga() {
  yield takeLatest(actions.link, link)
  yield takeLatest(actions.replace, replace)

  // react-routerのアクション
  yield takeLatest('@@router/LOCATION_CHANGE', change)
}

function* link(action) {
  const { location } = yield select()

  let pathname = action.payload
  if (typeof action.payload === 'object') {
    pathname = action.payload.pathname

    // 商品詳細のパンくずリスト用
    if (!isEmptyObject(action.payload.state.referencePath)) {
      const { path: referencePath } = checkURL(action.payload.state.referencePath)

      let referenceName = action.payload.state.referenceName
      if (isEmpty(referenceName)) {
        switch (true) {
          case referencePath === '/':
          case referencePath === '/items':
            referenceName = '商品検索'
            break
          case referencePath === '/favorites':
            referenceName = 'お気に入り'
            break
          default:
            referenceName = null
        }
      }

      if (!isEmpty(referenceName)) {
        yield put(actions.set('referencePath', referencePath))
        yield put(actions.set('referenceName', referenceName))
      }
    }

    // 商品詳細から前の画面に戻る用
    // 遷移するときにスクロール位置を保持、一覧に戻るときには change() でスクロール位置を復元
    if (action.payload.state.referenceScroll) {
      yield put(actions.set('referenceScroll', action.payload.state.referenceScroll))
    }
    // 一覧に戻るときにtrue、一覧画面のonMountでfalseに戻す
    if (action.payload.state.isReturnItems) {
      yield put(actions.set('isReturnItems', true))
    }
  }

  if (!location.isWeb) {
    yield put(push(pathname))
    return
  }
  if (pathname.match(/^http/)) {
    yield put(push(pathname))
    return
  }
  if (pathname.match(/^\/web\//)) {
    yield put(push(pathname))
    return
  }

  // 遷移元がWebであれば、WebのURLへ遷移させる
  pathname = `/web/${i18n.language}/${pathname.replace(/^\//, '')}`
  yield put(push(pathname))
}

function* replace(action) {
  const { location } = yield select()

  let pathname = action.payload

  if (!location.isWeb) {
    yield put(router_replace(pathname))
    return
  }
  if (pathname.match(/^http/)) {
    yield put(router_replace(pathname))
    return
  }
  if (pathname.match(/^\/web\//)) {
    yield put(router_replace(pathname))
    return
  }

  // 遷移元がWebであれば、WebのURLへ遷移させる
  pathname = `/web/${i18n.language}/${pathname.replace(/^\//, '')}`
  yield put(router_replace(pathname))
}

function* change(action) {
  const { location } = yield select()
  const {
    location: { pathname = '' },
  } = action.payload

  // 遷移したページのURLで言語等を切り替え得る
  const { isWeb, lang, path } = checkURL(pathname)
  if (location.isWeb !== isWeb) {
    yield put(actions.set('isWeb', isWeb))
  }
  if (i18n.language !== lang) {
    yield i18n.changeLanguage(lang)
  }

  // 別ページへ遷移したときはスクロールを戻す
  const { path: orgPath } = checkURL(location.referer)
  if (
    !(orgPath.indexOf('/series') === 0 && path.indexOf('/series') === 0) &&
    !(orgPath.indexOf('/models') === 0 && path.indexOf('/models') === 0) &&
    !(orgPath.indexOf('/items/') === 0 && path.indexOf('/items/') === 0)
  ) {
    window.scrollTo(0, 0)
  }

  // 最後の遷移先を保持
  yield put(actions.set('referer', pathname))
}

function checkURL(url) {
  const matches = url.match(/^(\/web\/)(.+?)(\/.*)?$/)
  if (!matches) {
    return {
      isWeb: false,
      lang: 'ja',
      path: url,
    }
  }

  return {
    isWeb: true,
    lang: matches[2],
    path: matches[3] || '',
  }
}
