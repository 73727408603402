import { OPEN_ENV } from './api-config'

const PRO_LIFF_ID = '1654988026-wY0mgV20'
const STG_LIFF_ID = '1654988025-qojXgvMK' // 審査用
const DEV_LIFF_ID = '1654988024-vLr3W1kk'

/* LIFF ID */
export const LIFF_ID = (() => {
  switch (OPEN_ENV) {
    case 'production':
      return PRO_LIFF_ID
    case 'staging':
      return STG_LIFF_ID
    case 'development':
    default:
      return DEV_LIFF_ID
  }
})()
