import { createActions, handleActions } from 'redux-actions'
import { select, put, call, takeEvery } from 'redux-saga/effects'
import api from './api'
import {
  PUT_EDIT,
  SSO_ME,
} from '../constants/api-url'
import { actions as errorActions } from './error'
import { actions as authActions } from './auth'
import { actions as locationActions } from './location'
import { diffDetail } from '../util/common'


/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    init: () => ({}),
    updateGlaviss: payload => payload,
    setGlaviss: payload => payload,
    updateSso: payload => payload,
    setSso: payload => payload,
    setUpdateGlaviss: payload => payload,
  },
  { prefix: '9999/member'},
)

/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  member: {},
  ssoUser: {}
}
const reducer = handleActions({
  [actions.init]: (state, action) => initialState,
  /*
   * Glaviss連携userの編集時にmember情報が空になり、
   * SSOUserの編集画面を描画されることを防ぐため
   */
  // [actions.updateGlaviss]: (state, action) => ({
  //   ...state,
  //   member: action.payload,
  // }),
  [actions.setSso]: (state, action) => ({
    ...state,
    ssoUser: action.payload,
  }),

  // 認証アクション
  [authActions.setGlavissAuth]: (state, action) => ({
    ...state,
    member: action.payload.customer,
  }),
  [authActions.setAuth]: (state, action) => ({
    ...state,
    ssoUser: action.payload.user,
  }),
  [actions.setUpdateGlaviss]: (state, action) => ({
    ...state,
    member: action.payload.customer,
  }),
  [authActions.logout]: () => initialState,
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* memberSaga(action) {
  yield takeEvery(actions.updateGlaviss, updateGlaviss)
  yield takeEvery(actions.updateSso, updateSso)
}
function* updateGlaviss(action) {
  const {
    form: { glaviss_edit: { values } },
    member: { member }
  } = yield select()

  const request = {...values}
 
  request.birthday = '';
  if (request.birthday_format_year == 'Invalid date') {
    request.birthday = null;
  } else if (request.birthday_format_year == '' && request.birthday_format_month == '' && request.birthday_format_day == '') {
    request.birthday = null;
  } else {
    request.birthday = request.birthday_format_year + '-' + request.birthday_format_month + '-' + request.birthday_format_day;
  }

  // 誕生日が入力されたら生年月日推測をOFFにする
  request.birthday_guess_flag = request.birthday ? '0': '1';

  // 生年月日推測がONの人は誕生日を更新しない様にするため
  if (member.birthday_guess_flag == '1' && request.birthday_guess_flag == '1'){
    delete request.birthday;
  }
  delete request.birthday_format_year;
  delete request.birthday_format_month;
  delete request.birthday_format_day;

  //ユーザーアカウント情報の更新
  const result = yield call(api, {
    url: PUT_EDIT,
    method: 'PUT',
    request,
  })
  if (!result.isSuccess) {
    return
  }
  yield put(actions.setUpdateGlaviss(result.json))

  yield put(locationActions.link('/member/info'))
}

function* updateSso(action) {
  const {
    form: { sso_edit: { values } }
  } = yield select()

  const request = {...values}

  //ユーザーアカウント情報の更新
  const result = yield call(api, {
    url: SSO_ME,
    method: 'PUT',
    request,
  })
  if (!result.isSuccess) {
    return
  }
  yield put(actions.setSso(result.json.user))

  yield put(locationActions.link('/member/info'))
}