import { createActions, handleActions } from 'redux-actions'
import { select, put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import api from './api'
import { NOTIFICATIONS, NOTIFICATION } from '../constants/api-url'
import moment from 'moment'
import { waitAuth } from './auth'


/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    init: () => ({}),
    clickNotification: payload => payload,
    notificationBack: payload => payload,
    getNotifications: payload => payload,
    notificationUpdate: payload => payload,
    notificationDelete: payload => payload,
    setNotifications: (notifications, meta) => ({notifications, meta}),
    addNotifications: (notifications, meta) => ({notifications, meta}),
  },
  { prefix: '9999/informations'},
)

/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  searchedMeta: {
    current_page: 1,
    last_page: 1,
    unread_total: 0
  },
  isSearching: false,

  notifications: []
}
const reducer = handleActions({
  [actions.init]: (state, action) => initialState,
  [actions.clickNotification]: (state, action) => ({
    ...state,
    isSearching: true,
    searchedMeta: {
      ...state.searchedMeta,
      current_page: state.searchedMeta.current_page +1
    },
  }),
  [actions.notificationBack]: (state, action) => ({
    ...state,
    isSearching: true,
    searchedMeta: {
      ...state.searchedMeta,
      current_page: state.searchedMeta.current_page -1
    },
  }),
  [actions.getNotifications]: (state, action) => ({
    ...state,
    searchedMeta: {
      ...initialState.searchedMeta,
      unread_total: state.searchedMeta.unread_total,
    },
    isSearching: true,
  }),
  [actions.setNotifications]: (state, action) => ({
    ...state,
    notifications: [
      ...action.payload.notifications,
    ],
    searchedMeta: {
      ...state.searchedMeta,
      ...action.payload.meta,
    },
    isSearching: false,
  }),
  [actions.addNotifications]: (state, action) => ({
    ...state,
    notifications: [
      ...state.notifications,
      ...action.payload.notifications,
    ],
    searchedMeta: {
      ...state.searchedMeta,
      ...action.payload.meta,
    },
    isSearching: false,
  })
}, initialState)


export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* informationsSaga() {
  yield takeLatest(actions.getNotifications, getNotifications)
  yield takeLatest(actions.clickNotification, getNotifications)
  yield takeLatest(actions.notificationBack, getNotifications)
  yield takeLatest(actions.notificationUpdate, notificationsUpdate)
  yield takeLatest(actions.notificationDelete, notificationsDelete)

  // ページ遷移したときにお知らせを読み込む
  yield takeLatest('@@router/LOCATION_CHANGE', function* () { yield put(actions.getNotifications()) })
}
  
// 通知一覧取得処理
function* getNotifications(action) {
  if (!(yield call(waitAuth))) {
    yield put(actions.setNotifications([], {}))
    return
  }

  const { 
    informations: {
      searchedMeta,
    }
  } = yield select()

  let page = searchedMeta.current_page;
  if (page > searchedMeta.last_page) {
    yield put(actions.setNotifications([], {}))
    return;
  }

  let request = {
    page,
  }

  // let page = 1;
  const result = yield call(api, {
    url: NOTIFICATIONS,
    method: 'GET',
    request,
    isLoading: false,
    isDisplayError: false,
  })
  if (!result.isSuccess) {
    yield put(actions.setNotifications([], {}))
    return
  }
  if(page == 1){
    yield put(actions.setNotifications(result.json.notifications, result.json.meta))
  } else{
    yield put(actions.addNotifications(result.json.notifications, result.json.meta))
  }
}

function* notificationsUpdate(action) {
  const notification_id = action.payload
  let now = moment().format('YYYY-MM-DD')
  let request = {
    readed_at: now
  }
  const result = yield call(api, {
    url: NOTIFICATION(notification_id),
    method: 'PUT',
    request
  })
  if (!result.isSuccess) {
    yield put(actions.setNotifications([], {}))
    return
  }
  yield put(actions.getNotifications())
}

function* notificationsDelete(action) {
  const notification_id = action.payload
  const result = yield call(api, {
    url: NOTIFICATION(notification_id),
    method: 'DELETE',
  })
  if (!result.isSuccess) {
    yield put(actions.setNotifications([], {}))
    return
  }
  yield put(actions.getNotifications())
}
