import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Spinner,
  Button,
} from 'reactstrap'
import { actions as locationActions } from '../../modules/location'
import { IoMdHeartEmpty, IoMdHeart, IoMdClose } from 'react-icons/io'
import styled from 'styled-components'
import * as Common from '../../constants/common'
import { actions as authActions } from '../../modules/auth'
import { actions as favoritesActions } from '../../modules/favorites'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import ProductImage from '../../components/molecules/ProductImage'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { isEmpty } from '../../util/common'
import { HP_URL } from '../../constants/api-config'

class Products extends Component {
  render() {
    const {
      products,
      isSearching = false,
      hasMore = false,
      onClickMore = () => {},
      favorites,
      toggleFavorite,
      link,
      isClose = false,
      t,
      i18n,
      isWeb,
      catalogPriceHidden,
    } = this.props

    const onProduct = (product) => (e) => {
      if (Number(product.ec_display_flag) == 1 && isWeb && this.props.location.pathname.includes('/favorites')) {
        this.props.link({
          pathname: `/items/ec/${product.product_cd}/${product.product_cd}`,
          state: { referencePath: this.props.location.pathname, referenceScroll: window.scrollY },
        })
      } else {
        this.props.link({
          pathname: `/items/${product.product_cd}/${product.product_cd}`,
          state: { referencePath: this.props.location.pathname, referenceScroll: window.scrollY },
        })
      }
      e.stopPropagation()
    }

    const onFavorite = (product) => (e) => {
      toggleFavorite(product)
      e.stopPropagation()
    }

    return (
      <Content>
        <Container fluid={true} className="products">
          <Row xs={2}>
            {products.map((product) => (
              <Col key={product.id}>
                <Card className="cards" onClick={onProduct(product)}>
                  {i18n.language !== 'en' && (
                    <button className="favorite" onClick={onFavorite(product)}>
                      {isClose && <IoMdClose size="1.3rem" />}
                      {favorites.find((favorite) => favorite.id === product.id)
                        ? !isClose && <IoMdHeart />
                        : !isClose && <IoMdHeartEmpty />}
                    </button>
                  )}
                  <CardBody>
                    {product.new_flag === '1' && <img className="new_label" src={`${IMG_URL}/img/ico/new.png`} />}
                    <CardSubtitle>
                      {i18n.language === 'en'
                        ? product.series && product.series.eng_name
                        : product.series && product.series.display_name}
                    </CardSubtitle>
                    <CardTitle>{i18n.language === 'en' ? product.eng_name : product.display_name}</CardTitle>
                    {/* TODO: ここは商品画像でいいはず。 */}
                    {/* <ProductImage model={product.model} imageNo={1}/><br/> */}
                    <ProductImage product={product} imageNo={1} />
                    <CardText tag="div" className="color">
                      {!isEmpty(product.color && product.color.color_cd) && 'col.'}{' '}
                      {product.color && product.color.color_cd}
                      <br />
                      {product.color && (i18n.language === 'en' ? product.color.eng_name : product.color.display_name)}
                      <br />
                      {i18n.language === 'en' ? product.lens_color_eng : product.lens_color}
                      {/** 言語が日本語の場合で「商品分類2」が「べっ甲」(商品分類2ID : 27)の場合も希望小売価格を非表示 */}
                      {/** #22840 [FNM-カタログ]価格の表示を非表示に変更依頼 */}
                      {!(catalogPriceHidden && isWeb) && i18n.language !== 'en' && product.class2?.id != 27 && (
                        <CardText tag="div" className="price">
                          {product.sell_unitprice_w_tax.toLocaleString()}円(税込)
                        </CardText>
                      )}
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        {isSearching && (
          <div className="searching">
            <Spinner />
          </div>
        )}
        {hasMore && (
          <div
            className="more"
            onClick={(e) => {
              e.target.blur()
              onClickMore()
            }}
          >
            VIEW MORE
          </div>
        )}
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    favorites: state.favorites.products || [],
    isWeb: state.location.isWeb,
    catalogPriceHidden: state.master.catalogPriceHidden,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    toggleFavorite: (product) => {
      dispatch(
        authActions.shouldAuth(() => {
          dispatch(favoritesActions.toggle(product))
        })
      )
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Products)))

const Content = styled.div`
  .row {
  }
  .col {
    border: 1px solid #d6d6d6;
    overflow: hidden;
    border-left-width: 0;
  }
  .col:nth-child(2n) {
    border-right-width: 0;
  }
  .col:nth-child(n + 3) {
    border-top-width: 0;
  }

  .card {
    margin: 1px;
    position: relative;
    border: 0;
    cursor: pointer;
  }
  .new_label {
    position: absolute;
    top: 16px;
    right: 16px;

    display: inline-block;
    background: #a7a7a7 0% 0% no-repeat padding-box;
    font: normal normal normal 11px/12px Arial;
    color: #ffffff;
    max-width: 70px;
    max-height: 18px;
  }
  .favorite {
    color: #a7a7a7;
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-size: 25px;
    line-height: 25px;
  }

  .card-img-top {
    margin: 0px;
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
    display: block;
    height: 72px;
  }
  .card-body {
    text-align: left;
    padding: 18px 16px 18px;
    height: 284px;
  }
  .card-subtitle {
    font-size: 11px;
    line-height: 15px;
  }
  .card-title {
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 20px;
  }
  .card-text {
    font-size: 13px;
  }
  .card-text.color {
    font-size: 11px;
    line-height: 18px;
  }

  .searching {
    text-align: center;
    background-color: ${Common.COLOR_LIGHT_GLAY};
    padding: 2rem;

    .spinner-border {
      width: 3rem;
      height: 3rem;
      color: ${Common.COLOR_GLAY};
      border-width: 2px;
    }
  }

  .space {
    padding-bottom: 160px;
  }
  ${media.xs_sm`
  .card-subtitle {
    width: calc(100% - 40px);
  }
`}
  ${media.sm`
  .row {
    margin-top: 0;
  }
  .list-pc{
    display: none !important;
  }
  .list-sp{
    display: block !important;
  }

`}
${media.md_lg`
  .row {
    margin-top:30px;
  }
  .col {
    border-width: 0;

    flex-basis: calc((100% - 60px) / 3);
    margin-right: 30px;
    margin-bottom: 30px;

    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
`}
${media.lg`
  .row {
    margin-top: 30px;
  }

  .card {
    margin: 1px;
    position: relative;
    border: 0;
    height: 333px;
    display: block;
  }

  .card-title {
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 32px;
    font: normal normal normal 22px/33px Helvetica Neue;
  }

  .card-body {
    padding: 27px 20px;
  }

  .favorite {
    color: #A7A7A7;
    position: absolute;
    top: 291px;
    right: 20px;
    font-size: 25px;
    line-height: 25px;
  }

  .new_label {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
  } 

  .card-img-top {
    margin: 0px;
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
    display: block;
    height: 113px;
  }
`}
${media.lg_xl`
  .row {
    margin-top: 30px;
  }
  .col {
    border-width: 0;

    flex-basis: calc((100% - 60px) / 3);
    margin-right: 30px;
    margin-bottom: 30px;

    &:nth-child(3n) {
      margin-right: 0px;
    }
  }
`}
${media.xl`
  .row {
    margin-top: 30px;
  }
  .list-pc{
    display: block !important;
  }
  .list-sp{
    display: none !important;
  }

  .col {
    border-width: 0;

    flex-basis: calc((100% - 120px) / 4);
    margin-right: 40px;
    margin-bottom: 30px;

    &:nth-child(4n) {
      margin-right: 0px;
    }
  }
`}
`
