import { createActions, handleActions } from 'redux-actions'
import { put, call, takeLatest } from 'redux-saga/effects'
import api from './api'
import {
  MENU_MAIN,
  MENU_MAIN_EN,
  MENU_SNS,
  MENU_SNS_EN,
  MENU_FOOTER_TOP,
  MENU_FOOTER_TOP_EN,
  MENU_FOOTER_1,
  MENU_FOOTER_EN_1,
  MENU_FOOTER_2,
  MENU_FOOTER_EN_2,
  MENU_FOOTER_BOTTOM,
  MENU_FOOTER_BOTTOM_EN,
} from '../constants/api-url'
import i18n from './i18n'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    set: (key, value) => ({key, value}),
    getMenuMain: () => ({}),
    getMenuMainEn: () => ({}),
    getMenuSns: () => ({}),
    getMenuSnsEn: () => ({}),
    getFooterMenuTop: () => ({}),
    getFooterMenuTopEn: () => ({}),
    getFooterMenu1: () => ({}),
    getFooterMenuEn1: () => ({}),
    getFooterMenu2: () => ({}),
    getFooterMenuEn2: () => ({}),
    getFooterMenuBottom: () => ({}),
    getFooterMenuBottomEn: () => ({}),
  },
  { prefix: '9999/menus' },
)

/*********************************
 * REDUCER
 ********************************/
const initialState = {
  menuMain: [], 
  menuMainEn: [], 
  menuSns: [], 
  menuSnsEn: [], 
  footerMenuTop: [],
  footerMenuTopEn: [],
  footerMenu1: [], 
  footerMenuEn1: [],
  footerMenu2: [], 
  footerMenuEn2: [],
  footerMenuBottom: [],
  footerMenuBottomEn: [],

  onlineUrl: '',
}

const reducer = handleActions({
  [actions.set]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),
}, initialState)


export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* menusSaga() {
  yield takeLatest(actions.getMenuMain, getMenuMain)
  yield takeLatest(actions.getMenuMainEn, getMenuMainEn)
  yield takeLatest(actions.getMenuSns, getMenuSns)
  yield takeLatest(actions.getMenuSnsEn, getMenuSnsEn)
  yield takeLatest(actions.getFooterMenuTop, getFooterMenuTop)
  yield takeLatest(actions.getFooterMenuTopEn, getFooterMenuTopEn)
  yield takeLatest(actions.getFooterMenu1, getFooterMenu1)
  yield takeLatest(actions.getFooterMenuEn1, getFooterMenuEn1)
  yield takeLatest(actions.getFooterMenu2, getFooterMenu2)
  yield takeLatest(actions.getFooterMenuEn2, getFooterMenuEn2)
  yield takeLatest(actions.getFooterMenuBottom, getFooterMenuBottom)
  yield takeLatest(actions.getFooterMenuBottomEn, getFooterMenuBottomEn)
}

// トップメニューを取得する
function* getMenuMain() {
  const result = yield call(api, {
    url: MENU_MAIN,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('menuMain', []))
    return
  }
  yield put(actions.set('menuMain', result.json))

  const nearOnline = result.json.find(menu => menu.url.match(/^https?:\/\/\[shop_link\]\/models\/ec\//))
  if (nearOnline) {
    yield put(actions.set('onlineUrl', '/web/' + i18n.language + nearOnline.url.replace(/^https?:\/\/\[shop_link\]/, '')))
  }
}

// トップメニューを取得する_en
function* getMenuMainEn() {
  const result = yield call(api, {
    url: MENU_MAIN_EN,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('menuMainEn', []))
    return
  }
  yield put(actions.set('menuMainEn', result.json))
}

// SNSメニューを取得する
function* getMenuSns() {
  const result = yield call(api, {
    url: MENU_SNS,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('menuSns', []))
    return
  }
  yield put(actions.set('menuSns', result.json))
}

// SNSメニューを取得する
function* getMenuSnsEn() {
  const result = yield call(api, {
    url: MENU_SNS_EN,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('menuSnsEn', []))
    return
  }
  yield put(actions.set('menuSnsEn', result.json))
}

// フッターメニュー_Top
function* getFooterMenuTop() {
  const result = yield call(api, {
    url: MENU_FOOTER_TOP,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenuTop', []))
    return
  }
  yield put(actions.set('footerMenuTop', result.json))
}

// フッターメニュー_Top_en
function* getFooterMenuTopEn() {
  const result = yield call(api, {
    url: MENU_FOOTER_TOP_EN,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenuTopEn', []))
    return
  }
  yield put(actions.set('footerMenuTopEn', result.json))
}

// フッターメニュー_1
function* getFooterMenu1() {
  const result = yield call(api, {
    url: MENU_FOOTER_1,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenu1', []))
    return
  }
  yield put(actions.set('footerMenu1', result.json))
}

// フッターメニュー_1_en
function* getFooterMenuEn1() {
  const result = yield call(api, {
    url: MENU_FOOTER_EN_1,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenuEn1', []))
    return
  }
  yield put(actions.set('footerMenuEn1', result.json))
}

// フッターメニュー_2
function* getFooterMenu2() {
  const result = yield call(api, {
    url: MENU_FOOTER_2,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenu2', []))
    return
  }
  yield put(actions.set('footerMenu2', result.json))
}

// フッターメニュー_2_en
function* getFooterMenuEn2() {
  const result = yield call(api, {
    url: MENU_FOOTER_EN_2,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenuEn2', []))
    return
  }
  yield put(actions.set('footerMenuEn2', result.json))
}

// フッターメニュー_bottom
function* getFooterMenuBottom() {
  const result = yield call(api, {
    url: MENU_FOOTER_BOTTOM,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenuBottom', []))
    return
  }
  yield put(actions.set('footerMenuBottom', result.json))
}

// フッターメニュー_bottom_en
function* getFooterMenuBottomEn() {
  const result = yield call(api, {
    url: MENU_FOOTER_BOTTOM_EN,
    method: 'GET',
  })
  if (!result.isSuccess) {
    yield put(actions.set('footerMenuBottomEn', []))
    return
  }
  yield put(actions.set('footerMenuBottomEn', result.json))
}