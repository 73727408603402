import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import {
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

class TermsOfServiceModal extends Component {

  render() {
    const {
    } = this.props

    return (
      <Content className="termsOfService_modal">
        <div className="title">
          <h1>利用規約</h1>
        </div>
        <div className="text-body">
          <Container fluid="lg">
            <div className="text">
              第１条 利用規約について<br />
              本規約は、株式会社フォーナインズ（以下、弊社と表記）が運営する
              フォーナインズ会員登録制ウェブサイト Four Nines Members（以下、本サイトと表記）に関し、
              サービスおよび利用条件を規定したものです。本サイトはお客様が会員登録して利用するものとします。<br />
              プライバシーポリシーについての詳しい内容は「個人情報保護ポリシー」を参照してください。
              弊社は本サイトのIDとパスワードを登録した時点で、
              下記各条項を承諾し同意しているものとみなします。<br /><br />

              第２条 目的・サービス内容<br />
              本サイトは、会員に対して、各種会員向けサービスおよび弊社商品を中心とした情報提供ならびに
              市場調査等のための情報の収集を目的とし、以下の活動を行います。<br />
              １）本サイトによる情報提供<br />
              ２）メッセージ、メール等による情報提供<br />
              ３）各種アンケート等による情報収集の実施<br /><br />

              第３条 会員資格<br />
              本サイトを利用できる会員は、本利用規約に同意された方となります。<br /><br />

              第４条 会員登録・登録内容の変更<br />
              １．前条にて規定した会員資格を有する方は、本サイトにて、IDとパスワードを登録していただくことにより会員となります。<br />
              ２．会員は、登録情報である各種情報内容に変更が生じた場合、速やかに本サイトより登録情報を修正していただきます。<br />
              ３．次の事項に該当する方は、会員登録をお断りいたします。また、会員登録手続き後であっても次の事項に該当することが判明した場合、
              弊社は会員資格を抹消することができるものとします。<br />
              １）暴力団、暴力団関係団体（関係者）、反社会的勢力またはその構成員またはそのおそれがある方<br />
              ２）他の会員に対し本サイトの利用を妨げる、または妨げるおそれがある方<br /><br />

              第５条 パスワードの管理<br />
              会員は、自己の責任において、本サイトのIDおよびパスワードを管理するものとし、<br />
              いかなる場合にも、IDおよびパスワードを第三者に譲渡または貸与することはできません。<br />
              弊社は、IDとパスワードの組み合わせが登録情報と一致して本サイトにログインされた場合には、<br />
              そのIDを登録している会員自身による利用とみなします。<br /><br />

              第６条 退会手続き<br />
              会員は退会の意思がある場合、本サイトより退会手続きを行ってください。<br /><br />

              第７条 会員資格の取消<br />
              弊社は、会員が以下の事項のいずれかに該当する場合、
              事前の通知なく会員資格を取り消し本サイトの利用を停止する場合があります。<br />
              １）本規約のいずれかの条項に違反した場合<br />
              ２）登録情報に虚偽の事実があることが判明した場合<br />
              ３）その他、弊社が本サイトの利用を適当でないと判断した場合<br /><br />

              第８条 禁止事項<br />
              会員は、本サイトの利用にあたり、以下の行為をしてはなりません。<br />
              １）法令または公序良俗に違反する行為<br />
              ２）犯罪行為に関連する行為<br />
              ３）弊社のサーバーまたはネットワークの機能を破壊または妨害する行為<br />
              ４）本サイトの運営を妨害または妨害するおそれのある行為<br />
              ５）他の会員に成りすます行為<br />
              ６）本サイトの他の利用者または第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為<br />
              ７）暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、<br />
              その他反社会的な内容を含み他人に不快感を与える表現を、投稿または送信する行為<br />
              ８）その他、弊社が不適切と判断する行為<br /><br />

              第９条 情報の共有と提供<br />
              弊社は、会員が登録いただいた内容を会員の同意を得ずに第三者に提供することはございません。
              但し、以下の場合に、個人情報を提供することがあります。<br />
              １）情報開示や共有について会員の同意がある場合。<br />
              ２）会員が希望する製品やサービスを提供するために、情報の提供や共有が必要と認められる場合。<br />
              ３）会員に製品やサービスを提供する目的で、弊社からの委託を受けて業務を行う会社が情報を必要とする場合。<br />
              ４）人の生命、身体又は財産の保護のために必要がある場合であって、会員の同意を得ることが困難であるとき。<br />
              ５）裁判所、検察庁、警察、弁護士会、消費者センターまたはこれらに準じた機関から、会員ID登録内容についての開示を求められた場合、
              弊社はこれに応じて情報を開示することができます。<br />
              ６）会員の行為が、弊社の権利、財産やサービスなどに損害を及ぼす可能性があり、
              それらの保護のため必要と認められる場合や、第三者に不利益を及ぼすと判断した場合は、<br />
              会員ID登録内容を当該第三者や警察または関連諸機関に通知することができます。<br /><br />

              第１０条 知的財産権等<br />
              本サイトが提供する文章、画像、映像等の著作権については、弊社に帰属し、
              著作権法、商標法、意匠法等により保護されています。本サイトより提供されるいかなる情報も<br />
              複製、販売、出版その他私的利用の範囲を超えて使用をすることはできません。<br /><br />

              第１１条 サービスの変更・停止<br />
              弊社は、以下のいずれかの事由があると判断した場合、
              会員に事前に通知することなく本サイトの全部または一部の提供を停止または中断することができるものとします。<br />
              １）本サイトにかかるコンピュータシステムの保守点検または更新を行う場合<br />
              ２）地震、落雷、火災、停電または天災などの不可抗力により、本サイトの提供が困難となった場合<br />
              ３）コンピュータまたは通信回線等が事故により停止した場合<br />
              ４）その他、弊社が本サイトの提供が困難と判断した場合<br /><br />

              第１２条 規約の変更<br />
              弊社は、必要と判断した場合には、会員に通知することなくいつでも本規約を変更することができるものとします。
              この場合、本サイト上に明示された施行日をもって、変更後の規約が適用されるものとします。<br /><br />

              第１３条 免責事項<br />
              弊社は、本サイトの変更または提供を中断することよって生じた損害について一切の責任を負いません。
              弊社の判断により、本サイトの全部または一部を適宜変更・廃止できるものとします。<br /><br />

              第１４条 損害賠償<br />
              会員は、本規約に違反し弊社に損害を与えた場合、その損害を賠償するものとします。<br /><br />

              第１５条 準拠法及び管轄裁判所<br />
              １）本規約は、日本法に準拠するものとします。<br />
              ２）本サイトに関連して会員と弊社との間で問題が生じた場合には、両者が誠意をもって協議するものとします。<br />
              ３）問題が協議によって解決できない場合には、東京簡易裁判所又は東京地方裁判所を専属管轄裁判所とする裁判によって決するものとします。<br /><br /><br />


              制定日: 2020年10月1日<br />
              施行日: 2020年10月1日<br />
              株式会社フォーナインズ

            </div>
          </Container>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(TermsOfServiceModal)
  )
)

const Content = styled.div`
  .modal-body {
    max-height:100%;
    background-color: #F5F5F2;
  }
  .title {
    padding: 10px 0px 0px 0px;
  }
  .text-body{
    .text{
      text-align: left;
      margin: 20px 0px 30px 0px;
      font-size: 12px;
      padding: 0px 0px 0px 10px;
    }
  }
`
