import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, call, takeLatest, delay, fork, join } from 'redux-saga/effects'
import { isEmpty } from '../util/common'
import api from './api'
import { MODELS } from '../constants/api-url'
import { waitProductClasses } from './master'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    set: (key, value) => ({ key, value }),
    findProductClasses: (class2_ids) => class2_ids,
    searchModels: (payload) => payload,
    searchModelsNext: (payload) => payload,
    addSearchModels: (models, meta, params) => ({ models, meta, params }),
  },
  { prefix: '9999/models' }
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  isEc: false,
  productClasses: [],
  selectedClass1: null,
  searchedModels: [],
  searchedMeta: {
    current_page: 0,
    last_page: 1,
  },
  searchedParams: {},
  isSearching: false,
}
const reducer = handleActions(
  {
    [actions.init]: () => initialState,
    [actions.set]: (state, action) => ({
      ...state,
      [action.payload.key]: action.payload.value,
    }),
    [actions.searchModels]: (state, action) => ({
      ...state,
      selectedClass1: action.payload,
      searchedModels: initialState.searchedModels,
      searchedMeta: initialState.searchedMeta,
      searchedParams: initialState.searchedParams,
      isSearching: true,
    }),
    [actions.searchModelsNext]: (state, action) => ({
      ...state,
      isSearching: true,
    }),
    [actions.addSearchModels]: (state, action) => ({
      ...state,
      searchedModels: [...state.searchedModels, ...action.payload.models],
      searchedMeta: {
        ...state.searchedMeta,
        ...action.payload.meta,
      },
      searchedParams: { ...action.payload.params },
      isSearching: false,
    }),
  },
  initialState
)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* modelsSaga() {
  yield takeLatest(actions.findProductClasses, findProductClasses)
  yield takeLatest(actions.searchModels, searchModels)
  yield takeLatest(actions.searchModelsNext, searchModels)
}

function* findProductClasses(action) {
  const productClasses = yield call(waitProductClasses)

  const class2_ids = action.payload.class2_ids
  const findedProductClasses = class2_ids.reduce((findedProductClasses, class2_id) => {
    // 分類1、2を見つける
    let findedProductClass1 = null
    let findedProductClass2 = null
    for (const productClass1 of productClasses) {
      findedProductClass2 = productClass1.product_class2s.find(
        (class2) => class2.web_catalog2_display_flag === '1' && class2.id === class2_id
      )
      if (!findedProductClass2) {
        continue
      }
      findedProductClass1 = productClass1
      break
    }

    // 検索済み分類1に分類2を追加する
    let alreadyFindedProductClass1 = findedProductClasses.find((class1) => class1.id === findedProductClass1.id)
    if (!alreadyFindedProductClass1) {
      alreadyFindedProductClass1 = {
        ...findedProductClass1,
        product_class2s: [],
      }
      findedProductClasses.push(alreadyFindedProductClass1)
    }
    alreadyFindedProductClass1.product_class2s.push(findedProductClass2)

    return findedProductClasses
  }, [])

  yield put(actions.set('productClasses', findedProductClasses))

  // 初期検索も続けて行う
  if (findedProductClasses.length === 1) {
    // 分類1が一つなら選択済みにする
    yield put(actions.searchModels(findedProductClasses[0]))
  } else if (action.payload.class1_id && findedProductClasses.length > 1) {
    const init_productClass = findedProductClasses.find((productClass) => productClass.id === action.payload.class1_id)

    // クエリパラメータのidと一致する商品分類1が存在する場合はその分類を選択済みにする
    !isEmpty(init_productClass)
      ? yield put(actions.searchModels(init_productClass))
      : yield put(actions.searchModels(null))
  } else {
    yield put(actions.searchModels(null))
  }
}

function* searchModels(action) {
  const {
    models: { isEc, productClasses, selectedClass1, searchedMeta },
  } = yield select()

  let class2_ids = []
  if (action.payload === null) {
    class2_ids = productClasses.reduce((class2_ids, class1) => {
      return [...class2_ids, ...class1.product_class2s.map((class2) => class2.id)]
    }, [])
  } else {
    class2_ids = action.payload.product_class2s.map((class2) => class2.id)
  }

  let page = searchedMeta.current_page + 1
  if (page > searchedMeta.last_page) {
    yield put(actions.addSearchModels([], {}))
    return
  }

  let request = {
    page,
    class2_ids,
    is_ec: isEc,
  }

  const result = yield call(api, {
    url: MODELS,
    method: 'GET',
    request,
    isLoading: false,
  })
  if (!result.isSuccess) {
    yield put(actions.addSearchModels([], {}))
    return
  }

  yield put(actions.addSearchModels(result.json.models, result.json.meta, request))
}
