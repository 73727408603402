import moment from 'moment'
import {
  CODE_CLASS_PRODUCT_SEARCH_ENTRY,
  LOAD_GENDER,
  LOAD_SYSTEM_VALUES,
  LOAD_MYPAGE_TOP,
  PRODUCTS,
  DEPARTMENTS,
  PRODUCT_CLASSES,
} from '../constants/api-url'
import { createActions, handleActions } from 'redux-actions'
import { select, put, call, takeEvery, takeLatest, all, take, delay, race } from 'redux-saga/effects'
import api from './api'
import { isEmpty, isEmptyObject } from '../util/common'
import {actions as WpActions} from './wp'
import { actions as errorActions } from './error'

/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    init: () => ({}),
    set: (key, value) => ({ key, value }),
    loadTopInformations: () => ({}),
    nextTopInformationProduct: information => information,
    setInformationProduct: (information, product) => ({ information, product }),

    loadProductClasses: () => ({}),
    loadedProductClasses: payload => payload,
  },
  { prefix: '9999/master'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  productSearchEntry1: [],
  productSearchEntry2: [],
  productSearchEntry3: [],
  productSearchEntry4: [],
  gender: [],
  rangeYears: [],
  online_image_numbers:[],
  online_discription: null,
  online_movie_path: null,
  online_sp_movie_path: null,
  topInformations: [],
  favoritePage: [],
  stores: [],
  productClasses: [],
  catalogPriceHidden: false,
}
//workersアクションを参照
const reducer = handleActions({
  [actions.init]: (state) => ({
    ...initialState,
    ...state,
  }),
  [actions.set]: (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }),
  [actions.loadedProductClasses]: (state, action) => ({
    ...state,
    productClasses: action.payload
  }),
  [actions.setInformationProduct]: (state, action) => {
    const information = state.topInformations.find(information => information.id === action.payload.information.id)
    if (!information) {
      return {
        ...state,
      }
    }
    const products = information.products.map(product => {
      if (product.id == action.payload.product.id) {
        return action.payload.product
      }
      return product
    })
    const show = products.filter(product => product.loading).length

    return {
      ...state,
      topInformations: state.topInformations.map((org) => {
        if (org.id === information.id) {
          return {
            ...information,
            show,
            products
          }
        }
        return org
      })
    }
  },
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* masterSaga() {
  yield takeLatest(actions.init, loadProductSearchEntry)
  yield takeLatest(actions.init, loadGender)
  yield takeLatest(actions.init, loadSystemValue)
  yield takeLatest(actions.init, loadDepartments)
  yield takeLatest(actions.init, loadProductClasses)
  yield takeLatest(actions.loadTopInformations, loadTopInformations)
  yield takeLatest(actions.nextTopInformationProduct, nextTopInformationProduct)

  yield takeLatest(actions.loadProductClasses, loadProductClasses)
}

function* loadProductSearchEntry()
{
  const results = yield all([...Array(4).keys()].map(idx => {
    return call(api, {
      url: CODE_CLASS_PRODUCT_SEARCH_ENTRY(idx + 1),
      method: 'GET',
      isLoading: false,
    })
  }));

  for (let idx = 0; idx < results.length; idx++) {
    let result = results[idx]
    if (!result.isSuccess) {
      continue
    }
    yield put(actions.set(`productSearchEntry${idx+1}`, result.json.code_class))
  }
}
// 性別の値を取得
function* loadGender()
{
  const result = yield call(api, {
    url: LOAD_GENDER,
    method: 'GET',
    isLoading: false,
  })
  if (!result.isSuccess) {
    return
  }
  yield put(actions.set('gender', result.json.code_class.codevalues))
}

// 誕生年の範囲を取得するために使用
function* loadSystemValue ()
{
  const result = yield call(api, {
    url: LOAD_SYSTEM_VALUES,
    method: 'GET',
    isLoading: false,
  })
  if (!result.isSuccess) {
    return
  }
  // system_valuesの値を取得
  yield put(actions.set('rangeYears', result.json.system_values.find((value) => value.key2 === 'birth_years')))
  yield put(actions.set('favoritePage', result.json.system_values.find((value) => value.key2 === 'per_page_favorite')))

  // システム値からオンラインストアで使用する画像番号配列を作成
  let online_image_numbers = result.json.system_values.find((value) => value.key2 === 'online_image_numbers');
  online_image_numbers = online_image_numbers.value_string.split(',').map(value => parseInt(value))
  yield put(actions.set('online_image_numbers', online_image_numbers))

  yield put(actions.set('online_discription', result.json.system_values.find((value) => value.key2 === 'online_discription')))

  // オンラインストアモードのトップで使う動画
  let online_movie_path = result.json.system_values.find((value) => value.key2 === 'online_movie')
  if(online_movie_path && online_movie_path.value_string) {
    online_movie_path = online_movie_path.value_string
    yield put(actions.set('online_movie_path', online_movie_path))
  }else {
    yield put(actions.set('online_movie_path', null))
  }

  let online_sp_movie_path = result.json.system_values.find((value) => value.key2 === 'online_sp_movie')
  if(online_sp_movie_path && online_sp_movie_path.value_string) {
    online_sp_movie_path = online_sp_movie_path.value_string
    yield put(actions.set('online_sp_movie_path', online_sp_movie_path))
  }else {
    yield put(actions.set('online_sp_movie_path', null))
  }

  let catalog_price_hidden = result.json.system_values.find((value) => value.key2 === 'catalog_price_hidden')
  if (catalog_price_hidden && catalog_price_hidden.value_string === "1") {
    yield put(actions.set('catalogPriceHidden', true));
  } else {
    yield put(actions.set('catalogPriceHidden', false));
  }
}

function* loadDepartments()
{
  const result = yield call(api, {
    url: DEPARTMENTS,
    method: 'GET',
    isLoading: false,
  })
  if (!result.isSuccess) {
    return
  }
  yield put(actions.set('stores', result.json.departments))
}

function* loadTopInformations()
{
  const result = yield call(api, {
    url: LOAD_MYPAGE_TOP,
    method: 'GET',
    isLoading: false,
  })
  if (!result.isSuccess) {
    return
  }

  const informations = result.json.code_class.codevalues.map(information => {
    let ids = `${information.attribute3},${information.attribute4},${information.attribute5},${information.attribute6},${information.attribute7},${information.attribute8},${information.attribute9},${information.attribute10}`.split(',')
    ids = ids.filter(id => id && id !== 'null')
    ids = [...new Set(ids)]

    return {
      ...information,
      total: ids.length,
      show: 0,
      products: ids.map(id => ({
        id,
        loaded: false,
        loading: false,
      })),
    }
  })
  
  yield put(actions.set('topInformations', informations))

  // wordpress画像の取得
  yield put(WpActions.top())
  
  yield all(informations.map(information => call(loadTopInformationProducts, information)))
  
}

function* loadProductClasses(action)
{
  const result = yield call(api, {
    url: PRODUCT_CLASSES,
    method: 'GET',
    isLoading: false,
  })
  if (!result.isSuccess) {
    return
  }
  yield put(actions.loadedProductClasses(result.json.product_class1s))
}

export function* waitProductClasses()
{
  const {
    master: {
      productClasses
    }
  } = yield select()
  if (!isEmptyObject(productClasses)) {
    return productClasses
  }

  yield put(actions.loadProductClasses())
  const { action, timeout } = yield race({
    action: take(actions.loadedProductClasses),
    timeout: delay(60 * 1000, true)
  })
  if (timeout) {
    // yield put(errorActions.displayError('タイムアウトです。ネットワーク環境を確認し、もう一度お試しください。'))
    return []
  }

  return action.payload
}

function* nextTopInformationProduct(action)
{
  yield call(loadTopInformationProducts, action.payload)
}

function* loadTopInformationProducts(information)
{
  const persistPage = 10

  const products = information.products
    .filter(product => product.loading === false)
    .slice(0, persistPage)
    .map(product => ({
      ...product,
      loading: true,
    }))

  yield all(products.map(product => put(actions.setInformationProduct(information, product))))

  const productsResult = yield call(api, {
    url: PRODUCTS,
    method: 'GET',
    request: {
      id_in: products.map(product => product.id),
      q: '',
      page: 0,
    },
    isLoading: false,
  })
  if (!productsResult.isSuccess) {
    return
  }

  yield all(products.map(product => put(actions.setInformationProduct(information, {
    ...product,
    loaded: true,
    ...(productsResult.json.products.find(result => result.id == product.id) || {})
  }))))

}


// function* loadStoreMaster(action) {
//   const { master:{ stores: loadedStores } } = yield select()
//   if (loadedStores.length) {
//     return
//   }

//   const payload = {
//     url: STORES,
//     method: 'GET',
//     request: '',
//     reqAuth: false,
//     isCors: true,
//   }
//   let response = yield call(api, payload)
//   if (isEmpty(response)) {
//     return
//   }

//   // 店舗に開始終了時間を付与
//   const timesResponse = yield call(api, {
//     url: TIME_TABLES,
//     method: 'GET',
//     request: {
//       type__include: [
//         'order_start',
//         'order_end',
//       ],
//     },
//   })
//   if (!timesResponse) {
//     return null
//   }

//   let stores = response.stores.map(store => ({
//     ...store,
//     order_start: timesResponse.time_tables.find(time => time.store_connection.store_id === store.id && time.type === 'order_start'),
//     order_end: timesResponse.time_tables.find(time => time.store_connection.store_id === store.id && time.type === 'order_end'),
//   }))

//   yield put(actions.setStoreMaster(stores))
// }
