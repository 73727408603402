import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { actions as authActions } from '../../modules/auth'
import { reduxForm, change } from 'redux-form'
import { Button } from 'reactstrap'

class LoginOutConfirmModal extends Component {
  render() {
    const { logout, set, onCancel = (e) => {} } = this.props

    return (
      <Content className="confirm">
        <div className="confirmMessage">ログアウトしてよろしいですか?</div>
        <div className="buttonArea">
          <Button onClick={() => logout()}>OK</Button>
          <Button onClick={() => onCancel()}>キャンセル</Button>
        </div>
      </Content>
    )
  }
}
const mapStateToProps = () => {}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(authActions.logout())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginOutConfirmModal))

const Content = styled.div`
  margin: 40px 20px;
  text-align: left;
  background-color: #F5F5F2;

    .confirmMessage{
      font-size: 19px;
      font-family: ヒラギノ角ゴ Pro
      letter-spacing: 2.03px
      margin: 5px 0px 0px 0px;
      text-align: left;
    }
    .buttonArea {
      display:flex;
      justify-content: space-between;
      margin-top:30px;
       
      button {
        width: 49%;
      }
    }
`
