import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { actions as locationActions } from '../../modules/location'
import { reduxForm, change, reset, formValueSelector } from 'redux-form'
import * as Common from '../../constants/common'
import { isEmpty, isEmptyObject } from '../../util/common'
import { actions as authActions } from '../../modules/auth'
import { HP_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import { actions as notificationsAction } from '../../modules/informations'
import { ENVIRONMENT } from '../../constants/api-config'
import Modal from '../../components/molecules/Modal'
import { withTranslation } from 'react-i18next'
import ShopListModal from '../../components/molecules/ShopListModal'
import { API_URL } from '../../constants/api-config'
import liff from '../../modules/liff'
import queryString from 'query-string'

class LoginCheck extends Component {
  componentDidMount() {
    const { token, member, checkAuth } = this.props

    if (!isEmpty(token) && isEmptyObject(member)) {
      checkAuth()
    } else {
      // 認証成功待ち
      window.addEventListener(
        'message',
        (e) => {
          const { action = null, payload = null } = e.data

          if (action === 'getAuthCode') {
            this.props.getAuth(payload)
          }
        },
        false
      )
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // 初回しかレンダリングさせたくない
    return false
  }

  render() {
    const { token } = this.props

    const { code = null } = queryString.parse(window.location.search)

    let current = `${window.location.protocol}//${window.location.host}/authCallback`
    const authUrl = `${API_URL}/sso/?redirect=${encodeURI(current)}`

    return <Content>{!liff.isInClient() && code === null && isEmptyObject(token) && <iframe src={authUrl} />}</Content>
  }
}

const mapStateToProps = (state) => {
  return {
    token: !isEmptyObject(state.auth.glaVissSso) ? state.auth.glaVissSso.token : null,
    member: state.member.ssoUser,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAuth: (code) => {
      dispatch(authActions.getAuth(code))
    },
    checkAuth: () => {
      dispatch(authActions.check())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCheck)

const Content = styled.div`
  display: none;
`
