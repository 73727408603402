import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { actions as locationActions } from '../../../modules/location'
import { isEmptyObject } from '../../../util/common'
import GlaVissEdit from './GlaVissEdit'
import SsoEdit from './SsoEdit'
import { IMG_URL } from '../../../constants/api-config'
import { actions as notificationsAction } from '../../../modules/informations'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'

class Edit extends Component {

  componentDidMount() {
    this.props.start()
  }

  render() {
    const {
      member,
      ssoUser,
      link,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickMember = () => {
      link('/member')
    }

    return (
      <Content className="member-edit">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => link('/member/info')}>お客様情報の確認</a></BreadcrumbItem>
            <BreadcrumbItem active>お客様情報の変更</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">お客様情報の確認・変更</div>
        {/* <div className="contents-subtitle">お客様情報の確認・変更</div>
        <h1 style={{margin: '50px'}}>お客様情報の確認</h1> */}

        {!isEmptyObject(member) && (
          <GlaVissEdit member={member}></GlaVissEdit>
        )}
        {isEmptyObject(member) && !isEmptyObject(ssoUser) && (
          <SsoEdit member={ssoUser}></SsoEdit>
        )}
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => link('/member/info')}>お客様情報の確認</a></BreadcrumbItem>
            <BreadcrumbItem active>お客様情報の変更</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    member: state.member.member,
    ssoUser: state.member.ssoUser,
    isWeb: state.location.isWeb,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    Edit
  )
)

const Content = styled.div`
margin: 0;
background-color: #F5F5F2;

.title{
  text-align: center;
  font-size: 12px;
}

.contents-title {
  margin-bottom: 40px;
}
`
