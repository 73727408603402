import React, { Component } from 'react'
import {
  Container,
  Row, 
  Col,
  Card,
  CardBody,
  CardText,
  CardHeader,
  CardFooter,
} from 'reactstrap'
import styled from 'styled-components'
import * as Common from '../../constants/common'
import Slider from 'react-slick';
import ProductImage from './ProductImage'
import ProductInfo from './ProductInfo'
import ProductDetail from './ProductDetail'
import {
  IoIosArrowForward,
  IoIosArrowBack,
} from 'react-icons/io'
import { isEmpty } from '../../util/common'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialSlide: (props.colors || []).findIndex(colorProduct => colorProduct.id == props.product.id) + 1,
      imageNo: 1,
    };
  }
  render() {
    const {
      product,
      colors = [],
      changeColor = product => {},
      header = null,
      children = null,
      isThumbnail = false,
      isDetail = false,
      isSize = false,
      isMovie = false,
      clickViewFlag
    } = this.props

    const {
      initialSlide,
      imageNo,
    } = this.state

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide,
      afterChange: (index) => {
        this.setState({initialSlide: index})
        if (index === 0) {
          return
        }
        this.setState({ imageNo: 1 })
        changeColor(colors[index-1])
      },
      nextArrow: <IoIosArrowForward className="slick-next"/>,
      prevArrow: <IoIosArrowBack className="slick-prev"/>
    }

    const clickView = (no) => {
      // NOTE 詳細画面からの場合のみ画像を変えるため
      if(clickViewFlag){
        if (this.slider) {
          this.slider.slickGoTo(0)
          this.setState({initialSlide: 0})
        }
        this.setState({ imageNo: no })
      }
    }

    const getImageClassName = () => {
      if(clickViewFlag){
        return 'image'
      }
      return 'imageNotCursor'
    }

    return (
      <Content className="product" size="lg">
        <Card>
          <CardHeader className="product-header">
            {typeof header === 'function' && colors.length > 0 && initialSlide > 0 && header(colors[initialSlide - 1])}
            {typeof header === 'function' && colors.length === 0 && header(product)}
            {typeof header !== 'function' && header}
          </CardHeader>

          {colors.length > 0 && (
            <>
              <Slider {...sliderSettings} ref={ref => this.slider = ref}>
                <div>
                  <ProductImage model={product.model} imageNo={imageNo}/>
                </div>
                {colors.map(colorProduct => (
                  <div key={colorProduct.id}>
                    <ProductImage product={colorProduct} imageNo={1}/>
                  </div>
                ))}
              </Slider>
              <ProductInfo product={initialSlide > 0 ? colors[initialSlide - 1] : product} color={initialSlide > 0} size={isSize} movie={isMovie}/>
            </>
          )}
          {colors.length === 0 && (
            <>
              <ProductImage product={product} imageNo={imageNo}/>
              <ProductInfo product={product} size={isSize} movie={isMovie}/>
            </>
          )}

          
          
          {isThumbnail && (
            <CardBody
              className="different-view"
            >
              <div className="title">ビュー</div>
              <CardText tag="div" className="thumbnail">
                <Container fluid={true}>
                  <Row xs={3}>
                    <Col onClick={() => clickView(1)}>
                      <div className={getImageClassName()}>
                        <img src={`${IMG_URL}/img/Model/${product.model.group_cd}_1.jpg`} onError={e => e.target.style.display = 'none'}/>
                      </div>
                      <div className="label">
                        FRONT
                      </div>
                    </Col>
                    <Col onClick={() => clickView(2)}>
                      <div className={getImageClassName()}>
                        <img src={`${IMG_URL}/img/Model/${product.model.group_cd}_2.jpg`} onError={e => e.target.style.display = 'none'}/>
                      </div>
                      <div className="label">
                        SIDE
                      </div>
                    </Col>
                    <Col onClick={() => clickView(3)}>
                      <div className={getImageClassName()}>
                        <img src={`${IMG_URL}/img/Model/${product.model.group_cd}_3.jpg`} onError={e => e.target.style.display = 'none'}/>
                      </div>
                      <div className="label">
                        TOP
                      </div>
                    </Col>
                  </Row>
                </Container>
              </CardText>
            </CardBody>
          )}
          {isDetail && (
            <ProductDetail product={product}/>
          )}
          {children && (
            <CardFooter className="product-footer">
              {children}
            </CardFooter>
          )}
        </Card>
      </Content>
    )
  }
}

export default Product

const Content = styled.div`
.different-view {
  border-bottom: 0;

  .title {
    border-top: solid 1px #727272;
    position: relative;
    margin: 0;
    padding: 20px 15px 20px 8px;
    font-size: 15px;
    letter-spacing: 1.5px;
  }

  .image {
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 0 5px;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .imageNotCursor {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 0 5px;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .label {
    text-align: center;
    font-size: 15px;
    letter-spacing: 2.1px;
    font-weight: 300;
    margin: 10px 0;
  }
}

.card {
  border: 0;
}
.card-header {
  background-color: ${Common.COLOR_WHITE};
  border-bottom: 0;
  padding-bottom: 0;
}
.card-body {
  padding-top: 0;
  padding-bottom: 0;
}
.card-footer {
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${Common.COLOR_WHITE};
  border-top: 0;
}

.product-header {
  z-index: 100;
}

.slick-prev {
  top: 30vw;
  left: 10px;
  z-index: 100;
  color: #707070;

  &:hover {
    color: #707070;
  }
}

.slick-next {
  top: 30vw;
  right: 10px;
  z-index: 100;
  color: #707070;

  &:hover {
    color: #707070;
  }
}

.slick-dots {
  width: 90vw;
  margin: auto;
  top: 60vw;
  bottom: auto;
  left: 0;
  right: 0;
  display: flex !important;
  justify-content: space-between;

  li {
    width: 100%;
    height: auto;
    margin: 0 1px;
    border-bottom: 1px solid #D8D8D8;

    &.slick-active {
      border-bottom-color: #707070;
    }

    button {
      width: 100%;
    
      &:before {
        display: none;
      }
    }
  }
}

${media.sm`
  .slick-prev {
    top: 190px;
  }

  .slick-next {
    top: 190px;
  }

  .slick-dots {
    width: 448px;
    top: 345px;
  }
`}
${media.md`
  .slick-prev {
    top: 320px;
  }

  .slick-next {
    top: 320px;
  }

  .slick-dots {
    width: 640px;
    top: 480px;
  }
`}
${media.lg`
  .slick-prev {
    top: 320px;
  }

  .slick-next {
    top: 320px;
  }

  .slick-dots {
    width: 640px;
    top: 480px;
  }
`}
`
