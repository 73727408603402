import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, call, takeLatest, delay, fork, join } from 'redux-saga/effects'
import { isEmpty } from '../util/common'
import api from './api'
import { SERIES } from '../constants/api-url'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    searchSeries: (payload) => payload,
    searchSeriesNext: (payload) => payload,
    addSearchSeries: (series, meta, params) => ({ series, meta, params }),
  },
  { prefix: '9999/series' }
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  searchedSeries: [],
  searchedMeta: {
    current_page: 0,
    last_page: 1,
  },
  searchedParams: {},
  isSearching: false,
}
const reducer = handleActions(
  {
    [actions.init]: () => initialState,
    [actions.searchSeries]: (state, action) => ({
      ...state,
      searchedSeries: initialState.searchedSeries,
      searchedMeta: initialState.searchedMeta,
      searchedParams: initialState.searchedParams,
      isSearching: true,
    }),
    [actions.searchSeriesNext]: (state, action) => ({
      ...state,
      isSearching: true,
    }),
    [actions.addSearchSeries]: (state, action) => ({
      ...state,
      searchedSeries: [...state.searchedSeries, ...action.payload.series],
      searchedMeta: {
        ...state.searchedMeta,
        ...action.payload.meta,
      },
      searchedParams: { ...action.payload.params },
      isSearching: false,
    }),
  },
  initialState
)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* seriesSaga() {
  //  yield takeEvery(actions.loadStoreMaster, loadStoreMaster)
  yield takeLatest(actions.searchSeries, searchSeries)
  yield takeLatest(actions.searchSeriesNext, searchSeries)
}

// 検索フォームが入力された行われる処理
function* searchSeries(action) {
  if (!action.payload) {
    yield put(actions.addSearchSeries([], {}))
    return
  }

  const { class1_cd = '', class2_cd = '' } = action.payload

  const {
    series: { searchedMeta },
  } = yield select()

  let page = searchedMeta.current_page + 1
  if (page > searchedMeta.last_page) {
    yield put(actions.addSearchSeries([], {}))
    return
  }

  let request = {
    page,
    class1_cd,
    class2_cd,
  }

  const result = yield call(api, {
    url: SERIES,
    method: 'GET',
    request,
    isLoading: false,
  })
  if (!result.isSuccess) {
    yield put(actions.addSearchSeries([], {}))
    return
  }

  yield put(actions.addSearchSeries(result.json.series, result.json.meta, request))
}
