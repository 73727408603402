/***************************************************
 * 色
 **************************************************/
// 予備
export const ACC_COLOR = '#245C72'
export const FONT_COLOR = '#000000'

/* メイン */
export const COLOR_BLACK = '#000000'
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_GLAY = '#818181'
export const COLOR_LIGHT_GLAY = '#EFEFEF'


/***************************************************
 * ターミナル
 **************************************************/

/***************************************************
 Googleアナリティクス public/index.htmlにも記載しているので注意
 **************************************************/
// 測定ID
export const GA = 'G-W3CPGV583L'
