import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { actions as locationActions } from '../../modules/location'
import { reduxForm, change, reset, formValueSelector } from 'redux-form'
import * as Common from '../../constants/common'
import { isEmpty, isEmptyObject } from '../../util/common'
import { actions as authActions } from '../../modules/auth'
import { HP_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import { actions as notificationsAction } from '../../modules/informations'
import {ENVIRONMENT} from '../../constants/api-config'
import Modal from '../../components/molecules/Modal'
import { withTranslation } from 'react-i18next'
import ShopListModal from '../../components/molecules/ShopListModal'
import { API_URL } from '../../constants/api-config'
import liff from '../../modules/liff'
import queryString from 'query-string'

class LoginCheckCallback extends Component {
  componentDidMount() {
    const {
      code
    } = queryString.parse(window.location.search)
  
    window.parent.postMessage({
      action: 'getAuthCode',
      payload: code
    }, '*')
  }

  render() {
    return <div></div>
  }
}

export default LoginCheckCallback
