import React, { Component } from 'react'
import {
  Collapse,
} from 'reactstrap'
import styled from 'styled-components'
import {
  IoIosAdd,
  IoIosRemove,
} from 'react-icons/io'
import { media } from '../../components/atomics/Media'

class ToggleTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isFirstOpen || false,
    };
  }

  render() {
    const {
      isOpen: isOpenLocal
    } = this.state

    const {
      title = '',
      isOpen: isOpenManual,
      OpenIcon = () => <img src="/images/arrow-small.svg"/>,
      CloseIcon = () => <img src="/images/arrow-up-small.svg"/>,
      toggle = (nextOpen) => { this.setState({isOpen: nextOpen}) },
      children,
      className = '',
    } = this.props

    let isOpen = isOpenLocal
    if (isOpenManual !== void 0) {
      isOpen = isOpenManual
    }

    return (
      <Content className={`toggle-tab ${className} ${isOpen?'toggle-tab-open':'toggle-tab-close'}`}>
        <div
          className="toggle-tab-title"
          onClick={() => toggle(!isOpen)}
        >
          <div>
            {title}
            <div className="toggle-tab-icon">
              {isOpen && (
                <CloseIcon/>
              )}
              {!isOpen && (
                <OpenIcon/>
              )}
            </div>
          </div>
        </div>
        <Collapse className="toggle-tab-body" isOpen={isOpen}>
          {children}
        </Collapse>
      </Content>
    )
  }
}

export default ToggleTab

const Content = styled.div`
border-top: solid 1px #727272;
border-bottom: solid 1px #727272;
text-align: left;

.toggle-tab-title {
  cursor:pointer;
  position: relative;
  margin: 0;
  padding: 20px 15px 20px 8px;
  font-size: 15px;

  > div {
    position: relative;
  } 
  .toggle-tab-icon {
    cursor:pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    color: #707070;
    svg {
      vertical-align: baseline;
    }
  }
}

${media.lg`
  border-top: solid 1px #DDDDDB;
  border-bottom: solid 1px #DDDDDB;
`}
`
