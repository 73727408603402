import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import Product from '../../../components/molecules/Product'
import { isEmptyObject } from '../../../util/common'
import { actions as locationActions } from '../../../modules/location'
import { actions as reservationAction } from '../../../modules/reservation'
import nl2br from 'react-nl2br'
import { IMG_URL } from '../../../constants/api-config'
import { actions as notificationsAction } from '../../../modules/informations'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'

class Reservations extends Component {
  componentDidMount() {
    this.props.start()
  }

  render() {
    const {
      reservations,
      link,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickMember = () => {
      link('/member')
    }

    return (
      <Content className="favorites">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem active>お取り置き</BreadcrumbItem>
          </Breadcrumb>
        </div>
        {/* <div className="back">
          <Button onClick={() => link('/member')}>
            <img src={`${IMG_URL}/img/ico/allow_left.png`}/>
          </Button>
        </div> */}
        <div className="contents-title">お取り置き</div>
        {/* <div className="contents-subtitle">お取り置き</div> */}
        {reservations.map(reservation => (
          <div key={reservation.id} className="reservation">
            {!isEmptyObject(reservation.product) && (
              <Product product={reservation.product}/>
            )}
            <div className="department">
              <h3>お取り置きご依頼店舗</h3>
              <div>{reservation.department.display_name}</div>
            </div>
            <div className="comment">
              {reservation.department.address1}{reservation.department.address2} {reservation.department.address3}<br />
          Tel. {reservation.department.phone_num1}-{reservation.department.phone_num2}-{reservation.department.phone_num3}<br />
              {nl2br(reservation.department.description)}
            </div>
            <div className="schedule">
              <h3>ご来店予定日</h3>
              <div>{moment(reservation.schedule_date).format('M月D日（ddd）')}</div>
            </div>
            <div className="cancel">
              上記期間内までにご来店の無い場合<br/>
              お取り置きはキャンセルとさせていただきます。
            </div>
          </div>
        ))}
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem active>お取り置き</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    products: state.favorites.products.filter(product => !isEmptyObject(product)),
    reservations: state.reservation.reservations,
    isWeb: state.location.isWeb,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
      dispatch(reservationAction.searchReservationProducts())
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    Reservations
  )
)

const Content = styled.div`
.contents-title {
  padding-bottom: 30px;
}

.reservation {
  border: 0;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;

  + .reservation {
    border-top: 0;
  }

  .department {
    border-top: 1px solid #D3D3D3;
    padding: 24px 0;
    text-align: center;
    font-size: 19px;

    h3 {
      font-size: 19px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 20px;
    }
  }

  .schedule {
    border-top: 1px solid #D3D3D3;
    padding: 24px 0;
    text-align: center;
    font-size: 28px;

    h3 {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  .comment {
    border-top: 1px solid #D3D3D3;
    padding: 24px 32px 40px;
    font-size: 14px;
    text-align: left;
  }

  .cancel {
    border-top: 1px solid #D3D3D3;
    padding: 24px 32px 40px;
    font-size: 14px;
    text-align: center;
  }
}
`
