import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, call } from 'redux-saga/effects'
import api from './api'
import {
  STOCKED_DEPARTMENTS,
  RECOMMEND,
  POST_RESERVATIONS,
  SEARCH_RESERVATIONS,
  PRODUCTS_SHOW
} from '../constants/api-url'
import { actions as locationActions } from './location'
import { isEmpty } from '../util/common'
import { actions as productDetailActions } from './productDetail'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    flowStock: payload => payload,
    setStocedDepartment: payload => payload,
    getRecommendedProducts: payload => payload,
    setRecommendedProducts: payload => payload,
    searchStocedDepartment: payload => payload,
    reservationProductStorage: payload => payload,
    setReservationInfo: payload =>payload,
    reserveRegistration: payload => payload,
    searchReservationProducts: payload => payload,
    setReservationProducts: payload => payload,
    setTemporaryReservations: payload => payload,
    judgeReservationLimit: payload => payload,
    setRegistrationProduct: payload => payload,
  },
  { prefix: '9999/reservation'},
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  stores: ([]), //在庫のある店舗
  recommendedProducts: [],
  reservationInfo: {}, //お取り置き情報
  reservations: [], //お取り置き確定商品
  temporaryReservations: [], //お取り置き依頼中商品と承認ずみ,
  registrationProduct: {}, //お取り置き予定の商品
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.setStocedDepartment]: (state, action) => ({
    ...state,
    stores: action.payload,
  }),
  [actions.setRecommendedProducts]: (state, action) => ({
    ...state,
    recommendedProducts: action.payload,
  }),
  [actions.setReservationInfo]: (state, action) => ({
    ...state,
    reservationInfo: action.payload,
  }),
  [actions.setReservationProducts]: (state, action) => ({
    ...state,
    reservations: action.payload
  }),
  [actions.setTemporaryReservations]: (state, action) => ({
    ...state,
    temporaryReservations: action.payload
  }),
  [actions.setRegistrationProduct]: (state, action) => ({
    ...state,
    registrationProduct: action.payload
  })
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* reservationSaga() {
  yield takeEvery(actions.flowStock, flowStock)
  yield takeEvery(actions.getRecommendedProducts, getRecommendedProducts)
  yield takeEvery(actions.searchStocedDepartment,　searchStocedDepartment)
  yield takeEvery(actions.reservationProductStorage,　reservationProductStorage)
  yield takeEvery(actions.reserveRegistration, reserveRegistration)
  yield takeEvery(actions.searchReservationProducts, searchReservationProducts)
  yield takeEvery(actions.judgeReservationLimit, judgeReservationLimit)
}

// 在庫がある店舗を取得する
function* flowStock(action) {

  let productId = action.payload

  //在庫店舗取得APIの戻り値
  const stocedDepartments = yield call(searchStocedDepartment, productId)

  // 取り置き予定の商品情報取得
  yield call(getRegistrationProduct, productId)

  // 画面遷移
  yield call(goStockUrl, productId, stocedDepartments)
}

// 在庫がある店舗を取得する
function* searchStocedDepartment(productId) {
  const result = yield call(api, {
    url: STOCKED_DEPARTMENTS(productId),
    method: 'GET',
  })

  if (!result.isSuccess) {
    return
  }
  yield put(actions.setStocedDepartment(result.json.departments))
  return result.json.departments
}

// 在庫とログインの状態を見て画面遷移
function* goStockUrl(productId, stocedDepartments) {

  const {auth} = yield select()

  let url

  if (stocedDepartments.length === 0) {
    url =  `/favorites/${productId}/reservation/outofstock`
    // NOTE 在庫なし画面で商品モーダルが不具合を起こすため
    yield put(productDetailActions.init())
  } else if(stocedDepartments.length != 0 && !isEmpty(auth.glaViss.token)) {
    url = `/favorites/${productId}/reservation`
  } else {
    url = `/favorites/${productId}/reservation/notlogged`
  }
  yield put(locationActions.link(url))
}

// おすすめ商品を取得
function* getRecommendedProducts(action) {

  const productId = action.payload
  yield call(getRegistrationProduct, productId)
  const result = yield call(api, {
    url: RECOMMEND(productId),
    method: 'GET',
  })
  if (!result.isSuccess) {
    return
  }
  yield put(actions.setRecommendedProducts(result.json.products))
}

// お取り置きデータをレデューサーに格納
function* reservationProductStorage(action) {

  const reservationInfo
    = action.payload
  
  const { member } = yield select()
  reservationInfo.customer_id = member.member.id

  reservationInfo.product_id = Number(reservationInfo.product_id)

  reservationInfo.department_id = 
  Number(reservationInfo.department_id)

  yield put(actions.setReservationInfo(reservationInfo))
}

// お取り置き登録 申し込みボタン
function* reserveRegistration(action) {

  const {
    success,
  } = action.payload

  const {
    reservation: {
      reservationInfo
    }
  } = yield select()

  const result = yield call(api, {
    url: POST_RESERVATIONS,
    method: 'POST',
    request: {
      ...reservationInfo
    },
  })
  if (!result.isSuccess) {
    return
  }

  success(result.json)
}

//　お取り置き検索
function* searchReservationProducts() {
  const { auth } = yield select()
  if (!auth.glaViss || !auth.glaViss.token) {
    yield put(actions.setReservationProducts([]))
    return
  }

  const result = yield call(api, {
    url: SEARCH_RESERVATIONS,
    method: 'GET',
    request: {
      situation_in: [0, 1]
    }
  })
  if (!result.isSuccess) {
    return
  }
  yield put(actions.setReservationProducts(result.json.reservations.filter(item => item.situation == '1')))
  yield put(actions.setTemporaryReservations(result.json.reservations))  
}

// お取り置き依頼の限度許容判定
function* judgeReservationLimit(action) {
  const {
    formValues,
    product
  } = action.payload
  const result = yield call(api, {
    url: '/api/mp/reservations/limit',
    method: 'GET',
  })
  let requestParam = {
    product_id: formValues.product_id,
    department_id: formValues.department_id,
    schedule_date: formValues.schedule_date,
    situation:formValues.situation
  }
  if (!result.isSuccess) {
    return 
  }else if(result.json.limit == false) {
    alert(result.json.message);
  } else {
    yield put(actions.reservationProductStorage(requestParam))  
    let url = `/favorites/${product.id}/reservation/notes`
    yield put(locationActions.link(url))
    
  }
  
}
// お取り置き予定の商品情報取得
function* getRegistrationProduct(productId) {
  const result = yield call(api, {
    url: PRODUCTS_SHOW(productId),
    method: 'GET',
  })
 
  if (!result.isSuccess) {
    return 
  }
  yield put(actions.setRegistrationProduct(result.json.product))  
  
}

