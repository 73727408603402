import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, change, formValueSelector, reset } from 'redux-form'
import { IoMdClose, IoMdHeartEmpty, IoMdHeart } from 'react-icons/io'
import * as Common from '../../../constants/common'
import { isEmptyObject, isEmpty } from '../../../util/common'
import { actions as locationActions } from '../../../modules/location'
import Products from '../../../components/molecules/Products'
import Product from '../../../components/molecules/Product'
import { actions as favoritesActions } from '../../../modules/favorites'
import { actions as reservationActions } from '../../../modules/reservation'
import { actions as productDetailActions } from '../../../modules/productDetail'
import { actions as notificationsAction } from '../../../modules/informations'
import Modal from '../../../components/molecules/Modal'
import ItemDeatil from '../Deatil'
import ShopListModal from '../../../components/molecules/ShopListModal'
import { IMG_URL } from '../../../constants/api-config'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'

class OutofStock extends Component {
  componentDidMount() {
    const { favorite_id } = this.props.match.params
    // おすすめ商品を取得
    this.props.getRecommendedProducts(favorite_id)
  }

  // ショップリスト　デバイスによってリンク変更
  linkShopList = () => {
    const url = 'https://www.fournines.co.jp/shoplist/'

    return window.location.assign(url)
  }

  render() {
    const {
      link,
      recommendedProducts,
      showRecommendProduct,
      product,
      shopListModal,
      member,
      resetForm,
      isWeb,
      location,
      i18n,
    } = this.props

    if (isEmptyObject(product)) {
      return ''
    }

    // モーダルウィンドウ開閉
    const onClickModel = (modal, value) => {
      this.props.set(modal, value)
    }

    // トップ画面に遷移
    const onClickHome = () => {
      if (isWeb) {
        if (i18n.language === 'en') {
          window.location.href = HP_EN_URL
        } else {
          window.location.href = HP_URL
        }
      } else {
        link('/')
      }
    }

    // 商品検索画面に遷移
    const onClickBack = () => {
      link('/items')
    }

    // 商品詳細画面に遷移
    const onProduct = (product) => (e) => {
      this.props.link(`/items/${product.product_cd}`)
      e.stopPropagation()
    }

    return (
      <Content className="reservation">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>トップページ</a>
            </BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && (
              <BreadcrumbItem>
                <a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem>
              <a onClick={onProduct(product)}>商品詳細</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>在庫なし</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">STOCK CHECKING</div>
        <div className="contents-subtitle">在庫確認</div>
        <div className="caption">
          申し訳ございません。
          <br />
          ご指定の商品は在庫がございません。
          <br />
          次回入荷につきましては、
          <br />
          お電話にてお問合せください。
        </div>
        {!isEmptyObject(member) && (
          <a
            target="_blank"
            rel="noopener"
            href={
              i18n.language === 'en'
                ? `${HP_EN_URL}/shops/four-nines-shop/list/`
                : `${HP_URL}/shops/four-nines-shop/list/`
            }
          >
            <Button block>フォーナインズ ショップリスト</Button>
          </a>
        )}
        {isEmptyObject(member) && (
          <a
            target="_blank"
            rel="noopener"
            href={i18n.language === 'en' ? `${HP_EN_URL}/shops/list/` : `${HP_URL}/shops/list/`}
          >
            <Button color="primary" block>
              ショップリスト
            </Button>
          </a>
        )}
        <Product
          product={product}
          headder={
            <div className="close">
              <IoMdClose size="2em" />
            </div>
          }
        />
        <div className="result">
          <div className="caption">おすすめアイテム</div>
          <Products products={recommendedProducts} hasMore={false} onClickMore={() => {}} />
        </div>
        <Modal className="width90" isOpen={shopListModal} onClose={() => onClickModel('shopListModal', false)}>
          <ShopListModal />
        </Modal>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>トップページ</a>
            </BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && (
              <BreadcrumbItem>
                <a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem>
              <a onClick={onProduct(product)}>商品詳細</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>在庫なし</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { favorite_id } = props.match.params
  const selector = formValueSelector('out_of_stock')
  return {
    id: selector(state, 'id'),
    product: state.reservation.registrationProduct || {},
    favorites: state.favorites.products || [],
    recommendedProducts: state.reservation.recommendedProducts || [],
    showRecommendProduct: state.productDetail.productDetail,
    shopListModal: selector(state, 'shopListModal'),
    member: state.member.member,
    isWeb: state.location.isWeb,
    referencePath: state.location.referencePath || '',
    referenceName: state.location.referenceName || '',
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    getRecommendedProducts: (id) => {
      dispatch(reservationActions.getRecommendedProducts(id))
    },
    set: (key, value) => {
      dispatch(change('out_of_stock', key, value))
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'out_of_stock',
    initialValues: {
      id: '',
    },
  })(withTranslation()(OutofStock))
)

const Content = styled.div`
  margin: 0 5px;

  .title {
    font-size: 1.5rem;
    text-align: center;
    margin: 0 15px;
    padding: 14px;
    border-bottom: 1px solid ${Common.COLOR_LIGHT_GLAY};
  }
  .caption {
    font-size: 0.8rem;
    margin: 1rem 15px;
    text-align: center;
  }
`
