import { connect } from 'react-redux'
import React, { Component } from 'react'
import { 
  Row, 
  Col, 
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import { reset } from 'redux-form'
import * as Common from '../../constants/common'
import { actions as locationActions } from '../../modules/location'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import { actions as notificationsAction } from '../../modules/informations'
import { actions as productsActions } from '../../modules/products'

class Footer extends Component {
  componentDidUpdate() {
  }

  render() {
    const {
      link,
      unread_total,
      resetForm,
    } = this.props

    const onClickHome = () => {
      link('/')
    }

    const onClickItem = () => {
      link('/items')
      resetForm('search_products')
      this.props.searchProduct()
    }

    const onClickFavorite = () => {
      link('/favorites')
    }

    const onClickInformation = () => {
      link('/informations')
    }

    const onClickMember = () => {
      link('/member')
    }

    return (
      <Content>
        <div className="copylight">
          <div className="logo">
            <img src={`${IMG_URL}/img/ico/logo_footer_2.png`}/>
          </div>
        </div>

        <nav>
          <Container>
            <Row className='icon'>
              <Col href="#" className="home" onClick={() => onClickHome()}><img src={`${IMG_URL}/img/ico/GN_home_2.png`} width="18" height="22" alt=""/></Col>
              <Col href="#" className="item" onClick={() => onClickItem()}><img src={`${IMG_URL}/img/ico/GN_search_2.png`} width="18" height="21" alt=""/></Col>
              <Col href="#" className="favorite" onClick={() => onClickFavorite()}><img src={`${IMG_URL}/img/ico/GN_favorite_2.png`} width="20" height="18" alt=""/></Col>
              <Col href="#" className="information" onClick={() => onClickInformation()}>
                <img src={`${IMG_URL}/img/ico/GN_information_2.png`} width="22" height="22" alt=""/>
                {unread_total !== 0 && <div className="unread_badge">{' '}</div>}
              </Col>
              <Col href="#" className="member" onClick={() => onClickMember()}><img src={`${IMG_URL}/img/ico/GN_myaccount_2.png`} width="14" height="24" alt=""/></Col>
            </Row>
          </Container>
        </nav>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    unread_total: state.informations.searchedMeta.unread_total,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
    searchProduct: () => {
      dispatch(productsActions.searchProduct({}))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

const Content = styled.footer`
  color: ${Common.FONT_COLOR};
  background-color: ${Common.COLOR_WHITE};

  .sns {
    padding-top: 25px;
    padding-bottom: 25px;
    color: #A7A7A7;
    font-size: 37px;
    text-align: center;

    svg {
      margin: 0 30px;
    }
  }

  .icon{
    cursor:pointer;
  }

  .list-group-item {
    padding: 16px 0 16px 32px;
    font-size: 12px;
    letter-spacing: 2px;
    border-color: #E5E5E5;
  }

  a {
    color: ${Common.FONT_COLOR};
  }

  .copylight {
    img {
      max-height: 82px;
      max-width: 100vw;
    }

    text-align: center;
    padding: 0;
    color: #909192;
    font-size: 14px;
    padding-bottom: 60px;
  }

  nav {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    background: #EFEFEF;
    text-align: center;
    z-index: 1000;

    .row {
      align-items: center;

      .col {
        position: relative;
        width: 20%;
        padding: 10px 0 15px;

      }
    }
  }
  .unread_badge{
    background: #FF5D5D;
    position:absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: auto;
    bottom: 3px;
    left: 0;
    right:0;
  }


  ${media.sm`
    nav {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }
  `}
  ${media.lg`
    nav {
      display: none;
    }
    .copylight {
      padding-bottom: 0;
    }
  `}
`
