import {createStore, compose, applyMiddleware} from 'redux'
import createRootReducer from '../modules'
import {routerMiddleware} from 'connected-react-router'
import persistState from 'redux-localstorage'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../modules/indexSaga'

export default function configureStore(history, initialState) {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
      ),
      persistState([
        'auth',
        'master',
        'location',
      ])
    )
  )

  sagaMiddleware.run(rootSaga)

  return store
}
