import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row, 
  Col,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm } from 'redux-form'
import { actions as reservationActions } from '../../../modules/reservation'
import * as Common from '../../../constants/common'
import { actions as locationActions } from '../../../modules/location'
import Product from '../../../components/molecules/Product'
import moment from 'moment'
import nl2br from 'react-nl2br'
import { reset } from 'redux-form'
import { actions as notificationsAction } from '../../../modules/informations'
import { media } from '../../../components/atomics/Media'
import { withRouter } from 'react-router'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'
import { isEmptyObject, isEmpty } from '../../../util/common'

class Notes extends Component {
  componentDidMount() {
    this.props.start()
  }

  render() {
    const {
      product,
      department,
      reservationInfo,
      link,
      reserveRegistration,
      resetForm,
      path,
      isWeb,
      location,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickItem = () => {
      link('/items')
    }

    const onClickBack = () => {
      this.props.history.goBack()
    }
    const onProduct = product => e => {
      this.props.link(`/items/${product.product_cd}`)
      e.stopPropagation()
    }

    //お取り置きについて注意事項
    return (
      <Content className="reservation-notes">
        <div  className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && <BreadcrumbItem><a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a></BreadcrumbItem>}
            <BreadcrumbItem><a onClick={() => onClickBack()}>商品詳細</a></BreadcrumbItem>
            <BreadcrumbItem active>在庫確認</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">STOCK CHECKING</div>
        <div className="contents-subtitle">在庫確認</div>
        <h1>お取り置きについて</h1>
        <Container className="contents-wrap">
          <Row xs={1} lg={2}>
            <Col>
              <Product
                product={product}
              />
              <div className="caption">
                お取り置きの可否はメッセージにてご連絡させていただきます。店舗在庫は常に変動しているため、商品が確保できない場合がございます。<br/>
                <br/>
                閉店1時間前までにお申し込みの場合、当日中に在庫確認をいたします。<br/>
                営業時間外にお申し込みの場合、翌営業日の回答とさせていただきます。<br/>
                {/* <br/> */}
                <br/>
              </div>
            </Col>
            <Col>
              <div className="department">
                <h3>お取り置きご依頼店舗</h3>
                <div>{department.display_name}</div>
              </div>
              <div className="comment">
                {department.address1}{department.address2} {department.address3}<br/>
                Tel. {department.phone_num1}-{department.phone_num2}-{department.phone_num3}<br/>
                {nl2br(department.description)}
              </div>
              <div className="schedule">
                <h3>ご来店予定日</h3>
                <div>{moment(reservationInfo.schedule_date).format('YYYY年 MM月DD日（ddd）')}</div>
              </div>
              <div className="footer">
                <Button color="primary" block onClick={e => reserveRegistration(`/favorites/${product.id}/reservation/confirm`)}>
                  申し込む
                </Button>
                <Button block onClick={() => this.props.history.goBack()}>
                  戻る
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <hr className="list-pc-line"></hr>
        <div  className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && <BreadcrumbItem><a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a></BreadcrumbItem>}
            <BreadcrumbItem><a onClick={onProduct(product)}>商品詳細</a></BreadcrumbItem>
            <BreadcrumbItem active>在庫確認</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { favorite_id } = props.match.params
  return {
    product: state.favorites.products.find(product => product.id == favorite_id),
    department: state.reservation.stores.find(store => store.id == state.reservation.reservationInfo.department_id),
    reservationInfo: state.reservation.reservationInfo,
    path:state.location.path,
    referencePath: state.location.referencePath || '',
    referenceName: state.location.referenceName || '',
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    reserveRegistration: (url) => {
      dispatch(reservationActions.reserveRegistration({
        success: (json) => {
          dispatch(locationActions.link(url))
        }
      }))
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'Notes',
    initialValues: {
    }
  })(
    withTranslation()(
      Notes
    )
  )
)

const Content = styled.div`
  margin: 0 5px;

  .btn{
    height: 60px;
  }

  .caption{
    border-top: 1px solid #707070;
    font-size: 14px;
    line-height: 25px;
    margin: 0 20px;
    padding: 20px 0;
    // padding: 20px 20px 25px 20px;
    text-align: left;
  }

  .contents-wrap {
    background-color: #ffffff;
    margin-bottom: 200px;
  }

  .department {
    background-color: #FFFF;
    border-top: 1px solid #D3D3D3;
    padding: 24px 0;
    text-align: center;
    font-size: 19px;

    h3 {
      font-size: 19px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 20px;
    }
  }

  .schedule {
    background-color: #FFFF;
    border-top: 1px solid #D3D3D3;
    padding: 24px 0;
    text-align: center;
    font-size: 28px;

    h3 {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  .comment {
    background-color: #FFFF;
    border-top: 1px solid #D3D3D3;
    font-size: 14px;
    line-height: 25px;
    padding: 20px;
    text-align: left;
  }

  ${media.sm`
  .contents-wrap {
    .col {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`}
${media.lg`
  .contents-wrap {
    .col {
      padding-top: 20px;
    }
  }
`}
`

