import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import {
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

class PrivacyPolicyModal extends Component {

  render() {
    const {
    } = this.props

    return (
      <Content className="privacyPolicy_modal" > 
        <div className="title">
          PRIVACY POLICY　
          <a className="sabtitle">個人情報保護方針</a>
        </div>
        <div className="text-body">
          <Container fluid="lg">
            <div className="text">
              私たちフォーナインズグループおよび当サイトは、
              お客様からお預かりした個人情報の重要性を深く認識し、
              個人情報保護に関する法令に基づいて以下のポリシーを掲げ、
              お客様からお預かりしている大切な個人情報の適正な管理
              ・利用と保護に努めます（ここでいうフォーナインズグループとは、株式会社フォーナインズ及び、
              「フォーナインズ 銀座本店」「フォーナインズ GINZA SIX店」「フォーナインズ 銀座並木通りSalon」
              「フォーナインズ 丸の内 イセタンサローネ メンズ店」「フォーナインズ 渋谷スクランブルスクエア店」
              「フォーナインズ 伊勢丹新宿店メンズ館」「フォーナインズ 玉川高島屋S・C店」「フォーナインズ 青山店」
              「フォーナインズ 横浜タカシマヤ店」「フォーナインズ ジェイアール名古屋タカシマヤ店」
              「フォーナインズ 大阪タカシマヤ店」「フォーナインズ・フィールサン イセタン羽田ストア」の12店舗を指します）。<br /><br /><br />
 
              <div className="heading">個人情報とは</div>
              氏名･住所・電話番号・メールアドレス・生年月日・性別等のほか、
              個人別に付与された番号・記号など、お客様個人を特定できる情報（当該情報のみでは識別できないけれども、
              他の情報と容易に照合することができ、それにより個人を識別できるものを含む）。<br /><br /><br />

              <div className="heading">個人情報収集の目的について</div>
              フォーナインズグループおよび当サイトは、お客様からお預かりする個人情報の利用目的を以下の通りに定め、
              その他の目的には利用しないものといたします。<br /><br />

              <div className="detail">
              ●お客様の眼鏡・サングラスなどの作製<br />
              ●お客様が購入された眼鏡・サングラスなどの商品の管理とお渡し<br />
              ●お客様へのダイレクトメール（アフターフォローのご案内状など）の送付<br />
              ●お客様が購入された商品の調整・修理などのアフターフォロー<br />
              ●お客様からお問い合わせをいただいた場合の回答・連絡<br />
              ●より良い商品を開発するためのデータの集約と分析<br /><br /><br />
              </div>

              <div className="heading">個人情報の共同利用について</div>
              お客様へ上記および共通のサービス・アフターフォローを提供する目的で、
              フォーナインズグループにおいて、お客様の同意を得た上で、
              お預かりした個人情報を共有させていただきます。<br /><br />

              共同利用する個人情報<br />
              氏名・住所・電話番号・メールアドレス・生年月日・性別・眼鏡の作製度数
              ・購買履歴のほか、個人別に付与された番号・記号を、共同利用いたします。<br /><br />

              共同利用者の範囲について<br />
              共同利用はフォーナインズグループおよび当サイト内で行います。<br /><br />

              共同利用を行う際の利用目的について<br />
              お客様からお預かりする個人情報の共同利用目的を以下の通りに定めます。<br />
              なお、利用目的はフォーナインズグループおよび当サイトで定めた利用目的を超えるものではありません。<br /><br />

              <div className="detail">
              ●お客様の眼鏡・サングラスなどの作製<br />
              ●お客様が購入された眼鏡・サングラスなどの商品の管理とお渡し<br />
              ●お客様へのダイレクトメール（アフターフォローのご案内状など）の送付<br />
              ●お客様が購入された商品の調整・修理などのアフターフォロー<br />
              ●お客様からお問い合わせをいただいた場合の回答・連絡<br />
              ●より良い商品を開発するためのデータの集約と分析<br />
              ●お客様への各店舗共通したサービス･アフターフォローの提供<br /><br /><br />
              </div>

              <div className="heading">個人情報の開示・訂正・利用停止について </div>
              お客様ご本人の個人情報について、開示・訂正･利用停止等をご希望される場合は、ご本人であることを確認したうえで、速やかに対応いたします。<br /><br />

              開示等のご要請の対象となる個人情報について<br />
              お客様からお預かりしている個人情報で、開示の対象となる情報は以下の通りです。<br />
              1.氏名 2.住所 3.性別 4.生年月日 5.電話番号 6.メールアドレス 7.眼鏡の作製度数 8.購買履歴<br /><br />

              開示等の受付先<br />
              お客様からお預かりした個人情報の開示等の受付先は、情報を収集させていただいた店舗とさせていただきます。<br /><br />

              ご本人の確認について<br />
              お客様からお預かりしている個人情報の開示等を行うに当たり、「個人情報の保護に関する法律」に基づき、
              お客様ご本人および代理人であることを以下の書類によりご確認させていただきます。<br /><br />

              お客様ご本人の場合<br />
              顔写真がありご本人と確認できる以下のいずれか。<br /><br />

              <div className="detail">
              ・運転免許証<br />
              ・旅券（パスポート）<br />
              ・学生証<br />
              ・住民基本台帳カード（「顔写真」「生年月日」「住所」が記載されているもの）<br />
              ・各種福祉手帳<br /><br />
              </div>
              

              代理人の場合<br />
              <div className="detail">
              ・本人および代理人について、上記のいずれかでそれぞれの確認ができるもの<br />
              ・代理を示す委任状（本人の署名があるもの）<br /><br />
              </div>

              なお上記確認書類は、ご来店か郵送で確認をさせていただきます。<br />
              代理人の場合は、ご来店でのみ受付をさせていただきます。<br />
              ※郵送の場合は、上記確認書類のコピーと住民票にて確認させていただきます。<br /><br /><br />

              <div className="heading">開示方法について</div>
 	            個人情報の開示方法は、所定の書面をご用意しております。開示のご要請いただいた情報を記入後、お客様ご本人に直接または郵送にてご提示いたします。<br /><br /><br />

              <div className="heading">保有個人データについて </div>
 	            私たちフォーナインズグループおよび当サイトは、2005年4月1日からの「個人情報保護に関する法律」
              完全施行以前に取得した保有個人データ（お客様情報）は、
              完全施行後にお預かりする個人情報と同様に適正な管理・利用と保護に努めます。<br /><br /><br />

              <div className="heading">個人情報取扱事業者の名称について</div>
              お客様の個人情報をお預かりしているフォーナインズグループは、以下の通りです。<br /><br />

              株式会社フォーナインズ<br />
              フォーナインズ 銀座本店<br />
              フォーナインズ GINZA SIX店<br />
              フォーナインズ 銀座並木通りSalon<br />
              フォーナインズ 丸の内 イセタンサローネ メンズ店<br />
              フォーナインズ 渋谷店<br />
              フォーナインズ 伊勢丹新宿店メンズ館<br />
              フォーナインズ 玉川高島屋S・C店<br />
              フォーナインズ 青山店<br />
              フォーナインズ 横浜タカシマヤ店<br />
              フォーナインズ ジェイアール名古屋タカシマヤ店<br />
              フォーナインズ 大阪タカシマヤ店<br />
              フォーナインズ・フィールサン イセタン羽田ストア<br /><br /><br />

              <div className="heading">保有個人データの利用目的について</div>
              お客様からお預かりしている個人情報は以下の通りに利用目的を定め、その他の目的には利用しないものとしております。<br /><br />

              <div className="detail">
              ●お客様の眼鏡・サングラスなどの作製<br />
              ●お客様が購入された眼鏡・サングラスなどの商品の管理とお渡し<br />
              ●お客様へのダイレクトメール（アフターフォローのご案内状など）の送付<br />
              ●お客様が購入された商品の調整・修理などのアフターフォロー<br />
              ●お客様からお問い合わせをいただいた場合の回答・連絡<br />
              ●より良い商品を開発するためのデータの集約と分析<br /><br />
              </div>

              保有個人データの共同利用について<br />
              お客様へ上記および共通のサービス・アフターフォローを提供する目的で、フォーナインズグループにおいて、
              お預かりしている個人情報を共有させていただいております。<br /><br />

              保有個人データの開示・訂正・利用停止等について<br />
              お預かりしている個人情報について、開示・訂正･利用停止等をご希望される場合は、
              ご本人であることを確認したうえで、速やかに対応いたします。<br /><br /><br />

              <div className="heading">個人情報の第三者への提供について</div>
 	            フォーナインズグループおよび当サイトは、法令に定める場合を除き、お客様からお預かりした個人情報を、
               あらかじめご本人の同意を得ることなく、第三者に開示・提供をいたしません。<br /><br /><br />

              <div className="heading">個人情報の管理について</div>
 	            お預かりした個人情報の紛失・改ざんおよび漏洩等の防止のために、適切な安全管理に努めます。<br /><br /><br />

              <div className="heading">変更・改善について</div>
              「個人情報の取扱いについて」は、個人情報保護に関する法令等を遵守するとともに適宜見直し、
              改善を図って参ります。変更があった場合は、フォーナインズグループおよび当サイトにてお知らせいたします。<br /><br /><br />

              <div className="heading">個人情報のお問い合わせ窓口について </div>
              株式会社フォーナインズの運営するFacebookページに関連した個人情報についてのお問い合わせは、下記宛てにお願いいたします。<br /><br />

              株式会社フォーナインズ<br />
              〒158-0097 東京都世田谷区用賀4-10-1世田谷ビジネススクエアタワー15階 　TEL03-5797-2249<br /><br />

              上記以外の個人情報についてのお問い合わせは、下記店舗までお願いいたします。<br /><br />

              フォーナインズ 銀座本店<br />
              〒104-0061 東京都中央区銀座3-5-7 マツザワビル2階・3階　TEL 03-3535-4949<br /><br />

              フォーナインズ GINZA SIX店<br />
              〒104-0061 東京都中央区銀座6-10-1 GINZA SIX 5F　TEL 03-6264-5049<br /><br />

              フォーナインズ 銀座並木通りSalon<br />
              〒104-0061 東京都中央区銀座7-5-4 海東ビル1階　TEL 03-6218-4949<br /><br />

              フォーナインズ 丸の内 イセタンサローネ メンズ店<br />
              〒100-0005 東京都千代田区丸の内3-3-1 新東京ビル1階　イセタンサローネ メンズ　TEL 03-6273-4749<br /><br />

              フォーナインズ 渋谷スクランブルスクエア店<br />
              〒150-6107 東京都渋谷区渋谷2-24-12 渋谷スクランブルスクエア7階　TEL 03-6712-5949<br /><br />

              フォーナインズ 伊勢丹新宿店メンズ館<br />
              〒160-0022 東京都新宿区新宿3-14-1 伊勢丹新宿店メンズ館8階　TEL 03-3350-7649<br /><br />

              フォーナインズ 玉川高島屋S・C店<br />
              〒158-0094 東京都世田谷区玉川3-17-1 玉川高島屋S･C南館5階　TEL 03-5491-2649<br /><br />

              フォーナインズ 青山店<br />
              〒107-0062 東京都港区南青山5-4-7 ジブラルタ生命南青山ビル1階　TEL 03-6419-1349<br /><br />

              フォーナインズ 横浜タカシマヤ店<br />
              〒220-8601 神奈川県横浜市西区南幸1-6-31　横浜タカシマヤ6階　TEL 045-313-3049<br /><br />

              フォーナインズ ジェイアール名古屋タカシマヤ店<br />
              〒450-6001 愛知県名古屋市中村区名駅1-1-4 ジェイアール名古屋タカシマヤ7階　TEL 052-566-3949<br /><br />

              フォーナインズ 大阪タカシマヤ店<br />
              〒542-8510 大阪府大阪市中央区難波5-1-5　大阪タカシマヤ3階　メンズコンテンポラリー　TEL 06-6630-8649<br /><br />

              フォーナインズ・フィールサン イセタン羽田ストア<br />
              〒144-0041 東京都大田区羽田空港3-3-2 羽田空港国内線第1 旅客ターミナル2 階 出発ゲートラウンジ 北ウイング<br />
              TEL 03-5757-8700（直通）<br /><br />
            </div>
          </Container>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    withRouter(PrivacyPolicyModal)
  )
)

const Content = styled.div`
  .modal-body {
    max-height:100%;
    background-color: #F5F5F2;
  }
  .title {
    font-size: 18px;
    margin: 20px 0px 20px 24px;
    padding: 10px 0px 0px 0px;
  }
  .sabtitle {
    font-size: 14px;
  }
  .text-body{
    .text{
      text-align: left;
      margin: 20px 0px 30px 0px;
      font-size: 12px;
      padding: 0px 0px 0px 10px;
    }
    .heading{
      text-align: left;
      font-size: 15px;
    }
    .detail{
      padding-left:10px;
    }
  }
`
