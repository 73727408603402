import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, call } from 'redux-saga/effects'
import api from './api'
import { PRODUCTS_SHOW, PRODUCTS, PRODUCTS_SHOW_BY_CODE, EC_STOCKS, OTHER_TYPES_SIZES } from '../constants/api-url'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    getProductDetail: (payload) => payload,
    changeProductDetail: (payload) => payload,
    setProductDetail: (payload) => payload,
    setColorGroupProducts: (payload) => payload,
    setEcStocks: (payload) => payload,
    setOtherTypesSizes: (payload) => payload,
  },
  { prefix: '9999/productDetail' }
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  productDetail: {},
  colorGroupProducts: [],
  ecStocks: {},
  otherTypesSizes: {},
}
const reducer = handleActions(
  {
    [actions.init]: () => initialState,
    [actions.getProductDetail]: (state, action) => ({
      ...state,
      productDetail: {
        ...initialState.productDetail,
      },
      colorGroupProducts: initialState.colorGroupProducts,
    }),
    [actions.setProductDetail]: (state, action) => ({
      ...state,
      productDetail: action.payload,
    }),
    [actions.setColorGroupProducts]: (state, action) => ({
      ...state,
      colorGroupProducts: action.payload,
    }),
    [actions.setEcStocks]: (state, action) => ({
      ...state,
      ecStocks: action.payload,
    }),
    [actions.setOtherTypesSizes]: (state, action) => ({
      ...state,
      otherTypesSizes: action.payload,
    }),
  },
  initialState
)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* productDetailSaga() {
  yield takeEvery(actions.getProductDetail, getProductDetail)
  yield takeEvery(actions.changeProductDetail, getProductDetail)
}

// 検索フォームが入力された行われる処理
function* getProductDetail(action) {
  // 検索フォーム入力
  const { id = null, code = null } = action.payload
  if (!id && !code) {
    return
  }

  const {
    productDetail: { colorGroupProducts = [] },
  } = yield select()
  const colorGroupProduct = colorGroupProducts.find(
    (product) => product.id === parseInt(id) || product.product_cd === code
  )
  if (colorGroupProduct) {
    yield put(actions.setProductDetail(colorGroupProduct))
    return
  }

  const result = yield call(api, {
    url: id ? PRODUCTS_SHOW(id) : PRODUCTS_SHOW_BY_CODE(code),
    method: 'GET',
    request: {
      with_ec: true,
    },
  })

  if (!result.isSuccess) {
    return
  }

  // 各modelに所属する商品がmypageに表示する商品がない時そのモデルを取得結果から除く
  let product = result.json.product
  let models
  if (product.series !== null) {
    models = product.series.models.reduce((prev_models, model) => {
      if (model.model_products.length > 0) {
        prev_models.push(model)
      }
      return prev_models
    }, [])
    // 上記で作成された新しいmodelsに現状のmodelsを更新
    product.series.models = models
  }

  yield put(actions.setProductDetail(product))

  if (result.json.product.type_cd !== null) {
    yield call(getOtherTypesSizes, product.product_group_id3)
  }

  // TODO: 色グループを取得する条件を付けたい
  yield call(getColorGroupProducts, product.product_group_id3)
}

function* getColorGroupProducts(product_group_id3) {
  if (!product_group_id3) {
    yield put(actions.setColorGroupProducts([]))
    return
  }

  const result = yield call(api, {
    url: PRODUCTS,
    method: 'GET',
    request: {
      product_group_id3,
      with_ec: true,
      q: '',
      page: 0,
    },
  })

  if (!result.isSuccess) {
    yield put(actions.setColorGroupProducts([], {}))
    return
  }

  let new_products = result.json.products.reduce((prev_products, product) => {
    // 各modelに所属する商品がmypageに表示する商品がない時そのモデルを取得結果から除く
    let models
    if (product.series !== null) {
      models = product.series.models.reduce((prev_models, model) => {
        if (model.model_products.length > 0) {
          prev_models.push(model)
        }
        return prev_models
      }, [])
      // 上記で作成された新しいmodelsに現状のmodelsを更新
      product.series.models = models
    }

    return [...prev_products, product]
  }, [])

  //詳細画面colの順番を sequence_num 昇順にする
  new_products.sort((a, b) => {
    const a1 = a.sequence_num
    const b1 = b.sequence_num
    if (a1 > b1) {
      return 1
    } else {
      return -1
    }
  })

  yield put(actions.setColorGroupProducts(new_products))

  if (new_products.length > 0) {
    let product_ids = []

    new_products.forEach((product) => {
      product_ids.push(product.id)
    })
    yield call(getEcStocks, product_ids)
  }
}

function* getEcStocks(product_ids) {
  const result = yield call(api, {
    url: EC_STOCKS,
    method: 'GET',
    request: {
      product_ids,
    },
  })

  if (!result.isSuccess) {
    yield put(actions.setEcStocks([], {}))
    return
  }

  yield put(actions.setEcStocks(result.json.ec_stocks))
}

function* getOtherTypesSizes(product_group_id3) {
  const result = yield call(api, {
    url: OTHER_TYPES_SIZES,
    method: 'GET',
    request: {
      product_group_id3,
    },
  })

  if (!result.isSuccess) {
    yield put(actions.setOtherTypesSizes([], {}))
    return
  }

  yield put(actions.setOtherTypesSizes(result.json.other_types_sizes))
}
