import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { actions as locationActions } from '../../modules/location'
import { actions as authActions } from '../../modules/auth'
import Modal from './Modal'
import TermsOfServiceModal from './TermsOfServiceModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import {
} from 'react-icons/fa'
import { Button } from 'reactstrap'
import { HP_URL } from '../../constants/api-config'

class WithdrawalModal extends Component {

  render() {
    const {
      termsOfServiceModal,
      privacyPolicyModal,
      active,
      accountDelete
    } = this.props

    // モーダルウィンドウ開閉
    const onClickModel = (modal) => {
      this.props.set(modal, !eval(modal))
    }

    // Checkボックスの判定
    const onClickBtn = () => {
      this.props.set('active', !active)
    }

    return (
      <Content className="member">
        <div className="title">退会手続き</div>
        <div className="text">
          <div className="message">Four Nines Membersのご利用ありがとうございます。</div>
          <div className="message">「退会」をしますと、「お気に入り」「お取り置き」「購入履歴」などが見れなくなります。また、下記のサービスとのつながりが解除されます。</div>
          <div>つながりが解除されるサービス</div>
          <div>・LINEミニアプリ</div>
          <div>・フォーナインズ公式LINEアカウント</div>
          <div className="message">・フォーナインズ公式オンラインストア</div>
          <Field
            className="employed"
            name="employed"
            id="employed"
            component="input"
            type="checkbox"
            onClick={() => onClickBtn()}
          />
          <a className="withdrawalRules" target="_blank" rel="noopener" href={`${HP_URL}/user-policy/`}>利用規約</a>と
          <a className="withdrawalRules" target="_blank" rel="noopener" href={`${HP_URL}/privacy-policy/`}>プライバシーポリシー</a>に同意の上退会する
        </div>
        <Button className="withdrawal" onClick={e => accountDelete()} disabled={!active}>退会する</Button>
        <Modal
          className="width90"
          isOpen={termsOfServiceModal}
          onClose={() => onClickModel('termsOfServiceModal', false)}
        >
          <TermsOfServiceModal/>
        </Modal>
        <Modal
          className="width90"
          isOpen={privacyPolicyModal}
          onClose={() => onClickModel('privacyPolicyModal', false)}
        >
          <PrivacyPolicyModal/>
        </Modal>
      </Content>
    )
  }
}
const mapStateToProps = state => {
  const selector = formValueSelector('WithdrawalModal')
  return {
    termsOfServiceModal: selector(state, 'termsOfServiceModal'),
    privacyPolicyModal: selector(state, 'privacyPolicyModal'),
    active: selector(state, 'active'),
  }
}
const mapDispatchToProps = dispatch => {
  return {
    set: (key, value) => {
      dispatch(change('WithdrawalModal', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    accountDelete: () => {
      dispatch(authActions.deleteSso())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'WithdrawalModal',
    initialValues: {
      active: false,
      termsOfServiceModal: false,
      privacyPolicyModal: false,
    }
  })(
    withTranslation()(
      WithdrawalModal
    )
  )
)
const Content = styled.div`
  margin: 40px 20px;
  text-align: left;
  background-color: #F5F5F2;

    .title{
      font-size: 19px;
      font-family: ヒラギノ角ゴ Pro
      letter-spacing: 2.03px
      margin: 5px 0px 0px 0px;
      text-align: center;
    }

    .employed{
      cursor:pointer;
    }

    .text {
      font-size: 12px;
      font-family: ヒラギノ角ゴ Pro
      margin: 20px 0px 35px 0px;
      letter-spacing: 2.03px
    }

    .message{
      margin-bottom: 30px;
    }

    .withdrawal {
      border: 0;
      background-color: #A7A7A7;
      color: #FFFFFF;
      text-align: center;
      padding: 14px;
      width: 100%;
    }

    .withdrawalRules {
      text-decoration: underline;
      cursor: pointer;
    }
    .withdrawalRules:first-of-type{
      margin-left: 5px;
    }
    .withdrawalRules:hover {
      color: unset;
    }
`
