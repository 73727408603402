import { createActions, handleActions } from 'redux-actions'
import { put, call, takeEvery } from 'redux-saga/effects'
import api from './api'
import { NOTIFICATION } from '../constants/api-url'
import moment from 'moment'
import { waitAuth } from './auth'
import { actions as locationActions } from './location'


/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    getInformationDetail: payload => payload,
    changeInformationDetail: payload => payload,
    setInformationDetail: payload => payload,
    informationUpdate : payload => payload
  },
  { prefix: '9999/informationDetail'},
)

/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  informationDetail: {},
}

const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.getInformationDetail]: (state, action) => ({
    ...state,
    informationDetail: {
      ...initialState.informationDetail
    }
  }),
  [actions.setInformationDetail]: (state, action) => ({
    ...state,
    informationDetail: action.payload,
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* informationDetailSaga() {
  yield takeEvery(actions.getInformationDetail, getInformationDetail)
  yield takeEvery(actions.informationUpdate, informationUpdate)
}

function* getInformationDetail(action) {
  if (!(yield call(waitAuth))) {
    return
  }

  const id = action.payload
  if (!id) {
    return
  }
  const result = yield call(api, {
    url: NOTIFICATION(id),
    method: 'GET',
    isDisplayError: false,
  })

  if (result.status == 404) {
    yield put(locationActions.link('/informations'))
  }
  if (!result.isSuccess) {
    return
  }
  yield put(actions.setInformationDetail(result.json.notification))
  
  if(result.json.notification.readed_at == null) {
    yield put(actions.informationUpdate(result.json.notification.id))
  }
  
}

function* informationUpdate(action) {
  const notification_id = action.payload
  let now = moment().format('YYYY-MM-DD')
  let request = {
    readed_at: now
  }
  const result = yield call(api, {
    url: NOTIFICATION(notification_id),
    method: 'PUT',
    request
  })
  if (!result.isSuccess) {
    yield put(actions.setNotifications([], {}))
    return
  }
}


