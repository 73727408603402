/***************************************
 *URL
 ***************************************/
/***疎通確認***/
export const PING = '/api/ping'
/***ログイン***/
export const AUTH_EXISTS = '/api/mp/auth/exists'
export const AUTH_PASSWORD = '/api/mp/auth/password'
export const AUTH_LOGIN = '/api/mp/auth/login'
export const AUTH_ME = '/api/mp/auth/me'

export const AUTH_LINE = '/api/sso/token/line'

/** メニュー*/
export const MENU_MAIN = 'https://www.fournines.co.jp/wp-json/fournines_api/menu_main'
export const MENU_MAIN_EN = 'https://www.fournines.co.jp/en/wp-json/fournines_api/menu_main'
export const MENU_SNS = 'https://www.fournines.co.jp/wp-json/fournines_api/sns'
export const MENU_SNS_EN = 'https://www.fournines.co.jp/en/wp-json/fournines_api/sns'
export const MENU_FOOTER_TOP = 'https://www.fournines.co.jp/wp-json/fournines_api/menu?getmenu=footer-menu-top'
export const MENU_FOOTER_TOP_EN = 'https://www.fournines.co.jp/en/wp-json/fournines_api/menu?getmenu=footer-menu-top'
export const MENU_FOOTER_1 = 'https://www.fournines.co.jp/wp-json/fournines_api/menu?getmenu=footer-menu-1'
export const MENU_FOOTER_EN_1 = 'https://www.fournines.co.jp/en/wp-json/fournines_api/menu?getmenu=footer-menu-1'
export const MENU_FOOTER_2 = 'https://www.fournines.co.jp/wp-json/fournines_api/menu?getmenu=footer-menu-2'
export const MENU_FOOTER_EN_2 = 'https://www.fournines.co.jp/en/wp-json/fournines_api/menu?getmenu=footer-menu-2'
export const MENU_FOOTER_BOTTOM = 'https://www.fournines.co.jp/wp-json/fournines_api/menu?getmenu=footer-bottom'
export const MENU_FOOTER_BOTTOM_EN = 'https://www.fournines.co.jp/en/wp-json/fournines_api/menu?getmenu=footer-bottom'

/** 商品検索 */
export const PRODUCTS = '/api/mp/products'
export const PRODUCTS_SHOW = (id) => `/api/mp/products/${id}`
export const PRODUCTS_SHOW_BY_CODE = (code) => `/api/mp/products/code/${code}`
export const SERIES = '/api/mp/series'
export const MODELS = '/api/mp/models'

/** EC在庫確認 */
export const EC_STOCKS = '/api/mp/products/ec_stocks'

/** 他タイプサイズ確認 */
export const OTHER_TYPES_SIZES = '/api/mp/products/other_types_sizes'

/** 在庫保有店舗取得 */
export const DEPARTMENTS = '/api/mp/departments'
export const STOCKED_DEPARTMENTS = (id) => `/api/mp/products/${id}/stocked_departments`

/** おすすめ商品取得 */
export const RECOMMEND = (id) => `/api/mp/products/${id}/recommend`

/** お取り置き検索 */
export const SEARCH_RESERVATIONS = '/api/mp/reservations'

/** お取り置き商品登録 */
export const POST_RESERVATIONS = '/api/mp/reservations'

/** 購入履歴 */
export const SELLS = '/api/mp/sells'

/** お知らせ */
export const NOTIFICATIONS = '/api/mp/notifications'
export const NOTIFICATION = (id) => `/api/mp/notifications/${id}`

/**マスタ**/
export const CODE_CLASS_PRODUCT_SEARCH_ENTRY = (type) => `/api/mp/code/class/mypage_product_search_entry${type}`
// export const STORES = '/api/stores'
// export const ADJUSTMENT = pos_management_id => `/api/pos_managements/${pos_management_id}/adjustment`
export const PRODUCT_CLASSES = '/api/mp/product_classes'

/** ログインユーザ情報更新 **/
export const PUT_EDIT = '/api/mp/auth/me'

/** パスワード変更 **/
export const CHANGE_PASSWORD = '/api/sso/me/password'

/** 性別取得 **/
export const LOAD_GENDER = '/api/mp/code/class/sex'

/** トップページ情報 **/
export const LOAD_MYPAGE_TOP = '/api/mp/code/class/mypage_top'

/** システム値一覧取得 **/
export const LOAD_SYSTEM_VALUES = '/api/mp/system_values/mypage'

/** SSO トークン取得 **/
export const SSO_TOKEN = '/api/sso/token'
export const SSO_ME = '/api/sso/me'
export const SSO_AUTH_ME = '/api/sso/auth/me'
/** SSO ストレージ */
export const SSO_STORAGE = '/api/sso/storages'


/** WP記事 */
export const WP_TOP = '/wp-json/wp/v2/mypage?_embed&orderby=date&order=desc'
export const WP_MODEL_CATALOG = '/wp-json/wp/v2/fnm_online_store?_embed&orderby=date&order=desc'