import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import nl2br from 'react-nl2br'
import { actions as locationActions } from '../../modules/location'
import QrCode from 'qrcode.react'
import * as Common from '../../constants/common'
import { isEmpty, isEmptyObject } from '../../util/common'
import { actions as authActions } from '../../modules/auth'

class Card extends Component {
  componentDidMount() {
    this.props.start()
  }

  componentWillMount() {
    if(this.props.authGlaviss.token == null){
      var url = this.props.history.location.pathname
      this.props.setLocation(url)
      this.props.link('/login')
    }
  }

  render() {
    const {
      member,
      link,
      authGlaviss,
    } = this.props

    if (isEmptyObject(member)) {
      return ''
    }

    return (
      <Content className="member-card">
        <div className="title">Four Nines Members</div>
        <div className="name">
          {member.customer_family_name} {member.customer_first_name} さん
        </div>
        <div className="no">
          No.{member.customer_num}
        </div>
        <div className="qr">
          <QrCode value={`9999members.customer_num.${member.customer_num}`}/>
        </div>
        <div>
          ショップスタッフにご提示ください
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    member: state.member.member,
    authGlaviss: state.auth.glaViss,
    location: state.auth.location
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    setLocation: (url) => {
      dispatch(authActions.setLocation(url))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    Card
  )
)

const Content = styled.div`
  text-align: center;

  div {
    margin: 2rem 0;
  }
  .title {
    font-size: 1.2rem;
  }
  .name {
    font-size: 1.3rem;
  }
  .no {
    font-size: 1.3rem;
  }

  .qr {

  }
`
