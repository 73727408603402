import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import {
  IoMdClose
} from 'react-icons/io'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { actions as locationActions } from '../../modules/location'
import moment from 'moment'
import { actions as notificationsAction } from '../../modules/informations'
import { actions as authActions } from '../../modules/auth'
import { media } from '../../components/atomics/Media'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'

class Informations extends Component {
  componentDidMount() {
    this.props.start()
  }

  onClickNotification = (targetNotification) => {
    if (targetNotification.readed_at === null) {
      this.props.notificationUpdate(targetNotification.id) // ToDo:LINEメッセージから既読するために通知詳細画面を開いた時にも同じ処理を行なっています。あとでこの処理を削除する予定です。
    }
    this.props.goNotification(targetNotification)
  }

  onClickNotificationDelete = notification => e => {
    this.props.notificationDelete(notification.id)
    e.stopPropagation()
  }

  render() {
    const {
      notifications,
      onClickMore,
      hasMore,
      link,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickInformation = () => {
      link('/informations')
    }

    return (
      <Content className="informations">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem active>メッセージ</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">MESSAGE</div>
        <div className="contents-subtitle">メッセージ</div>
        {notifications.length === 0 && hasMore === false && <div className="not-found">メッセージはありません</div>}
        {notifications.map(notification => (
          <Card key={notification.id} className="notifications">
            <div className='notification' onClick={e => this.onClickNotification(notification)}>
              <Row className='send-date'>
                <Col className={`title ${notification.readed_at === null && 'unread_badge'}`}>
                  {moment(notification.sended_at).format('YYYY/MM/DD')}
                </Col>
              </Row>
              <Row>
                <Col>{notification.title}</Col>
                <Col xs={1} className="close" onClick={this.onClickNotificationDelete(notification)}>
                  <IoMdClose size="1.3rem"/>
                </Col>
              </Row>
              <Row className='body'>
                {/* TODO: 通知本番はHTMLになる予定のため表示の仕方を変更する */}
                <Col>
                  <div id={`information-body-${notification.id}`} ref={ref => this.informationBody = ({...this.informationBody, [notification.id]: ref})} />
                  <div style={{display: 'none'}}>
                    <iframe srcDoc={notification.body} onLoad={e => this.informationBody[notification.id].innerText = e.target.contentWindow.document.body.innerText}/>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        ))}
        <div className="container">
          {hasMore && <div block className="more" onClick={e => {
            e.target.blur()
            onClickMore()
          }}>VIEW MORE</div>}
        </div>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem active>メッセージ</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.informations.notifications,
    hasMore: state.informations.searchedMeta.current_page !== 0 && state.informations.searchedMeta.current_page < state.informations.searchedMeta.last_page,
    isWeb: state.location.isWeb,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
      dispatch(authActions.shouldAuth(() => {
        dispatch(notificationsAction.getNotifications())
      }))
    },
    notificationNext: () => {
      dispatch(notificationsAction.notificationNext())
    },
    notificationUpdate: (id) => {
      dispatch(notificationsAction.notificationUpdate(id))
    },
    notificationDelete: (id) => {
      dispatch(notificationsAction.notificationDelete(id))
    },
    goNotification: (notification) => {
      dispatch(locationActions.link(`/informations/${notification.id}`))
    },
    onClickMore: () => {
      dispatch(notificationsAction.clickNotification())
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    Informations
  )
)

const Content = styled.div`

.subtitle{
  text-align: center;
}

.notifications {
  border: 0;
  border-bottom: 1px solid #DDDDDB;
  border-spacing: 10px 100px;
}

.notification{
  margin: 15px 54px;
  cursor:pointer;

  .close {
    margin-right: -30px;
    cursor:pointer;
  }

  .title {
    font-size: 14px;
    letter-spacing: 0.7px;
    margin-bottom: 12px;
  }
  .body {
    color: #8D8D8D;
    font-size: 12px;
    height: 1rem;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .send-date {
    font-size: 13px;
  }
  
}

.sended{
  border-radius: 10px;
  text-decoration: none;
  background: #e64545;
  color: #FFF;
  width: 55px;
  height: 25px;
  border: none;
  text-align: center;
  font-size: 15px;
}

.readed{
  border-radius: 10px;
  text-decoration: none;
  background: #7acc9e;
  color: #FFF;
  width: 55px;
  height: 25px;
  border: none;
  text-align: center;
  font-size: 15px;
}

.container{
  grid-template-rows: 20px 30px;
  grid-template-columns: 250px 100px 100px;
  margin-bottom: 160px;
}

.unread_badge:before {
  content: '';
  font-size: 14px;
  background: #FF5D5D;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -12px;
  top: 0.5rem;
}

.not-found {
  text-align: center;
}
`
