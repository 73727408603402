import React, { Component } from 'react'
import {
  CardImg,
} from 'reactstrap'
import styled from 'styled-components'
import { isEmptyObject } from '../../util/common'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'

class ProductImage extends Component {
  render() {
    const {
      product = null,
      model = null,
      imageNo = 1,
      className = '',
      ...props
    } = this.props

    const altName = () => {
      if (!isEmptyObject(product)) {
        return product.product_name1
      }
      if (!isEmptyObject(model)) {
        return 'Model ' + model.display_name
      }
    }

    const srcUrl = (no=1) => {
      if (!isEmptyObject(product)) {
        return `${IMG_URL}/img/Product/${product.product_cd}_${no}.jpg`
      }
      if (!isEmptyObject(model)) {
        return `${IMG_URL}/img/Model/${model.group_cd}_${no}.jpg`
      }
    }

    return (
      <Content 
        top
        width="100%" 
        src={srcUrl(imageNo)} 
        alt={altName()} 
        onError={e => {
          // TODO: NoImage仮置
          e.target.src = `${IMG_URL}/mypage_top/noimage.svg`
        }}
        className={`product-image ${className}`}
        {...props}
      />
    )
  }
}

export default ProductImage

const Content = styled(CardImg)`
width: 80vw;
height: 60vw;
margin: auto;
padding-top: 20px;
margin-top: 20px;
object-fit: contain;
display: block;

${media.sm`
  width: 448px;
  height: 345px;
`}
${media.lg`
  margin-top: 58px;
  height: 167px;
  object-fit: contain;
`}
`
