import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Container, Row, Col, CardBody } from 'reactstrap'
import styled from 'styled-components'
import nl2br from 'react-nl2br'
import { withTranslation } from 'react-i18next'
import ToggleTab from './ToggleTab'
import { isEmpty } from '../../util/common'
import { media } from '../../components/atomics/Media'
import { isEmptyObject } from '../../util/common'
import { IMG_URL } from '../../constants/api-config'

class ProductDetail extends Component {
  render() {
    const { product, otherTypesSizes, className = '', isEc, t, i18n } = this.props

    let materialKeys = []
    for (let i = 4; i < 28; i += 2) {
      materialKeys.push(i)
    }

    let discription_flag = false
    if (i18n.language !== 'en') {
      if (product.series && product.series.text2) discription_flag = true
    } else {
      if (product.series && product.series.eng && product.series.eng.eng_text2) discription_flag = true
    }

    let ec_discription_flag = false
    if (isEc && Boolean(Number(product.ec_display_flag))) {
      if (i18n.language !== 'en') {
        if (product.model.ec_discription_title) ec_discription_flag = true
      } else {
        if (product.model.eng && product.model.eng.ec_discription_title) ec_discription_flag = true
      }
    }

    return (
      <Content className={`product-detail ${className}`}>
        {discription_flag === true && (
          <ToggleTab title={t('商品説明')} className="discription">
            {i18n.language !== 'en' ? nl2br(product.series.text2) : nl2br(product.series.eng.eng_text2)}
            {product.model && product.model.movie1 && (
              <div className="movie">
                <a href={product.model.movie1} target="_blank">
                  PRODUCT MOVIE
                  <img src="/images/icon-external.svg" />
                  <br />
                </a>
              </div>
            )}
          </ToggleTab>
        )}
        <ToggleTab title={t('仕様')} className="discription">
          <div className="size">
            <div className="size-label">{t('サイズ')}</div>
            <Container fluid={true}>
              {isEmptyObject(otherTypesSizes) && (
                <Row>
                  <Col xs={5}>{t('ボックスサイズ')}</Col>
                  <Col xs={7}>
                    {i18n.language === 'en'
                      ? product.size && product.size.eng_name
                      : product.size && product.size.display_name}
                  </Col>
                  <Col xs={5}>{t('レンズ天地幅')}</Col>
                  <Col xs={7}>
                    {i18n.language === 'en'
                      ? product.lens_vertical && product.lens_vertical.eng_name
                      : product.lens_vertical && product.lens_vertical.display_name}
                    mm
                  </Col>
                  <Col xs={5}>{t('枠幅')}</Col>
                  <Col xs={7}>
                    {i18n.language === 'en'
                      ? product.frame_horizontal && product.frame_horizontal.eng_name
                      : product.frame_horizontal && product.frame_horizontal.display_name}
                    mm
                  </Col>
                </Row>
              )}
              {!isEmptyObject(otherTypesSizes) && (
                <Row>
                  <Col xs={5}>{t('ボックスサイズ')}</Col>
                  <Col xs={7}>
                    {Object.keys(otherTypesSizes).map((key) => {
                      const type = 'Type' + otherTypesSizes[key].type_cd + ': '
                      return (
                        <p key={key}>
                          {type}
                          {i18n.language === 'en'
                            ? otherTypesSizes[key].size.display_name
                            : otherTypesSizes[key].size.eng_name}
                        </p>
                      )
                    })}
                  </Col>
                  <Col xs={5}>{t('レンズ天地幅')}</Col>
                  <Col xs={7}>
                    {Object.keys(otherTypesSizes).map((key) => {
                      const type = 'Type' + otherTypesSizes[key].type_cd + ': '
                      return (
                        <p key={key}>
                          {type}
                          {i18n.language === 'en'
                            ? otherTypesSizes[key].lens_vertical.eng_name
                            : otherTypesSizes[key].lens_vertical.display_name}
                          mm
                        </p>
                      )
                    })}
                  </Col>
                  <Col xs={5}>{t('枠幅')}</Col>
                  <Col xs={7}>
                    {Object.keys(otherTypesSizes).map((key) => {
                      const type = 'Type' + otherTypesSizes[key].type_cd + ': '
                      return (
                        <p key={key}>
                          {type}
                          {i18n.language === 'en'
                            ? otherTypesSizes[key].frame_horizontal.eng_name
                            : otherTypesSizes[key].frame_horizontal.display_name}
                          mm
                        </p>
                      )
                    })}
                  </Col>
                </Row>
              )}
            </Container>
          </div>
          <div className="material">
            <div className="material-label">{t('素材')}</div>
            <Container fluid={true}>
              <Row>
                {!isEmpty(product.material_string1) && (
                  <>
                    <Col xs={4}>{t('フロント')}</Col>
                    <Col xs={8}>{i18n.language === 'en' ? product.material_string1_eng : product.material_string1}</Col>
                  </>
                )}
                {!isEmpty(product.material_string2) && (
                  <>
                    <Col xs={4}>{t('テンプル')}</Col>
                    <Col xs={8}>{i18n.language === 'en' ? product.material_string2_eng : product.material_string2}</Col>
                  </>
                )}
                {!isEmpty(product.material_string3) && (
                  <>
                    <Col xs={4}>{t('モダン')}</Col>
                    <Col xs={8}>{i18n.language === 'en' ? product.material_string3_eng : product.material_string3}</Col>
                  </>
                )}
                {!isEmpty(product.material_string4) && (
                  <>
                    <Col xs={4}>{t('レンズ')}</Col>
                    <Col xs={8}>{i18n.language === 'en' ? product.material_string4_eng : product.material_string4}</Col>
                  </>
                )}
              </Row>
            </Container>
          </div>
          <div className="attach">
            <div className="attach-label">{t('付属品')}</div>
            {i18n.language === 'en' ? nl2br(product.accessory_string_eng) : nl2br(product.accessory_string)}
          </div>
        </ToggleTab>
        {ec_discription_flag === true && (
          <ToggleTab
            title={i18n.language !== 'en' ? product.model.ec_discription_title : product.model.eng.ec_discription_title}
            className="discription"
            isFirstOpen={true}
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language !== 'en' ? product.model.ec_discription_html : product.model.eng.ec_discription_html,
              }}
            />
          </ToggleTab>
        )}
      </Content>
    )
  }
}

export default withTranslation()(withRouter(ProductDetail))

const Content = styled(CardBody)`
  padding-top: 0;
  padding-bottom: 0;

  .movie {
    margin-top: 20px;
    a {
      text-decoration: underline;
    }
    img {
      margin-left: 5px;
    }
  }

  .toggle-tab {
    &:not(:last-child) {
      border-bottom: 0;
    }

    .toggle-tab-body {
      padding: 0 10px;
    }
  }

  .material-label {
    font-size: 13px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .material {
    border-bottom: 1px solid #dadada;

    .col-4 {
      border-top: 1px solid #dadada;
      border-width: 100%;
      padding: 20px 0 20px 8px;
      font-size: 15px;
    }
    .col-8 {
      border-top: 1px solid #dadada;
      padding: 23px 0;
      font-size: 13px;
    }
  }

  .size-label {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .size {
    border-bottom: 1px solid #dadada;

    .col-5 {
      border-top: 1px solid #dadada;
      padding: 20px 0 20px 8px;
      font-size: 15px;
    }
    .col-7 {
      border-top: 1px solid #dadada;
      padding: 23px 0;
      font-size: 13px;
    }
  }

  .attach-label {
    font-size: 13px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .attach {
    font-size: 14px;
    line-height: 25px;
    margin: 0 0 15px;
  }

  .discription .toggle-tab-body {
    font-size: 14px;
    line-height: 25px;
    margin: 15px 0;
  }

  ${media.lg`
  .discription .toggle-tab-body {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 40px;
    border-color: #DDDDDB;
  }

  .toggle-tab {  
    .toggle-tab-body {
      padding: 0 20px;
      font-size: 13px;
    }
  }

  .size-label {
    .col-5 {
      font-size: 13px;
    }
    .col-7 {
      font-size: 13px;
    }
  }
`}
`
