import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import { actions as authAction } from '../../modules/auth'

class Callback extends Component {
  componentDidMount() {
    this.props.auth(queryString.parse(this.props.location.search))
  }

  render() {
    return (
      <div>
        ログイン検証
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    auth: (qs) => {
      dispatch(authAction.makeShopAuth(qs))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback)
