import React, { Component, useCallback } from 'react'
import { store } from './index'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as Common from './constants/common'
import Loading from './components/molecules/Loading'
import Error from './components/molecules/Error'
import Notice from './components/molecules/Notice'
import NotFound from './views/notFound/NotFound'
import Header from './views/Layout/Header'
import Footer from './views/Layout/Footer'
import Home from './views/Home/Home'
import Items from './views/Items/Items'
import ItemDeatil from './views/Items/Deatil'
import Size from './views/Items/Size'
import SearchProducts from './views/Items/Search'
import Favorites from './views/Items/Favorites'
import Reservation from './views/Items/Reservation/Reservation'
import ReservationNotes from './views/Items/Reservation/Notes'
import ReservationConfirmation from './views/Items/Reservation/Confirmation'
import ReservationNotlogged from './views/Items/Reservation/NotLogged'
import OutofStock from './views/Items/Reservation/OutofStock'
import Reservations from './views/Items/Reservation/Reservations'
import Informations from './views/Informations/Informations'
import Member from './views/Member/Member'
import MemberCard from './views/Member/Card'
import Info from './views/Member/Info/Info'
import MemberEdit from './views/Member/Info/Edit'
import Login from './views/Member/Login'
import PurchaseHistory from './views/PurchaseHistory/PurchaseHistory'
import MakeShopLogin from './views/MakeShop/Login'
import MakeShopCallback from './views/MakeShop/Callback'
import ChangePassword from './views/Member/ChangePassword'
import InfomationDetail from './views/Informations/Detail'
import ProductSize from './components/molecules/ProductSize'
import SeriesCatalog from './views/Items/SeriesCatalog'
import ModelCatalog from './views/Items/ModelCatalog'
import WebHeader from './views/Layout/WebHeader'
import WebFooter from './views/Layout/WebFooter'
import WebEnFooter from './views/Layout/WebEnFooter'
import WebMenu from './views/Layout/WebMenu'
import LoginCheck from './views/Layout/LoginCheck'

import 'sanitize.css'
import { actions as authActions } from './modules/auth'
import { actions as masterActions } from './modules/master'
import { actions as productsActions } from './modules/products'
import { actions as favoritesActions } from './modules/favorites'
import { actions as notificationsAction } from './modules/informations'
import { actions as locationAction } from './modules/location'
import TopScroll from './components/molecules/TopScroll'
import ScrollToTop from './components/molecules/ScrollToTop'
import { media } from './components/atomics/Media'
import { HP_URL } from './constants/api-config'
import { Helmet } from 'react-helmet'

class App extends Component {
  componentDidMount() {
    this.props.start()

    const { pathname } = this.props.location
    window.gtag('config', Common.GA, {
      page_path: pathname,
    })

    // FNM単独起動時はパンくず非表示
    if (pathname.indexOf('/web/') < 0) {
      const breadcrumbs = document.getElementsByClassName('breadcrumb')
      for (let i = 0; i < breadcrumbs.length; i++) {
        breadcrumbs[i].style.display = 'none'
      }
    }
  }
  componentWillUnmount() {}

  render() {
    return (
      <Container id="container">
        <Switch>
          <Route
            path="/web/ja/models/ec/"
            component={() => (
              <Helmet>
                <title>999.9 ONLINE STORE</title>
                <meta
                  name="description"
                  content="ブルーライトカット | ゲーム ・PC・スマホ用メガネ | PLAIDe(プレイド) デジタルライフの「見る」を快適に ⽬を酷使する過酷な環境で⻑時間モニターに向き合うeスポーツ。そのeスポーツ向けにつくられたゲーミンググラス「PLAIDe」がゲームの枠を⾶び越え、デジタル新時代のあらゆるシーンへ。モニターからの強い光や、ヘッドセットとアイウェアの相性など、ゲームはもちろんデジタルライフ特有のストレスを軽減する機能性と毎⽇快適に使えるスタイルが融合し、アイウェアの新たな価値を創造します。リモートワークの広がりや動画コンテンツの多様化など、⼈とモニターとの関係がより⾝近になった今、PLAIDeは、パソコンやスマートフォンのストレスから⽬を守り、デジタルライフの「⾒る」を快適に変えていきます。デジタル新時代のスタンダード、PLAIDe。"
                ></meta>
              </Helmet>
            )}
          />
          <Route
            component={() => (
              <Helmet>
                <title>フォーナインズ</title>
              </Helmet>
            )}
          />
        </Switch>
        <Switch>
          <Route path="/web/" component={WebHeader} />
          <Route path="/" component={Header} />
        </Switch>
        <Contents className="wrapper">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/items" component={SearchProducts} />
            <Route exact path="/items/ec/:item_code" component={ItemDeatil} />
            <Route exact path="/items/ec/:item_code/:color_item_code" component={ItemDeatil} />
            <Route exact path="/items/:item_code" component={ItemDeatil} />
            <Route exact path="/items/:item_code/:color_item_code" component={ItemDeatil} />
            <Route exact path="/favorites" component={Favorites} />
            <Route exact path="/size" component={Size} />
            <Route exact path="/favorites/:favorite_id/reservation" component={Reservation} />
            <Route exact path="/favorites/:favorite_id/reservation/notes" component={ReservationNotes} />
            <Route exact path="/favorites/:favorite_id/reservation/confirm" component={ReservationConfirmation} />
            <Route exact path="/favorites/:favorite_id/reservation/notlogged" component={ReservationNotlogged} />
            <Route exact path="/favorites/:favorite_id/reservation/outofstock" component={OutofStock} />
            <Route exact path="/reservations" component={Reservations} />
            <Route exact path="/informations" component={Informations} />
            <Route exact path="/member" component={Member} />
            <Route exact path="/informations/:information_id" component={InfomationDetail} />
            <Route exact path="/member/card" component={MemberCard} />
            <Route exact path="/member/info" component={Info} />
            <Route exact path="/member/info/edit" component={MemberEdit} />
            <Route exact path="/histories" component={PurchaseHistory} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/member/change_password" component={ChangePassword} />
            <Route exact path="/product_size" component={ProductSize} />

            <Route exact path="/web/:lang/" component={SearchProducts} />
            <Route exact path="/web/:lang/items" component={SearchProducts} />
            <Route exact path="/web/:lang/items/ec/:item_code" component={ItemDeatil} />
            <Route exact path="/web/:lang/items/ec/:item_code/:color_item_code" component={ItemDeatil} />
            <Route exact path="/web/:lang/items/:item_code" component={ItemDeatil} />
            <Route exact path="/web/:lang/items/:item_code/:color_item_code" component={ItemDeatil} />
            <Route exact path="/web/:lang/favorites" component={Favorites} />
            <Route exact path="/web/:lang/size" component={Size} />
            <Route exact path="/web/:lang/favorites/:favorite_id/reservation" component={Reservation} />
            <Route exact path="/web/:lang/favorites/:favorite_id/reservation/notes" component={ReservationNotes} />
            <Route
              exact
              path="/web/:lang/favorites/:favorite_id/reservation/confirm"
              component={ReservationConfirmation}
            />
            <Route
              exact
              path="/web/:lang/favorites/:favorite_id/reservation/notlogged"
              component={ReservationNotlogged}
            />
            <Route exact path="/web/:lang/favorites/:favorite_id/reservation/outofstock" component={OutofStock} />
            <Route exact path="/web/:lang/reservations" component={Reservations} />
            <Route exact path="/web/:lang/informations" component={Informations} />
            <Route exact path="/web/:lang/member" component={Member} />
            <Route exact path="/web/:lang/informations/:information_id" component={InfomationDetail} />
            <Route exact path="/web/:lang/member/card" component={MemberCard} />
            <Route exact path="/web/:lang/member/info" component={Info} />
            <Route exact path="/web/:lang/member/info/edit" component={MemberEdit} />
            <Route exact path="/web/:lang/histories" component={PurchaseHistory} />
            <Route exact path="/web/:lang/login" component={Login} />
            <Route exact path="/web/:lang/member/change_password" component={ChangePassword} />
            <Route exact path="/web/:lang/product_size" component={ProductSize} />
            <Route exact path="/web/:lang/series/:class1_cd" component={SeriesCatalog} />
            <Route exact path="/web/:lang/series/:class1_cd/:class2_cd" component={SeriesCatalog} />
            <Route exact path="/web/:lang/models/:class2_ids" component={ModelCatalog} />
            <Route exact path="/web/:lang/models/ec/:class2_ids" component={ModelCatalog} />

            <Route exact component={NotFound} />
          </Switch>
          <Loading />
          <Error />
          <Notice />
        </Contents>
        <Switch>
          <Route path="/web/en/" component={WebEnFooter} />
          <Route path="/web/" component={WebFooter} />
          <Route path="/" component={Footer} />
        </Switch>
        <Route path="/web/" component={WebMenu} />
        <LoginCheck />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    start: () => {
      dispatch(authActions.init())
      dispatch(favoritesActions.init())
      dispatch(masterActions.init())
      dispatch(productsActions.init())

      // Webから表示されたときは「商品詳細の遷移元」をクリアする
      if (document.referrer.indexOf(HP_URL) === 0) {
        dispatch(locationAction.set('referencePath', ''))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

const Container = styled.div`
  background-color: #f5f5f2;
  color: ${Common.FONT_COLOR};

  .top-scroll {
    bottom: 79px;
    display: block;
  }
  .list-pc-line {
    display: none;
  }

  .list-pc {
    display: none;
  }
  .list-sp {
    display: block;
  }

  ${media.sm`
    .wrapper {
      width: 576px;
      margin: 0 auto;
    }
  `}
  ${media.md`
    .wrapper {
      width: 768px;
    }
  `}
  ${media.lg`
    .list-pc-line {
      display: block;
      position: absolute;
      width: 100vw;
      left: 0;
      margin: 0;
    }

    .list-pc {
      display: block;
    }
    .list-sp {
      display: none;
    }
    padding-bottom: 0;
    .wrapper {
      width: 992px;
    }
  `}
  ${media.xl`
    .wrapper {
      width: 1280px;
    }
  `}
`

const Contents = styled.div``
