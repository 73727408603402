import { createActions, handleActions } from 'redux-actions'
import { select, put, takeEvery, call, takeLatest, delay, fork, join } from 'redux-saga/effects'
import {isEmpty} from '../util/common'
import api from './api'
import {
  PRODUCTS,
  STOCKED_DEPARTMENTS,
  RECOMMEND
} from '../constants/api-url'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    searchProduct: payload => payload,
    searchProductNext: payload => payload,
    addSearchProducts: (products, meta) => ({products, meta}),
    detailedSearch: payload => payload,
  },
  { prefix: '9999/products' },
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  searchedProducts: [], // 検索した商品
  searchedMeta: {
    current_page: 0,
    last_page: 1
  },
  isSearching: true,
}
const reducer = handleActions({
  [actions.init]: () => initialState,
  [actions.searchProduct]: (state, action) => ({
    ...state,
    searchedProducts: initialState.searchedProducts,
    searchedMeta: initialState.searchedMeta,
    isSearching: true,
  }),
  [actions.searchProductNext]: (state, action) => ({
    ...state,
    isSearching: true,
  }),
  [actions.addSearchProducts]: (state, action) => ({
    ...state,
    searchedProducts: [
      ...state.searchedProducts,
      ...action.payload.products,
    ],
    searchedMeta: {
      ...state.searchedMeta,
      ...action.payload.meta,
    },
    isSearching: false,
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* productsSaga() {
//  yield takeEvery(actions.loadStoreMaster, loadStoreMaster)
  yield takeLatest(actions.searchProduct, searchProduct)
  yield takeLatest(actions.searchProductNext, searchProduct)
}

// 検索フォームが入力された行われる処理
function* searchProduct(action) {
  if (!action.payload) {
    yield put(actions.addSearchProducts([], {}))
    return
  }

  const {
    words = '',
    mypage_product_search_entry1 = [],
    mypage_product_search_entry2 = [],
    mypage_product_search_entry3 = [],
    mypage_product_search_entry4 = [],
  } = action.payload

  const {
    products: {
      searchedMeta,
    },
    master: {
      productSearchEntry1,
      productSearchEntry2,
      productSearchEntry3,
      productSearchEntry4,
    },
  } = yield select()

  let page = searchedMeta.current_page + 1;
  if (page > searchedMeta.last_page) {
    yield put(actions.addSearchProducts([], {}))
    return;
  }

  let request = {
    page,
    q: words,
    search_entry1: productSearchEntry1.codevalues && productSearchEntry1.codevalues.filter(codevalue => mypage_product_search_entry1[codevalue.cd] === true).map(codevalue => codevalue.cd_name),
    search_entry2: productSearchEntry2.codevalues && productSearchEntry2.codevalues.filter(codevalue => mypage_product_search_entry2[codevalue.cd] === true).map(codevalue => codevalue.cd_name),
    search_entry3: productSearchEntry3.codevalues && productSearchEntry3.codevalues.filter(codevalue => mypage_product_search_entry3[codevalue.cd] === true).map(codevalue => codevalue.cd_name),
    search_entry4: productSearchEntry4.codevalues && productSearchEntry4.codevalues.filter(codevalue => mypage_product_search_entry4[codevalue.cd] === true).map(codevalue => codevalue.cd_name),
  }

  yield delay(600);

  const result = yield call(api, {
    url: PRODUCTS,
    method: 'GET',
    request,
    isLoading: false,
  })
  if (!result.isSuccess) {
    yield put(actions.addSearchProducts([], {}))
    return
  }

  yield put(actions.addSearchProducts(result.json.products, result.json.meta))
}

// 商品を絞りこむ
function* filterProduct() {
  const { products } = yield select()
  // TODO: とりあえず品番で検索　APIになる想定
  let result1
  if(products.detailSearchWordIds.lenght > 0){
    // 詳細検索はカテゴリだけ実装
    let result1 = products.products.filter(product => {
      return products.detailSearchWordIds.indexOf(product.class1_name) != -1
    })
  } else{
    result1 = products.products
  }
  let result2 = result1.filter(product => product.product_cd.indexOf(products.searchWord) != -1)

  yield put(actions.setSearchProducts(result2))
}


