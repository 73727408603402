import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import styled from 'styled-components'
import { actions as locationActions } from '../../modules/location'
import { HP_URL, HP_EN_URL, OPEN_ENV, API_URL } from '../../constants/api-config'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { isEmptyObject, randomString } from '../../util/common'
import { actions as authActions, redirectSso } from '../../modules/auth'
import { reset } from 'redux-form'
import { actions as productsActions } from '../../modules/products'
import { actions as menusActions } from '../../modules/menus'
import classNames from 'classnames'
import LoginOutConfirmModal from '../../components/molecules/LoginOutConfirmModal'
import Modal from '../../components/molecules/Modal'

class WebMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginOutConfirmModal: false,
    }
  }

  componentDidMount() {
    this.props.getMenuMain()
    this.props.getMenuMainEn()
    this.props.getMenuSns()
    this.props.getMenuSnsEn()
  }

  render() {
    const { link, i18n, resetForm, menuMain, menuMainEn, menuSns, menuSnsEn, name } = this.props

    const menu = i18n.language === 'en' ? menuMainEn : menuMain
    const sns = i18n.language === 'en' ? menuSnsEn : menuSns
    const hp_url = i18n.language === 'en' ? HP_EN_URL : HP_URL

    //検索条件初期化
    const onClickItem = () => {
      link('/items')
      resetForm('search_products')
      this.props.searchProduct()
    }

    return (
      <>
        <div className="l-menu">
          <div className="g-menu c-sans">
            <div className="g-menu__wrapper">
              <div className="g-menu__inner">
                <div className="g-menu__logo">
                  <a href={hp_url}>
                    <div className="c-logo">
                      <img src="/assets/ui/logo.svg" alt="999.9" />
                    </div>
                  </a>
                  <div className="g-menu__lng c-sans">
                    <a
                      className={classNames('g-menu__lng__a g-menu__lng__a--ja', {
                        'is-current': i18n.language !== 'en',
                      })}
                      href={HP_URL}
                    >
                      JP
                    </a>
                    <a
                      className={classNames('g-menu__lng__a g-menu__lng__a--en', {
                        'is-current': i18n.language === 'en',
                      })}
                      href={HP_EN_URL}
                    >
                      EN
                    </a>
                  </div>
                </div>
                <div className="g-menu__inner__t">
                  {i18n.language !== 'en' && (
                    <>
                      <ul className="c-ja">
                        {name === null && (
                          <>
                            <li>
                              <a className="g-menu__li" href="javascript:void(0)" onClick={(e) => redirectSso()}>
                                ログイン
                              </a>
                            </li>
                            <li>
                              <a
                                className="g-menu__li"
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  // 会員登録へ遷移できるURLが無いので暫定対応
                                  switch (OPEN_ENV) {
                                    case 'production':
                                      window.location.href = `${API_URL}/sso/4/signup`
                                    case 'staging':
                                      window.location.href = `${API_URL}/sso/3/signup`
                                    case 'development':
                                    default:
                                      window.location.href = `${API_URL}/sso/2/signup`
                                      break
                                  }
                                }}
                              >
                                新規会員登録
                              </a>
                            </li>
                          </>
                        )}
                        {name !== null && (
                          <li>
                            <div className="g-menu__li -logged-in">{name} 様</div>
                          </li>
                        )}
                        {name !== null && (
                          <li>
                            <div className="g-menu__li g-menu__li--expand">
                              マイページ<i></i>
                            </div>
                            <div className="g-menu__expand">
                              <ul className="g-menu__expand__inner">
                                <li>
                                  <a
                                    className="g-menu__li"
                                    href="javascript:void(0)"
                                    onClick={(e) => link('/favorites')}
                                  >
                                    お気に入り
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="g-menu__li"
                                    href="javascript:void(0)"
                                    onClick={(e) => link('/informations')}
                                  >
                                    メッセージ
                                  </a>
                                </li>
                                <li>
                                  <a className="g-menu__li" href="javascript:void(0)" onClick={(e) => link('/member')}>
                                    マイアカウント
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="g-menu__li"
                                    href="javascript:void(0)"
                                    onClick={(e) => this.setState({ loginOutConfirmModal: true })}
                                  >
                                    ログアウト
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        )}
                        <li>
                          <a className="g-menu__li" href={`${hp_url}/about-members`}>
                            Four Nines Membersについて
                          </a>
                        </li>
                      </ul>
                      <hr />
                    </>
                  )}
                  {menu.length !== 0 && (
                    <ul>
                      {menu.map((item, index) => {
                        if (item.child.length === 0) {
                          if (item.url.match(/^https?:\/\/\[shop_link\]/)) {
                            return (
                              <li key={index}>
                                <a
                                  className="g-menu__li"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    link(`${item.url.replace(/^https?:\/\/\[shop_link\]/, '')}`)
                                  }}
                                  target={item.target}
                                >
                                  {item.title}
                                </a>
                              </li>
                            )
                          } else {
                            return (
                              <li key={index}>
                                <a
                                  className="g-menu__li"
                                  href={item.url.replace(/^\//, HP_URL + '/')}
                                  target={item.target}
                                >
                                  {item.title}
                                </a>
                              </li>
                            )
                          }
                        } else {
                          return (
                            <li key={index}>
                              <div className="g-menu__li g-menu__li--expand">
                                {item.title}
                                <i></i>
                              </div>
                              <div className="g-menu__expand">
                                <ul className="g-menu__expand__inner">
                                  {item.child.map((item, index) => (
                                    <li key={index}>
                                      {item.url.match(/^https?:\/\/\[shop_link\]/) ? (
                                        <a
                                          className="g-menu__li"
                                          href="javascript:void(0)"
                                          onClick={(e) => {
                                            link(`${item.url.replace(/^https?:\/\/\[shop_link\]/, '')}`)
                                          }}
                                        >
                                          {item.title}
                                        </a>
                                      ) : (
                                        <a
                                          className="g-menu__li"
                                          href={item.url.replace(/^\//, HP_URL + '/')}
                                          target={item.target}
                                        >
                                          {item.title}
                                        </a>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </li>
                          )
                        }
                      })}
                    </ul>
                  )}
                  {sns.length !== 0 && (
                    <div className="g-menu__sns">
                      {sns.map((item, index) => (
                        <a key={index} target="_blank" rel="noopener noreferrer" href={`${item.link.url}`}>
                          <img src={item.image} alt={item.link.title} />
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="g-menu__expand--desktop">
                {name !== null && (
                  <div className="g-menu__expand--desktop__el" data-menu-category="マイページ">
                    <ul>
                      <li>
                        <a className="g-menu__li" href="javascript:void(0)" onClick={(e) => link('/favorites')}>
                          お気に入り
                        </a>
                      </li>
                      <li>
                        <a className="g-menu__li" href="javascript:void(0)" onClick={(e) => link('/informations')}>
                          メッセージ
                        </a>
                      </li>
                      <li>
                        <a className="g-menu__li" href="javascript:void(0)" onClick={(e) => link('/member')}>
                          マイアカウント
                        </a>
                      </li>
                      <li>
                        <a
                          className="g-menu__li"
                          href="javascript:void(0)"
                          onClick={(e) => this.setState({ loginOutConfirmModal: true })}
                        >
                          ログアウト
                        </a>
                      </li>
                    </ul>
                  </div>
                )}

                {menu.map((item, index) => (
                  <div key={index} className="g-menu__expand--desktop__el" data-menu-category={item.title}>
                    <ul>
                      {item.child.map((item, index) => (
                        <li key={index}>
                          {item.url.match(/^https?:\/\/\[shop_link\]/) ? (
                            <a
                              className="g-menu__li"
                              href="javascript:void(0)"
                              onClick={() => {
                                link(`${item.url.replace(/^https?:\/\/\[shop_link\]/, '')}`)
                              }}
                            >
                              {item.title}
                            </a>
                          ) : (
                            <a className="g-menu__li" href={item.url.replace(/^\//, HP_URL + '/')} target={item.target}>
                              {item.title}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="g-menu__close">
                <div className="g-menu__close__btn">
                  <i>
                    <img src="/assets/menu/close.svg" alt="" />
                  </i>
                  <p className="c-sans">CLOSE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="width90"
          isOpen={this.state.loginOutConfirmModal}
          onClose={() => this.setState({ loginOutConfirmModal: false })}
        >
          <LoginOutConfirmModal onCancel={() => this.setState({ loginOutConfirmModal: false })} />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    menuMain: state.menus.menuMain,
    menuMainEn: state.menus.menuMainEn,
    menuSns: state.menus.menuSns,
    menuSnsEn: state.menus.menuSnsEn,
    name: !isEmptyObject(state.member.member)
      ? `${state.member.member.customer_family_name} ${state.member.member.customer_first_name || ''}`
      : !isEmptyObject(state.member.ssoUser)
      ? state.member.ssoUser.name
      : null,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
    searchProduct: () => {
      dispatch(productsActions.searchProduct({}))
    },
    getMenuMain: () => {
      dispatch(menusActions.getMenuMain({}))
    },
    getMenuMainEn: () => {
      dispatch(menusActions.getMenuMainEn({}))
    },
    getMenuSns: () => {
      dispatch(menusActions.getMenuSns({}))
    },
    getMenuSnsEn: () => {
      dispatch(menusActions.getMenuSnsEn({}))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(WebMenu)))

// export default WebMenu
