import { createActions, handleActions } from 'redux-actions'
import { OPEN_ENV, API_URL, HP_URL, HP_EN_URL } from '../constants/api-config'
import { LINE_AUTH, SSO_TOKEN, SSO_ME } from '../constants/api-url'
import { select, put, call, takeEvery, take, takeLatest, delay, race } from 'redux-saga/effects'
import { actions as locationActions } from './location'
import api from './api'
import {
  AUTH_EXISTS,
  AUTH_PASSWORD,
  AUTH_LOGIN,
  AUTH_ME,
  AUTH_LINE,
  CHANGE_PASSWORD,
  SSO_AUTH_ME
} from '../constants/api-url'
import liff, { init as liffInit } from '../modules/liff'
import { isEmpty } from '../util/common'
import {
  SSO_CLIENT_ID,
  SSO_CLIENT_SECRET,
  SSO_CALLBACK_URL,
  SSO_ACCESS_TOKEN,
  SSO_USER_LOGIN_INFO,
} from '../constants/makeShop'
import { actions as errorActions } from './error'
import queryString from 'query-string'
import {store} from '../index'
import i18n from './i18n'

/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    init: (success = () => {}) => ({success}),
    shouldAuth: (success = () => {}) => ({success}),

    liffVerify: (success = () => {}) => ({success}),

    setGlavissAuth: payload => payload,

    setLocation: payload => payload,

    verify: (success = () => {}) => ({success}),
    getAuth: (code, success = () => {}) => ({code, success}),
    setAuth: payload => payload,
    changePassword: payload => payload,

    deleteSso: () => ({}),

    logout: () => ({}),
    check: () => ({}),
    getMe: () => ({}),
  },
  { prefix: '9999/auth'},
)
/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  glaVissSso: {
    token: null,
    token_type: 'Bearer',
    user: null,
  },
  glaViss: {
    token: null,
    token_type: 'Bearer',
    customer: null,
  },
  location: '/',
}
const reducer = handleActions({
  [actions.init]: (state, action) => ({
    ...initialState,
    ...state,
  }),
  [actions.setGlavissAuth]: (state, action) => ({
    ...state,
    glaViss: {
      token: action.payload.token,
      token_type: action.payload.token_type,
    }
  }),
  [actions.logout]: (state, action) => ({
    ...initialState,
    location: state.location,
  }),
  [actions.setLocation]: (state, action) => ({
    ...state,
    location: action.payload,
  }),
  [actions.setAuth]: (state, action) => ({
    ...state,
    glaVissSso: {
      ...state.glaVissSso,
      ...action.payload,
    }
  }),
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* authSaga() {
  yield takeEvery(actions.init, init)
  yield takeEvery(actions.shouldAuth, shouldAuth)
  yield takeEvery(actions.liffVerify, liffVerify)
  
  yield takeEvery(actions.changePassword, changePassword)

  yield takeEvery(actions.verify, verify)
  yield takeEvery(actions.getAuth, getAuth)
  yield takeEvery(actions.logout, logout)
  yield takeEvery(actions.deleteSso, deleteSso)

  yield takeLatest(actions.check, check)
  yield takeLatest(actions.getMe, getMe)
}

function* init(action) {
  // Line内ブラウザで開かれていれば、ミニアプリとしてLine認証
  if (liff.isInClient()) {
    liffInit(() => {
      store.dispatch(actions.setAuth(initialState.glaVissSso))
      store.dispatch(actions.liffVerify(action.payload.success))
    })
  } else {
    yield call(verify, action)
  }
}

function* shouldAuth(action) {
  const {
    auth: {
      glaVissSso
    }
  } = yield select()

  if (glaVissSso && glaVissSso.token) {
    // 認証済みならそのまま返す
    action.payload.success()
    return
  }

  if (liff.isInClient()) {
    // LINE内ブラウザで開かれていれば、ミニアプリとしてLine認証を待つ
    yield call(liffVerify, action)
    return
  }

  // ログイン認証中なら終了
  const {
    code = null
  } = queryString.parse(window.location.search)
  if (code) {
    return
  }

  // ログインへ遷移
  // TODO: ログイン成功後に同じ処理をさせたい
  yield call(redirectSso)
}

function* liffVerify(action) {
  try {
    if (OPEN_ENV === 'local') {
      return 
    }
    
    const idToken = liff.getIDToken()
    const accessToken = liff.getAccessToken()
    let response = yield call(apiAuthWithLine, idToken, accessToken)
    if (!response.isSuccess) {
      // エラー
      return
    }
  
    yield put(actions.setAuth(response.json))

    // ユーザーを取得
    const me = yield call(ssoMe, response.json)
    if (!me) {
    // エラー
      return
    }

    action.payload.success()
  } catch(e) {
    // liff.init()が終わってないとエラーになるが、
    // liff.init成功時にもう一度呼び出される
    yield race({
      action: take(actions.setAuth),
      timeout: delay(60 * 1000, true)
    })

    action.payload.success()
  }
}

function* apiAuthWithLine(id_token, access_token) {
  const payload = {
    url: AUTH_LINE,
    method: 'POST',
    request: {
      id_token,
      access_token,
    },
  }
  return yield call(api, payload)
}


function* changePassword(action) {
  const {
    old_password,
    password,
    password_confirmation
  } = action.payload

  const response = yield call(api, {
    url: CHANGE_PASSWORD,
    method: 'PUT',
    request: {
      old_password,
      password,
      password_confirmation
    },
  })
  if (!response.isSuccess) {
    return
  }

  // 認証保存
  yield put(actions.setGlavissAuth(response.json))

  yield put(locationActions.link('/member/info'))
}


function* verify(action) {
  const {
    auth: {
      glaVissSso
    }
  } = yield select()

  if (glaVissSso && glaVissSso.token) {
    // 認証トークンを持っていれば、ユーザー情報＋glaViss認証トークン を取得
    const me = yield call(ssoMe, glaVissSso)
    if (!me) {
      yield put(actions.setAuth(initialState.glaVissSso))
      return
    }

    return action.payload.success()
  }
  // ssoで認証ができていない

  const {
    provider = null,
    code = null,
  } = queryString.parse(window.location.search)
  
  if (provider === 'glaVissSSO' && code !== null) {
    // code から token を取得
    yield call(getAuth, {
      payload: {
        code,
        callback: action.payload.callback,
      }
    })
    return;
  }
}

function* getAuth(action) {
  const {
    code,
    success = () => {},
  } = action.payload

  const tokenResponse = yield call(api, {
    url: SSO_TOKEN,
    method: 'POST',
    request: { code }
  })
  if (!tokenResponse.isSuccess) {
    return
  }

  yield put(actions.setAuth(tokenResponse.json))

  // ユーザーを取得
  const me = yield call(ssoMe, tokenResponse.json)
  if (!me) {
    return
  }

  return success()
}

export function redirectSso() {
  let current = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

  window.location.href = `${API_URL}/sso/login?redirect=${encodeURI(current + '?provider=glaVissSSO')}`
}

function* ssoMe(auth) {
  const response = yield call(api, {
    url: SSO_AUTH_ME,
    method: 'GET',
    isDisplayError: false
  })

  if (!response.isSuccess) {
    return
  }

  yield put(actions.setAuth({
    user: response.json.user,
  }))
  yield put(actions.setGlavissAuth(response.json.glaviss))

  return response.json
}

function* deleteSso() {
  const response = yield call(api, {
    url: SSO_ME,
    method: 'DELETE',
    isDisplayError: false
  })
  if (!response.isSuccess) {
    return
  }

  // NOTE: 退会APIを叩いてもsession認証が継続させるためAPIにアクセスできるため明示的にログアウト
  yield call(logout)
}

function* logout() {
  const {
    location: {
      isWeb
    }
  } = yield select()

  if(isWeb){
    if(i18n.language === 'en'){
      window.location.href = `${API_URL}/sso/logout${`?redirect=${HP_EN_URL}`}`
    }else{
      window.location.href = `${API_URL}/sso/logout${`?redirect=${HP_URL}`}`
    }
  }else{
    window.location.href = `${API_URL}/sso/logout`
  }
}

// 顧客情報を取り直す
function* check() {
  const {
    auth: {
      glaVissSso
    }
  } = yield select()

  if (!glaVissSso || !glaVissSso.token) {
    return
  }

  // 認証トークンを持っていれば、ユーザー情報＋glaViss認証トークン を取得
  const me = yield call(ssoMe, glaVissSso)
  if (!me) {
    yield put(actions.setAuth(initialState.glaVissSso))
    yield put(actions.init())
    return
  }
}

// 顧客情報を取得する
function* getMe(auth) {
  const response = yield call(api, {
    url: SSO_ME,
    method: 'GET',
    isDisplayError: false
  })
  if (!response.isSuccess) {
    return
  }

  yield put(actions.setAuth({
    user: response.json.user,
  }))
  yield put(actions.setGlavissAuth(response.json.glaviss))
}

export function* waitAuth() {
  const {
    auth: {
      glaVissSso
    }
  } = yield select()

  if (glaVissSso && glaVissSso.token) {
    return true
  }

  // setGlavissAuthが行われるのを10秒待つ
  const { timeout } = yield race({
    setAuth: take(actions.setGlavissAuth),
    timeout: delay(1000 * 60, true)
  })
  if (timeout) {
    // yield put(errorActions.displayError('タイムアウトです。ネットワーク環境を確認し、もう一度お試しください。'))
    return false
  }

  return true;
}
