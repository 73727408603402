import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm } from 'redux-form'
import { actions as locationActions } from '../../../modules/location'
import { isEmptyObject } from '../../../util/common'
import moment from 'moment'
import { IMG_URL } from '../../../constants/api-config'
import { actions as authActions } from '../../../modules/auth'
import { actions as notificationsAction } from '../../../modules/informations'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'

class Info extends Component {
  componentDidMount() {
    this.props.start()
    this.props.getMe()
  }
  render() {
    const {
      member,
      ssoUser,
      link,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickMember = () => {
      link('/member')
    }

    return (
      <Content className="member-info">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem active>お客様情報の確認</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">お客様情報の確認・変更</div>
        {/* <div className="contents-subtitle">お客様情報の確認・変更</div> */}
        {/* <h1 style={{margin: '50px'}}>お客様情報の確認</h1> */}
        <div className="info-body">
          <Container>
            <Row>
              {!isEmptyObject(member) && (
                <>
                  <div className="top">
                    <Col xs="12">
                      <Label>お名前</Label>
                      <div className="value">{member.customer_family_name}{member.customer_first_name}</div>
                    </Col>
                  </div>
                  <Col xs="12">
                    <Label>お名前（カタカナ）</Label>
                    <div className="value">{member.customer_family_name_kana}{member.customer_first_name_kana}</div>
                  </Col>
                  <Col xs="12">
                    <Label>メールアドレス</Label>
                    <div className="value">{member.email_account}@{member.email_domain}</div>
                  </Col>
                  <Col xs="12">
                    <Label>携帯電話番号</Label>
                    <div className="value">{member.mobile_phone_num1}-{member.mobile_phone_num2}-{member.mobile_phone_num3}</div>
                  </Col>
                  <Col xs="12">
                    <Label>パスワード</Label>
                    <Button block className="change-password" onClick={() => link('/member/change_password')}>
                      パスワードを変更する
                    </Button>
                  </Col>
                  <Col xs="12">
                    <Label>性別</Label>
                    <div className="value">{member.sex == 1 ? '女性' : '男性'}</div>
                  </Col>
                  <Col xs="12">
                    <Label>誕生日</Label>
                    <div className="value">{member.birthday !== null ? (moment(member.birthday).format('YYYY年MM月DD日')) : ('')}</div>
                  </Col>
                  <Col xs="12">
                    <Label>郵便番号</Label>
                    <div className="value">{member.post_cd1}-{member.post_cd2}</div>
                  </Col>
                  <Col xs="12">
                    <Label>都道府県</Label>
                    <div className="value">{member.address1}</div>
                  </Col>
                  <Col xs="12">
                    <Label>市区町村番地</Label>
                    <div className="value">{member.address2}</div>
                  </Col>
                  <Col xs="12">
                    <Label>建物名</Label>
                    <div className="value">{member.address3}</div>
                  </Col>
                  <Col xs="12">
                    <Label>眼鏡の仕上がりのご連絡</Label>
                    <div className="value">{member.finish_notification_flag == 1 ? '希望する' : '希望しない'}</div>
                  </Col>
                  <Col xs="12">
                    <Label>調整などのご連絡</Label>
                    <div className="value">{member.adjustment_notification_flag == 1 ? '希望する' : '希望しない'}</div>
                  </Col>
                  <Col xs="12">
                    <Label>その他のご連絡</Label>
                    <div className="value">{member.other_notification_flag == 1 ? '希望する' : '希望しない'}</div>
                  </Col>
                </>
              )}
              {isEmptyObject(member) && (
                <>
                  <div className="top">
                    <Col xs="12">
                      <Label>お名前</Label>
                      <div className="value">{ssoUser.name}</div>
                    </Col>
                  </div>
                  <Col xs="12">
                    <Label>メールアドレス</Label>
                    <div className="value">{ssoUser.email}</div>
                  </Col>
                  <Col xs="12">
                    <Label>携帯電話番号</Label>
                    <div className="value">{ssoUser.mobile_phone_num1}-{ssoUser.mobile_phone_num2}-{ssoUser.mobile_phone_num3}</div>
                  </Col>
                  <Col xs="12">
                    <Label>パスワード</Label>
                    <Button block className="change-password" onClick={() => link('/member/change_password')}>
                      パスワード変更を変更する
                    </Button>
                  </Col>
                  <Col xs="12">
                    <Label>お住いの都道府県</Label>
                    <div className="value">{ssoUser.address1}</div>
                  </Col>
                  <Col xs="12">
                    <Label>各種情報のご連絡</Label>
                    <div className="value">{ssoUser.notification_flag == 1 ? '希望する' : '希望しない'}</div>
                  </Col>
                </>
              )}
              <Button className="btn-large" color="primary" block onClick={() => link('/member/info/edit')}>登録情報を変更する</Button>
            </Row>
          </Container>
        </div>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem active>お客様情報の確認</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    member: state.member.member,
    ssoUser: state.member.ssoUser,
    isWeb: state.location.isWeb,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    getMe: () => {
      dispatch(authActions.getMe())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'info',
  })(
    withTranslation()(
      Info
    )
  )
)

const Content = styled.div`
  margin: 0;
  font-size: 1.2em;
  background-color: #F5F5F2;

  .title{
    text-align: center;
    font-size: 12px;
  }

  .top{
    border-top: 1px solid;
    border-color: #D8D8D8;
    width:100%;
    padding-top: 20px;
  }
  
  .contents-title {
    margin-bottom: 40px;
  }

  .info-body {
    margin: 0 26px;
    padding-bottom: 80px;

    .row > div {
      border-bottom: 1px solid;
      border-color: #D8D8D8;

      margin-bottom: 21px;
    }
    .col-6:nth-child(2n+1) {
      padding-right: 2px;
    }
    .col-6:nth-child(2n) {
      padding-left: 2px;
    }

    label {
      font-size: 15px;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .value {
      font-size: 15px;
      line-height: 18px;
      border-radius: 4px;
      padding: 14px;
      padding-bottom: 20px;
    }
    .change-password {
      background-color: #C1C1B8;
      margin: 0 auto;
      margin-bottom:20px;
      color: white;
    }
  }
`
