/*************
 *HOST
 *************/

// mypage url
export const PRO_HOST = 'https://member.fournines.co.jp'
export const STG_HOST = 'https://st-mypage.glaviss.jp'
export const DEV_HOST = 'https://fsys-testmypage.mt-square.com'

// api
const PRO_API_URL = 'https://mypageapi.fournines.co.jp/mypage'
const STG_API_URL = 'https://st-mypageapi.glaviss.jp/mypage'
const DEV_API_URL = 'https://fsys-testmypageapi.mt-square.com/mypage'

// cloud front
const PRO_IMG = 'https://images.glaviss.jp'
const STG_IMG = 'https://st-images.glaviss.jp'
const DEV_IMG = 'https://fsys-testimages.mt-square.com'

// wp
const PRO_WP_URL = 'https://www.fournines.co.jp'
const STG_WP_URL = 'https://www.fournines.co.jp'
const DEV_WP_URL = 'https://fsys-testwordpress.mt-square.com/'

// HP
const PRO_HP_URL = 'https://www.fournines.co.jp'
const STG_HP_URL = 'https://fournines.f-demo.com'
const DEV_HP_URL = 'https://www.fournines.co.jp'

// HP_EN
const PRO_HP_EN_URL = 'https://www.fournines.co.jp/en'
const STG_HP_EN_URL = 'https://fournines.f-demo.com//en'
const DEV_HP_EN_URL = 'https://www.fournines.co.jp/en'

// ONLINE HEADER
const ONLINE_PRO_URL = 'https://member.fournines.co.jp/web/ja/models/ec/133_135'
const ONLINE_STG_URL = 'https://st-mypage.glaviss.jp/web/ja/models/ec/133_135'
const ONLINE_DEV_URL = 'https://fsys-testmypage.mt-square.com/web/ja/models/ec/133_135'

/**本番環境フラグ**/
export const OPEN_ENV = (() => {
  switch (true) {
    case 'https://' + window.location.hostname === PRO_HOST:
      return 'production'
    case 'https://' + window.location.hostname === STG_HOST:
      return 'staging'
    case 'https://' + window.location.hostname === DEV_HOST:
      return 'development'
    default:
      break
  }
  return 'local'
})()

/**開発URL**/
export const API_URL = (() => {
  switch (OPEN_ENV) {
    case 'production':
      return PRO_API_URL
    case 'staging':
      return STG_API_URL
    case 'development':
      return DEV_API_URL
    default:
      break
  }

  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL
  }

  return DEV_API_URL
})()

/**IMG**/
export const IMG_URL = (() => {
  switch (OPEN_ENV) {
    case 'production':
      return PRO_IMG
    case 'staging':
      return STG_IMG
    case 'development':
    default:
      return DEV_IMG
  }
})()

/**環境**/
export const ENVIRONMENT = (() => {
  switch (OPEN_ENV) {
    case 'development':
      return {
        name: '- 開発',
        color: '#3cb371',
      }
    case 'staging':
      return {
        name: '- 練習用',
        color: '#00bfff',
      }
    case 'production':
      return {
        name: '',
        color: '',
      }
    default:
      //デフォルトは開発に接続
      return {
        name: '- 開発',
        color: '#3cb371',
      }
  }
})()

/**WP URL**/
export const WP_URL = (() => {
  switch (OPEN_ENV) {
    case 'production':
      return PRO_WP_URL
    case 'staging':
      return STG_WP_URL
    case 'development':
      return DEV_WP_URL
    default:
      break
  }

  return DEV_WP_URL
})()

/**HP URL**/
export const HP_URL = (() => {
  switch (OPEN_ENV) {
    case 'production':
      return PRO_HP_URL
    case 'staging':
      return STG_HP_URL
    case 'development':
      return DEV_HP_URL
    default:
      break
  }

  return DEV_HP_URL
})()

/**HP EN URL**/
export const HP_EN_URL = (() => {
  switch (OPEN_ENV) {
    case 'production':
      return PRO_HP_EN_URL
    case 'staging':
      return STG_HP_EN_URL
    case 'development':
      return DEV_HP_EN_URL
    default:
      break
  }

  return DEV_HP_EN_URL
})()

/**オンラインモードヘッダーロゴリンク**/
export const ONLINE_HEADER_URL = (() => {
  switch (OPEN_ENV) {
    case 'production':
      return ONLINE_PRO_URL
    case 'staging':
      return ONLINE_STG_URL
    case 'development':
    default:
      return ONLINE_DEV_URL
  }
})()
