import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  Label,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardHeader,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { change, reduxForm, formValueSelector, reset } from 'redux-form'
import {
  IoIosAdd,
  IoIosRemove,
  IoIosArrowDropdownCircle,
  IoMdClose,
} from 'react-icons/io'
import nl2br from 'react-nl2br'
import { isEmptyObject, isEmpty } from '../../../util/common'
import * as Common from '../../../constants/common'
import { actions as locationActions } from '../../../modules/location'
import { actions as notificationsAction } from '../../../modules/informations'
import { store } from '../../..'
import moment from 'moment'
import Product from '../../../components/molecules/Product'
import { actions as productDetailActions } from '../../../modules/productDetail'
import { media } from '../../../components/atomics/Media'
import { HP_URL, HP_EN_URL } from '../../../constants/api-config'

class Confirmation extends Component {
  render() {
    const {
      product,
      department,
      reservationInfo,
      link,
      resetForm,
      isWeb,
      location,
      i18n,
    } = this.props

    if (isEmptyObject(product)) {
      return ''
    }

    // トップ画面に遷移
    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }
    // 商品検索画面に遷移
    const onClickBack = () => {
      link('/items')
    }

    // 商品詳細画面に遷移
    const onProduct = product => e => {
      this.props.link(`/items/${product.product_cd}`)
      e.stopPropagation()
    }

    return (
      <Content className="reservation">
        <div  className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && <BreadcrumbItem><a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a></BreadcrumbItem>}
            <BreadcrumbItem><a onClick={onProduct(product)}>商品詳細</a></BreadcrumbItem>
            <BreadcrumbItem active>取り置き店舗</BreadcrumbItem>
            <BreadcrumbItem active>取り置き完了</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">STOCK CHECKING</div>
        <div className="contents-subtitle">在庫確認</div>
        <h1>取り置きの依頼を承りました</h1>
        <div className="caption">
                取り置きの依頼を承りました。<br/>
                ご依頼の商品を確保ができましたらメッセージでご連絡いたします。
        </div>
        <Container className="contents-wrap">
          <Row xs={1} lg={2}>
            <Col>
              <Product
                product={product}
              />
            </Col>
            <Col>
              <div className="department">
                <h3>お取り置きご依頼店舗</h3>
                <div>{department.display_name}</div>
              </div>
              <div className="comment">
                {department.address1}{department.address2} {department.address3}<br/>
                Tel. {department.phone_num1}-{department.phone_num2}-{department.phone_num3}<br/>
                {nl2br(department.description)}
              </div>
              <div className="schedule">
                <h3>ご来店予定日</h3>
                <div>{moment(reservationInfo.schedule_date).format('YYYY年 MM月DD日（ddd）')}</div>
              </div>
              <div className="cancel">
                上記期間内までにご来店の無い場合<br/>
                お取り置きはキャンセルとさせていただきます。
              </div>
            </Col>
          </Row>
        </Container>
        <hr className="list-pc-line"></hr>
        <div  className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            {!isEmpty(this.props.referencePath) && !isEmpty(this.props.referenceName) && <BreadcrumbItem><a onClick={() => link(this.props.referencePath)}>{this.props.referenceName}</a></BreadcrumbItem>}
            <BreadcrumbItem><a onClick={onProduct(product)}>商品詳細</a></BreadcrumbItem>
            <BreadcrumbItem active>取り置き店舗</BreadcrumbItem>
            <BreadcrumbItem active>取り置き完了</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { favorite_id } = props.match.params
  return {
    product: state.favorites.products.find(product => product.id == favorite_id),
    department: state.reservation.stores.find(store => store.id == state.reservation.reservationInfo.department_id),
    reservationInfo: state.reservation.reservationInfo,
    isWeb: state.location.isWeb,
    referencePath: state.location.referencePath || '',
    referenceName: state.location.referenceName || '',
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'confirmation',
  })(
    withTranslation()(
      Confirmation
    )
  )
)

const Content = styled.div`
  margin: 0;

  .caption{
    border-top: 1px solid #707070;
    font-size: 14px;
    line-height: 25px;
    margin: 0 20px;
    padding: 20px 0;
    text-align: center;
  }

  .contents-wrap {
    background-color: #ffffff;
    margin-bottom: 200px;
  }

  .department {
    background-color: #FFFF;
    border-top: 1px solid #D3D3D3;
    padding: 24px 0;
    text-align: center;
    font-size: 19px;

    h3 {
      font-size: 19px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 20px;
    }
  }

  .schedule {
    background-color: #FFFF;
    border-top: 1px solid #D3D3D3;
    padding: 24px 0;
    text-align: center;
    font-size: 28px;

    h3 {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  .comment {
    background-color: #FFFF;
    border-top: 1px solid #D3D3D3;
    font-size: 14px;
    line-height: 25px;
    padding: 20px;
    text-align: left;
  }

  .cancel {
    border-top: 1px solid #D3D3D3;
    font-size: 14px;
    line-height: 25px;
    padding: 20px;
    text-align: center;
  }

  ${media.sm`
    .contents-wrap {
      .col {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  `}
  ${media.lg`
    .contents-wrap {
      .col {
        padding-top: 20px;
      }
    }
  `}
`
