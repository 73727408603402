import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  Label,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardHeader,
  CardFooter,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, Field, change, reset, getFormValues } from 'redux-form'
import {
  IoIosAdd,
  IoIosRemove,
  IoIosArrowDropdownCircle,
  IoMdClose,
} from 'react-icons/io'
import { actions as locationActions } from '../../modules/location'
import nl2br from 'react-nl2br'
import { isEmpty } from '../../util/common'
import { actions as authActions } from '../../modules/auth'

class Login extends Component {
  componentDidMount() {
    this.props.start()
  }

  render() {
    const {
      formValues = {},
      setForm,
      checkEmail,
      storePassword,
      login,
    } = this.props

    let inputType = 'email'
    switch (formValues.emailExists) {
      case 'Unregistered password':
        inputType = 'password'
        break;
      case 'Registered':
        inputType = 'login'
        break;
      default:
        break;
    }

    return (
      <Content className="login">
        {inputType === 'email' && (
          <div className="email">
            <div className="message">
              メールアドレスを入力して次へ
            </div>
            <div>
              <Input name="email" tag={Field} component="input" placeholder="メールアドレス" />
            </div>
            <div>
              <Button className="button" block onClick={e => checkEmail(formValues)}>次へ</Button>
            </div>
          </div>
        )}
        {inputType === 'password' && (
          <div className="password">
            <div className="message">
              パスワードを登録して下さい。<br/>
              英数記号を含めて6桁以上での登録を推奨します。
            </div>
            <div>
              <Field name="password" component="input" type="password" placeholder="パスワード" className="form-control" />
              <Field name="password_confirmation" component="input" type="password" placeholder="パスワードの確認" className="form-control" />
            </div>
            <div>
              <Button className="button" block onClick={e => storePassword(formValues)}>パスワード登録</Button>
            </div>
          </div>
        )}
        {inputType === 'login' && (
          <div className="login">
            <div className="email">
              {formValues.email}
            </div>
            <div>
              <Field name="password" component="input" type="password" placeholder="パスワード" className="form-control"/>
            </div>
            <div>
              <Button className="button" block onClick={e => login(formValues,this.props.location)}>ログイン</Button>
            </div>
          </div>
        )}

      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    formValues: getFormValues('login')(state),
    location: state.auth.location
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    setForm: (key, value) => {
      dispatch(change('login', key, value))
    },
    checkEmail: (formValues) => {
      dispatch(authActions.checkEmail({
        ...formValues,
        success: (json) => {
          dispatch(change('login', 'emailExists', json.status))
        }
      }))
    },
    storePassword: (formValues) => {
      dispatch(authActions.storePassword({
        ...formValues,
        success: (json) => {
          dispatch(locationActions.link('/'))
        }
      }))
    },
    login: (formValues,url) => {
      dispatch(authActions.login({
        ...formValues,
        success: (json) => {
          dispatch(locationActions.link(url))
          dispatch(authActions.setLocation('/'))
        }
      }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'login',
    initialValues: {
      email: '',
      emailExists: '',
      password: '',
      password_confirmation: '',
    }
  })(
    withTranslation()(
      Login
    )
  )
)

const Content = styled.div`
  margin:30px;
  width: 300px;

  .message{
    font-size: 20px;
  }
  
  .button{
    background-color: black;
    color: white;
  }

  > div {
    > div {
      margin-bottom: 14px;
    }
  }
`
