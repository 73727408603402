import React, { Children, Component } from 'react'
import Slider from 'react-slick'
import { IMG_URL } from '../../constants/api-config'
import styled, { css } from 'styled-components'
import { media } from '../../components/atomics/Media'

class Carousel extends Component {
  constructor() {
    super()
    this.videoRef = []
    this.setVideoRef = (element) => {
      this.videoRef.push(element)
    }
  }

  play = () => {
    this.slider.slickPlay()
  }
  pause = () => {
    this.slider.slickPause()
  }

  render() {
    const {
      items = [],
      renderItem = (item) => {},
      interval = 3000,
      className = '',
      source = null,
      isEc = false,
      isPc = true,
      ...params
    } = this.props

    const moveStart = (index) => {
      if (index === 0 && this.videoRef[0]) {
        this.pause()
        this.videoRef[0].play()
      } else {
        moveStop()
      }
    }

    const moveStop = () => {
      if (this.videoRef[0]) {
        this.videoRef[0].currentTime = 0
        this.videoRef[0].pause()
      }
    }

    const moveOnEnd = () => {
      this.play()
    }

    const moveInitialStart = () => {
      if (this.videoRef[0]) {
        this.pause()
        this.videoRef[0].play()
      }
    }

    return (
      <Wrap isEc={isEc} source={source}>
        <Slider
          ref={(slider) => (this.slider = slider)}
          dots={true}
          className={className}
          infinite={true}
          speed={300}
          autoplay={items.length > 1}
          autoplaySpeed={3000}
          afterChange={isEc ? (index) => moveStart(index) : () => {}}
          pauseOnFocus={false}
          pauseOnHover={false}
          {...params}
        >
          {isEc && source && (
            <div className="video-section">
              <video
                type="video/mp4"
                playsInline
                ref={this.setVideoRef}
                src={source}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                onEnded={() => moveOnEnd()}
                onPause={() => moveOnEnd()}
                onLoadStart={() => moveInitialStart()}
                muted
              />
            </div>
          )}
          {items.map((item, i) => (
            <div className="image-section" key={i}>
              {renderItem(item)}
            </div>
          ))}
          {this.props.children}
        </Slider>
      </Wrap>
    )
  }
}

export default Carousel

const Wrap = styled.div`
  ${(props) =>
    props.isEc && props.source
      ? css`
          img {
            height: 100%;
          }

          ${media.xl`
    .image-section, .video-section {
      height: 640px;
      min-height: 640px;
    }  
  `}

          ${media.lg`
    .image-section, .video-section {
      height: 496px;
    } 
  `}

  ${media.md_lg`
    .image-section, .video-section {
      height: 860px;
    }
  `}

  ${media.sm_md`
    .image-section, .video-section {
      height: 645px
    }  
  `}

  ${media.xs_sm`
    .image-section, .video-section {
      height: calc(100vw + 45px);
      min-height: 0px;
      margin-bottom: 0;
    }
  `}
        `
      : ''}
`
