import { css, keyframes } from 'styled-components'

export const media = {
  xl: (...args) => css`
    @media (min-width: 1280px) {
      ${ css(...args)}
    }
  `,
  lg: (...args) => css`
    @media (min-width: 992px) {
      ${ css(...args)}
    }
  `,
  md: (...args) => css`
    @media (min-width: 768px) {
      ${ css(...args)}
    }
  `,
  sm: (...args) => css`
    @media (min-width: 576px) {
      ${ css(...args)}
    }
  `,
  xs: (...args) => css`
    @media (min-width: 0px) {
      ${ css(...args)}
    }
  `,

  lg_xl: (...args) => css`
    @media (min-width: 992px) and (max-width: 1279px) {
      ${ css(...args)}
    }
  `,
  md_lg: (...args) => css`
    @media (min-width: 768px) and (max-width: 991px) {
      ${ css(...args)}
    }
  `,
  sm_md: (...args) => css`
    @media (min-width: 576px) and (max-width: 767px) {
      ${ css(...args)}
    }
  `,
  xs_sm: (...args) => css`
    @media (min-width: 0px) and (max-width: 575px) {
      ${ css(...args)}
    }
  `
}