import { createActions, handleActions } from 'redux-actions'
import { select, put, call, takeEvery } from 'redux-saga/effects'
import { reduxForm, Field, change, formValueSelector, getFormValues } from 'redux-form'
import api from './api'
import {
  AUTH_EXISTS,
  PUT_EDIT,
} from '../constants/api-url'
import { isEmpty } from '../util/common'
import { actions as errorActions } from './error'
import { actions as authActions } from './auth'


/***************************************************************
 *ACTION CREATOR
 ***************************************************************/
export const actions = createActions(
  {
    init: () => ({}),
  },
  { prefix: '9999/membeInfo'},
)

/***************************************************************
 *REDUCER
 ***************************************************************/
const initialState = {
  member: {},
}
const reducer = handleActions({
  [actions.init]: () => initialState,
}, initialState)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* memberinfoSaga() {
//  yield takeEvery(actions.loadStoreMaster, loadStoreMaster)
}
