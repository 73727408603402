import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Container, Row, Col, Button, Breadcrumb, BreadcrumbItem, Spinner } from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { actions as locationActions } from '../../modules/location'
import Toggle from '../../components/atomics/Toggle'
import Products from '../../components/molecules/Products'
import { actions as masterActions } from '../../modules/master'
import { actions as productDetailActions } from '../../modules/productDetail'
import { actions as authActions } from '../../modules/auth'
import { actions as wpActions } from '../../modules/wp'
import { isEmpty, isEmptyObject } from '../../util/common'
import Modal from '../../components/molecules/Modal'
import ItemDeatil from '../Items/Deatil'
import { IMG_URL } from '../../constants/api-config'
import { scroller } from 'react-scroll'
import { WP_URL } from '../../constants/api-config'
import moment from 'moment'
import { actions as notificationsAction } from '../../modules/informations'
import { LoadingWrapper, Loading } from '../../components/molecules/Loading'
import { media } from '../../components/atomics/Media'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timestamp: moment().unix(),
      isFirstImageLoaded: false,
    }
  }

  componentDidMount() {
    this.props.start()
    if (isEmptyObject(this.props.member)) {
      this.props.check()
    }
  }

  render() {
    const { authGlaviss, link, unread_total, topInformations, nextTopInformationProduct, wpTopPosts } = this.props

    const onClickHome = () => {
      link('/')
    }

    return (
      <Content>
        {!this.state.isFirstImageLoaded && topInformations.length !== 0 && (
          <LoadingWrapper style={{ background: '#444444' }}>
            <Loading>
              <Spinner size={'sm'} style={{ width: '5rem', height: '5rem', color: '#eeeeee' }} />
            </Loading>
          </LoadingWrapper>
        )}
        <article className="informations">
          {topInformations.map((information, index) => (
            <Toggle
              key={information.id}
              ref={(ref) => (this.toggle = { ...this.toggle, [information.id]: ref })}
              firstOpen={false}
              title={
                <img
                  className={`main-image main-image-${information.id}`}
                  src={`${IMG_URL}${information.attribute1}?${this.state.timestamp}`}
                  onLoad={(e) => {
                    if (index !== 0) {
                      return
                    }
                    // 1枚目の読み込みが終わるまでローディング＋他の画像を表示しない
                    this.setState({ isFirstImageLoaded: true })
                  }}
                  style={this.state.isFirstImageLoaded ? {} : { display: 'none' }}
                />
              }
              onEntering={() =>
                scroller.scrollTo(`sub-image-${information.id}`, {
                  duration: 800,
                  delay: 0,
                  smooth: 'true',
                  offset: -70,
                })
              }
              onExiting={() =>
                scroller.scrollTo(`main-image-${information.id}`, {
                  duration: 800,
                  delay: 0,
                  smooth: 'true',
                  offset: -70,
                })
              }
            >
              <div className={'sub-image'}>
                <img
                  className={`sub-image sub-image-${information.id}`}
                  src={`${IMG_URL}${information.attribute2}?${moment().unix()}`}
                  onClick={() => this.toggle[information.id].close()}
                />
              </div>
              <Products
                products={information.products.filter((product) => product.loaded)}
                hasMore={information.total > information.show}
                onClickMore={() => nextTopInformationProduct(information)}
              />
            </Toggle>
          ))}
        </article>
        <article className="hp-contents">
          <Container fluid={true}>
            <Row xs="1">
              {wpTopPosts.map((post) => (
                <Col key={post.id}>
                  <Toggle
                    firstOpen={false}
                    title={
                      <img
                        className="featuredmedia"
                        src={post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url}
                        alt={post.title.rendered}
                        style={this.state.isFirstImageLoaded || topInformations.length === 0 ? {} : { display: 'none' }}
                      />
                    }
                    onEntered={() => {
                      this[`wp-content-${post.id}`].style.height =
                        this[`wp-content-${post.id}`].contentWindow.document.body.scrollHeight + 'px'
                    }}
                  >
                    <div className="hp-section">
                      <iframe
                        scrolling="no"
                        ref={(ref) => (this[`wp-content-${post.id}`] = ref)}
                        srcDoc={`<!DOCTYPE html>
                      　<html lang="ja">
                          <head>
                            <link rel="stylesheet" id="wp-block-library-css" href="${WP_URL}/wp-includes/css/dist/block-library/style.min.css?ver=5.5.3" media="all">
                            <link rel="stylesheet" id="twentytwenty-style-css" href="${WP_URL}/wp-content/themes/twentynineteen/style.css?ver=1.5" media="all">
                            <style type="text/css">.entry .entry-content > *:first-child {margin-top:0;} .entry .entry-content > *:last-child {margin-bottom:0;}</style>
                          </head>
                          <body>
                            <div class="entry"><div class="entry-content">
                              ${post.content.rendered.replace(/(<a .*?)(>.*?<\/a>)/g, '$1 target="_parent" $2')}
                            </div></div>
                          </body>
                        </html>`
                          .replace(/\r?\n/g, '')
                          .replace(/>\s+</g, '><')}
                      />
                    </div>
                  </Toggle>
                </Col>
              ))}
            </Row>
          </Container>
        </article>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    member: state.member.member,
    unread_total: state.informations.searchedMeta.unread_total,
    topInformations: state.master.topInformations,
    wpTopPosts: state.wp.top,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    start: () => {
      dispatch(masterActions.loadTopInformations())
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    nextTopInformationProduct: (information) => {
      dispatch(masterActions.nextTopInformationProduct(information))
    },
    check: () => {
      dispatch(authActions.check())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Home))

const Content = styled.div`
  .informations {
    .main-image {
      width: 100%;
      cursor: pointer;
    }
    .sub-image {
      line-height: 0;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }

  .products {
    cursor: pointer;
  }

  .hp-contents {
    .featuredmedia {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }

  .hp-section {
    padding: 0;
    iframe {
      width: 100%;
      overflow: hidden;
    }
  }
`
