import { LIFF_ID } from '../constants/liff'

const liff = window.liff
export default liff

export function init(success) {
  try {
    liff.init(
      { liffId: LIFF_ID },
      success,
      (err) => {
        console.log(err.code, err.message);
      }
    )  
  } catch(e) {
  }
}

