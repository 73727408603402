import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardBody, CardTitle, CardSubtitle, CardText, CardImg, Button } from 'reactstrap'
import { actions as locationActions } from '../../modules/location'
import styled from 'styled-components'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import Slider from 'react-slick'
import ProductImage from '../../components/molecules/ProductImage'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import classNames from 'classnames'
class Series extends Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
    }
  }

  render() {
    const { series, className = '', pageName, isWeb, link, i18n, catalogPriceHidden } = this.props

    const NextArrow = ({ currentSlide, slideCount, ...props }) => (
      <div {...props}>
        <img src="/images/group_7415.svg" />
      </div>
    )

    const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
      <div {...props}>
        <img src="/images/group_7768.svg" />
      </div>
    )

    const onSlideClick = (index) => {
      if (this.index === index) {
        return
      }
      this.slider.slickGoTo(index)
    }

    const afterSlideChange = (index) => {
      this.setState({ index })
    }

    const onClick = () => {
      this.props.link({
        pathname: `/items/${series.models[this.state.index].model_products[0].product_cd}`,
        state: {
          referencePath: this.props.location.pathname,
          referenceName: pageName,
          referenceScroll: window.scrollY,
        },
      })
    }

    let nextArrow
    let prevArrow
    if (series.models.length > 4) {
      nextArrow = <NextArrow />
      prevArrow = <PrevArrow />
    }

    let newFlag
    for (let i = 0; i < series.models[this.state.index].model_products.length; i++) {
      if (series.models[this.state.index].model_products[i].new_flag === '1') {
        newFlag = '1'
        break
      }
    }
    return (
      <Content className={`series_item ${className}`}>
        <CardBody>
          {newFlag === '1' && <img className="new_label" src={`${IMG_URL}/img/ico/new.png`} />}
          <div onClick={(e) => onClick()}>
            <CardSubtitle className="name">
              {i18n.language === 'en' ? series.eng_name : series.display_name}
            </CardSubtitle>
            <div className="image-wrap">
              <CardImg
                top
                width="100%"
                src={`${IMG_URL}/img/Model/${series.models[this.state.index].group_cd}_1.jpg`}
                alt={series.models[this.state.index].display_name}
                className="image"
              />
            </div>
            <CardText className="display-name">
              {i18n.language === 'en'
                ? series.models[this.state.index].eng_name
                : series.models[this.state.index].display_name}
            </CardText>
            {/** 言語が日本語の場合で「商品分類2」が「べっ甲」(商品分類2ID : 27)の場合も希望小売価格を非表示 */}
            {/** #22840 [FNM-カタログ]価格の表示を非表示に変更依頼 */}
            <CardText
              tag="div"
              className={classNames('price', {
                invisible:
                  (catalogPriceHidden && isWeb) ||
                  i18n.language === 'en' ||
                  series.models[this.state.index].model_products[0].class2_id == 27,
              })}
            >
              {series.models[this.state.index].model_products[0].sell_unitprice_w_tax.toLocaleString()}円(税込)
            </CardText>
          </div>
          <div
            className="models-slider"
            style={{ width: `calc((65px + 6px) * ${Math.min(series.models.length, 4)} - 6px)` }}
          >
            <Slider
              slidesToScroll={1}
              variableWidth
              dots
              nextArrow={nextArrow}
              prevArrow={prevArrow}
              ref={(ref) => (this.slider = ref)}
              afterChange={afterSlideChange}
              // appendDots = {dot}
            >
              {series.models.map((model, index) => (
                <div key={index} className="models-slider-item" onClick={(e) => onSlideClick(index)}>
                  <ProductImage
                    model={model}
                    imageNo={model.image_numbers.length ? model.image_numbers[0].number : 1}
                  />
                </div>
              ))}
            </Slider>
            {series.models.length === 1 && (
              <ul className="slick-dots" style={{ display: 'block' }}>
                <li className="slick-active">
                  <button>1</button>
                </li>
              </ul>
            )}
          </div>
        </CardBody>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    catalogPriceHidden: state.master.catalogPriceHidden,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Series)))

const Content = styled(CardBody)`
  padding-top: 0;
  padding-bottom: 0;
  background-color: #ffffff;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;

  .image-wrap {
    margin: 0 auto;
    height: 180px;
    display: flex;
  }
  .card-body {
    cursor: pointer;
  }
  .card-subtitle {
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 2.66px;
    font-weight: 300;
    margin-bottom: 3px;
    width: calc(100% - 40px);
  }
  .card-title {
    font-size: 26px;
    line-height: 33px;
    font-weight: bold;
    margin-bottom: 11px;
    display: inline-block;
  }
  .card-img-top {
    cursor: pointer;
    object-fit: contain;
  }

  .new_label {
    position: absolute;
    top: 27px;
    right: 40px;

    display: inline-block;
    background: #a7a7a7 0% 0% no-repeat padding-box;
    font: normal normal normal 11px/12px Arial;
    color: #ffffff;
    height: 18px;
  }

  .size {
    border-radius: 0;
    font-size: 10px;
    display: inline-block;
    float: right;
    display: block;
    margin: 0 0 0 auto;
    padding: 3px 5px;
    border-color: #bababa;
    cursor: pointer;
  }

  .color {
    font-size: 11px;
    line-height: 18px;
    margin-bottom: 13px;
    min-height: 3rem;
  }
  .color-code {
  }
  .color-name {
  }
  .lens-color {
  }
  .name {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 9px;
  }

  .display-name {
    margin-top: -20px;
    margin-bottom: 0;
    text-align: center;
  }

  .price {
    font-size: 13px;
    margin-bottom: 18px;
    text-align: center;
  }
  .price-label {
    padding-right: 1rem;
  }

  .models-slider {
    position: relative;
    margin: 0 auto;
  }
  .slick-slide {
    margin-right: 6px;
  }
  .models-slider-item {
    height: 64px;
    position: relative;
  }
  .models-slider-item:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dddddd;
    opacity: 0.5;
  }
  .slick-current .models-slider-item:after {
    content: none;
  }
  .slick-dots {
    bottom: -40px;
  }
  .slick-arrow {
    top: auto;
    bottom: -35px;
    font-size: inherit;
    line-height: inherit;
    transform: inherit;
    z-index: 10;
  }
  .slick-prev {
    left: calc(50% - 140px);
    &:before {
      content: none;
    }
  }
  .slick-next {
    right: calc(50% - 140px);
    &:before {
      content: none;
    }
  }

  .product-image {
    margin: 0;
    padding: 3px;
    width: 65px;
    height: 65px;
    position: relative;
  }

  ${media.lg`
padding: 0;
height:400px;

.card-body card-subtitle {
    margin-top: 0;
    margin-bottom: 0;
  }
  .card-body {
    padding: 0;
    min-height: 375px;
    .image {
      object-fit: cover;
      width: 160px;
      margin:0 auto;
      display: block;
    }
  }

  .new_label {
    display: inline-block;
    position: absolute;
    padding
    top: 3px;
    right: 20px;
  } 

  .name {
    font: normal normal normal 20px/24px Helvetica Neue;
    font-size: 15px;
    padding-top: 30px;
    padding-left: 20px;
    padding-bottom: 20px;
    width: calc(100% - 60px);
  }

  .display-name {
    text-align:center
    margin-top: -10px;
    margin-bottom: 0;
  }
  .price {
    text-align:center;
    margin-bottom: 0;
  }

  .image-wrap {
    margin:0 auto;
    height:75px;
    display:block;
  }

  .card-img-top {
    cursor: pointer;
    padding: 0px;

  }

  .models-slider {
    margin: 20px auto;
  
  }
  
`}
`
