import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { actions as authActions } from '../../modules/auth'
import { actions as favoritesActions } from '../../modules/favorites'
import { isEmptyObject } from '../../util/common'
import { actions as locationActions } from '../../modules/location'
import { actions as reservationAction } from '../../modules/reservation'
import * as Common from '../../constants/common'
import Products from '../../components/molecules/Products'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'

class Favorites extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCount: props.favoritePage,
    }
  }

  componentDidMount() {
    // 戻ってきたら検索しない
    // MEMO: HistoryBackで戻ったら history.action == POP になる。
    //　　　　一覧へで戻ったら isReturnItems == true で戻している。
    this.props.locationSet('isReturnItems', false)
    if (!isEmptyObject(this.props.products)) {
      if (this.props.isReturnItems) {
        // スクロール位置を戻す
        // @@router/LOCATION_CHANGE イベントでスクロール位置を変えても、上手く行かない。
        window.scrollTo(0, this.props.referenceScroll)
        return
      } else if (this.props.history.action === 'POP') {
        return
      }
    }

    this.props.start()
  }

  componentDidUpdate(props) {
    // マスタ読み込みが間に合ってなかった場合の対応
    if (!this.state.showCount && props.favoritePage > 0) {
      this.setState({ showCount: props.favoritePage })
    }
  }

  // お取り置き商品取得
  getReservation = (targetProduct) => {
    let reservation = this.props.reservations.find((reservation) => reservation.product_id == targetProduct.id)
    return reservation || {}
  }

  // お取り置き表示のメッセージ
  getReservationMessage = (reservationProduct) => {
    let scheduleDate = moment(reservationProduct.schedule_date).format(' M月 D日（ddd）')
    let departmentName = reservationProduct.department.display_name
    let message = reservationProduct.situation == '0' ? '依頼中' : ''
    return `${scheduleDate}${departmentName}にてお取り置き${message}`
  }

  // ショップリスト　デバイスによってリンク変更
  linkShopList = () => {
    let url
    if (this.props.i18n.language === 'en') {
      url = 'https://www.fournines.co.jp/en/shoplist/'
    } else {
      url = 'https://www.fournines.co.jp/shoplist/'
    }
    return window.location.assign(url)
  }

  render() {
    const { products, sub, favoritePage, showCountAdd, isSearching, set, link, isWeb, i18n } = this.props

    const showCount = favoritePage + showCountAdd

    const onClickMore = () => {
      set('showCountAdd', showCountAdd + favoritePage)
    }

    const onClickHome = () => {
      if (isWeb) {
        if (i18n.language === 'en') {
          window.location.href = HP_EN_URL
        } else {
          window.location.href = HP_URL
        }
      } else {
        link('/')
      }
    }

    // お気に入り商品の並び順
    const sortProducts = (targetProducts) => {
      return targetProducts.sort((a, b) => {
        let ta = this.getReservation(a)
        let tb = this.getReservation(b)
        const ma = ta.schedule_date ? moment(ta.schedule_date) : moment('2018-01-23')
        const mb = tb.schedule_date ? moment(tb.schedule_date) : moment('2018-01-23')

        let tas = ta.id ? 1 : 0
        let tbs = tb.id ? 1 : 0
        // 取り置き日の昇順
        if (tas > tbs) return -1
        if (tas < tbs) return 1
        if (mb.isAfter(ma)) return -1
        if (ma.isAfter(mb)) return 1
        // お気に入りに追加順(お気に入り追加時に追加順になるっぽいのでロジック不要)
        return 0
      })
    }

    return (
      <Content className="favorites">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>トップページ</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>お気に入り</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">FAVORITES</div>
        <div className="contents-subtitle">お気に入り</div>
        <Products
          products={sortProducts(products).slice(0, showCount)}
          isSearching={isSearching}
          hasMore={showCount < products.length}
          onClickMore={() => onClickMore()}
        />
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>トップページ</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>お気に入り</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    // TODO:APIの不具合のため
    products: state.favorites.products.filter((product) => !isEmptyObject(product)),
    // お取り置き商品(依頼中と承認済み)
    reservations: state.reservation.temporaryReservations || [],
    favoritePage: parseInt(state.master.favoritePage.value_string),
    showCountAdd: parseInt(state.favorites.showCountAdd),
    member: state.member.member,
    isSearching: state.favorites.isSearching,
    isWeb: state.location.isWeb,

    isReturnItems: state.location.isReturnItems,
    referenceScroll: state.location.referenceScroll,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    start: () => {
      dispatch(favoritesActions.init())
      dispatch(
        authActions.shouldAuth(() => {
          dispatch(reservationAction.searchReservationProducts())
        })
      )
    },
    set: (key, value) => {
      dispatch(favoritesActions.set(key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    sub: (product) => {
      dispatch(favoritesActions.sub(product))
    },
    flowStock: (product) => {
      dispatch(reservationAction.flowStock(product.id))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    locationSet: (key, value) => {
      dispatch(locationActions.set(key, value))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Favorites))

const Content = styled.div`
  .product {
    border: 0;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    padding-bottom: 40px;

    + .product {
      border-top: 0;
    }
  }

  .close {
    text-align: right;
    font-size: 25px;
    margin: 0 10px;
    cursor: pointer;
  }

  .reservation {
    text-align: center;
    background-color: #efefef;
    margin: 0;
    width: 100%;
    border-radius: 4px;
    font-size: 13px;
    padding: 4px;
  }
  .searching {
    text-align: center;
    background-color: ${Common.COLOR_LIGHT_GLAY};
    padding: 2rem;

    .spinner-border {
      width: 3rem;
      height: 3rem;
      color: ${Common.COLOR_GLAY};
      border-width: 2px;
    }
  }
`
