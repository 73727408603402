import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Row, Col, Container, Badge } from 'reactstrap'
import styled from 'styled-components'
import { actions as locationActions } from '../../modules/location'
import { reduxForm, change, reset } from 'redux-form'
import * as Common from '../../constants/common'
import { isEmpty, isEmptyObject } from '../../util/common'
import { actions as authActions } from '../../modules/auth'
import { IMG_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import { actions as notificationsAction } from '../../modules/informations'
import { ENVIRONMENT } from '../../constants/api-config'
import { HP_URL } from '../../constants/api-config'
import { actions as productsActions } from '../../modules/products'

class Header extends Component {
  componentDidUpdate() {}

  render() {
    const { isOpenMenu, name, set, link, resetForm, unread_total } = this.props

    const onClickHome = () => {
      link('/')
    }

    const onClickItem = () => {
      link('/items')
      resetForm('search_products')
      this.props.searchProduct()
    }

    const onClickFavorite = () => {
      link('/favorites')
    }

    const onClickInformation = () => {
      link('/informations')
    }

    const onClickMember = () => {
      link('/member')
    }

    return (
      <Content>
        <div className="logo" onClick={() => link('/')}>
          <img src={`${IMG_URL}/img/ico/FNM_LOGO.svg`} />
        </div>
        <div className="environment" style={{ color: ENVIRONMENT.color, fontSize: '20px' }}>
          {ENVIRONMENT.name !== '本番' && ENVIRONMENT.name}
        </div>
        <div className="header-info">
          <div className="g-header__ln__wrapper shopList">
            <a className="g-header__ln" target="_blank" rel="noopener" href={`${HP_URL}/shops/`}>
              <i className="g-header__ln__icon g-header__ln__icon--shop">
                <img className="g-header__i--b" src="/assets/ui/pin.svg" alt="" />
                <img className="g-header__i--w" src="/assets/ui/pin.w.svg" alt="" />
              </i>
              <span>店舗検索</span>
              <div style={{ paddingBottom: '2px' }}>　</div>
            </a>
          </div>
          {!isEmpty(name) && <div className="hello">{name} さん</div>}
        </div>

        <nav>
          <Container>
            <Row className="icon">
              <Col href="#" className="home" onClick={() => onClickHome()}>
                <img src={`${IMG_URL}/img/ico/GN_home_2.png`} width="18" height="22" alt="" />
                ホーム
              </Col>
              <Col href="#" className="item" onClick={() => onClickItem()}>
                <img src={`${IMG_URL}/img/ico/GN_search_2.png`} width="18" height="21" alt="" />
                商品検索
              </Col>
              <Col href="#" className="favorite" onClick={() => onClickFavorite()}>
                <img src={`${IMG_URL}/img/ico/GN_favorite_2.png`} width="20" height="18" alt="" />
                お気に入り
              </Col>
              <Col href="#" className="information" onClick={() => onClickInformation()}>
                <img src={`${IMG_URL}/img/ico/GN_information_2.png`} width="22" height="22" alt="" />
                メッセージ
                {unread_total !== 0 && <Badge pill>{unread_total}</Badge>}
              </Col>
              <Col href="#" className="member" onClick={() => onClickMember()}>
                <img src={`${IMG_URL}/img/ico/GN_myaccount_2.png`} width="14" height="24" alt="" />
                マイアカウント
              </Col>
            </Row>
          </Container>
        </nav>

        {/** ページトップへ戻るアイコンの差し替え。 FNM単体スマホのときに位置調整をするためにHeaderに書いている。 */}
        <div class="c-b2t">
          <img src="/assets/ui/svg_float.svg" />
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenMenu: state.form.header && state.form.header.values.isOpenMenu,
    name: !isEmptyObject(state.member.member)
      ? `${state.member.member.customer_family_name} ${state.member.member.customer_first_name || ''}`
      : !isEmpty(state.member.ssoUser)
      ? state.member.ssoUser.name
      : null,
    unread_total: state.informations.searchedMeta.unread_total,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    set: (key, value) => {
      dispatch(change('header', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    logout: () => {
      dispatch(authActions.logout())
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
    searchProduct: () => {
      dispatch(productsActions.searchProduct({}))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'header',
    initialValues: {
      isOpenMenu: false,
    },
  })(Header)
)

const Content = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: #3e3e3e;
  background-color: #efefef;
  z-index: 1000;

  .header-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
  }

  .shopList {
    position: unset;
    top: unset;
    right: unset;
    padding: 25px 8px 21px 8px;
    line-height: 14px;
  }

  nav {
    display: none;

    .col {
      align-items: center;
      padding: 0;
      padding-bottom: 10px;
    }

    img {
      margin-right: 0.25rem;
    }

    .home {
      width: calc((100% - 4px) / 5);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
    }
    .item {
      width: calc((100% - 4px) / 5);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
    }
    .favorite {
      width: calc((100% - 4px) / 5);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
    }
    .information {
      width: calc((100% - 4px) / 5);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;

      .badge {
        margin-left: 0.25rem;
        background-color: #ff5d5d;
        font-size: 14px;
      }
    }
    .member {
      width: calc((100% - 4px) / 5);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
    }
  }

  + *:before {
    content: '';
    display: block;
    padding-top: 60px;
  }

  .logo {
    cursor: pointer;
    img {
      height: 14px;
      vertical-align: top;
    }
    line-height: 14px;
    position: absolute;
    top: 25px;
    left: 17px;
  }

  .title {
    text-align: right;
    padding: 25px 16px 21px 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
  }

  .environment {
    line-height: 14px;
    position: absolute;
    top: 25px;
    left: 210px;
  }

  .hello {
    text-align: right;
    padding: 25px 16px 21px 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
  }

  .login {
    padding: 12px;
    font-size: 1rem;
  }
  .logout {
    padding: 12px 5px;
    font-size: 1rem;
  }

  .favorites {
    position: absolute;
    top: 7px;
    right: 3.5rem;
    font-size: 2rem;
    svg {
      vertical-align: baseline;
    }
  }

  .sp-menu {
    position: absolute;
    top: 2px;
    right: 0px;
    z-index: 100;

    .menu-button {
      text-align: right;
      font-size: 2.4rem;
      padding: 0 10px;
      height: 46px;

      svg {
        vertical-align: baseline;
      }
    }

    .menu-list {
      background-color: ${Common.COLOR_WHITE};

      .list-group-item:first-child {
        border-top: 0;
      }
      .list-group-item:last-child {
        border-bottom: 0;
      }
    }

    .background {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .breadcrumb {
    margin: 0;
  }

  .c-b2t {
    bottom: 80px;
  }

  ${media.sm`
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;

  `}

  ${media.lg`
    nav {
      display: block;
    }

    + *:before {
      padding-top: 94px;
    }

    .c-b2t {
      bottom: 2.875rem;
    }  
  `}
`
