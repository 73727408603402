import { connect } from 'react-redux'
import React, { Component } from 'react'
import { 
  Row, 
  Col, 
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import { reset } from 'redux-form'
import * as Common from '../../constants/common'
import { actions as locationActions } from '../../modules/location'
import { IMG_URL, HP_URL } from '../../constants/api-config'
import { media } from '../../components/atomics/Media'
import { actions as notificationsAction } from '../../modules/informations'
import { actions as menusActions } from '../../modules/menus'

class WebFooter extends Component {
  componentDidMount() {
    this.props.getFooterMenuTop()
    this.props.getFooterMenu1()
    this.props.getFooterMenu2()
    this.props.getFooterMenuBottom()
    this.props.getMenuSns()
  }
  render() {
    const { 
      link,
      footerMenuTop,
      footerMenu1,
      footerMenu2,
      footerMenuBottom,
      menuSns,
    } = this.props
    return (
      <Content className="c-iv g-footer is-show">
        <div className="c-logo"><img src={`${HP_URL}/wp-content/themes/fournines/assets/ui/logo.svg`} alt="999.9"/></div>
        {footerMenuTop.length !== 0 && (
          <div className="menu-footer-menu-top-container">
            <ul id="menu-footer-menu-top" className="c-sans g-footer__contents--1">
              {footerMenuTop.map((item, index) => {
                return item.url.match(/^https?:\/\/\[shop_link\]/) ? (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-custom"><a href="#" onClick={() => link(`${item.url.replace(/^https?:\/\/\[shop_link\]/, '')}`)}>{item.title}</a></li>
                ) : (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-custom"><a href={item.url.replace(/^\//, HP_URL+'/')} target={item.target}>{item.title}</a></li>
                )
              })}
            </ul>
          </div>
        )}
        {footerMenu1.length !== 0 && (
          <div className="menu-footer-menu-1-container">
            <ul id="menu-footer-menu-1" className="c-sans g-footer__contents--2">
              {footerMenu1.map((item, index) => {
                return item.url.match(/^https?:\/\/\[shop_link\]/) ? (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-custom"><a href="#" onClick={() => link(`${item.url.replace(/^https?:\/\/\[shop_link\]/, '')}`)}>{item.title}</a></li>
                ) : (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-custom"><a href={item.url.replace(/^\//, HP_URL+'/')} target={item.target}>{item.title}</a></li>
                )
              })}
            </ul>
          </div> 
        )}
        {footerMenu2.length !== 0 && (
          <div className="menu-footer-menu-2-container">
            <ul id="menu-footer-menu-2" className="g-footer__contents g-footer__contents--3">
              {footerMenu2.map((item, index) => {
                return item.url.match(/^https?:\/\/\[shop_link\]/) ? (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-custom"><a href="#" onClick={() => link(`${item.url.replace(/^https?:\/\/\[shop_link\]/, '')}`)}>{item.title}</a></li>
                ) : (
                  <li key={index} className={`menu-item menu-item-type-post_type menu-item-object-custom ${item.target === '_blank' && 'c-ln--blank'}`}><a href={item.url.replace(/^\//, HP_URL+'/')} target={item.target}>{item.title}</a></li>
                )
              })}
            </ul>
          </div>
        )}
        {menuSns.length !== 0 && (
          <div className="g-footer__sns">
            {menuSns.map((item, index) => (
              <a key={index} target="_blank" rel="noopener noreferrer" href={`${item.link.url}`}>
                <img src={item.image} alt={item.link.title}/>
              </a>
            ))}
          </div>
        )}
        {footerMenuBottom.length !== 0 && (
          <div className="menu-footer-bottom-menu-container">
            <ul id="menu-footer-bottom-menu" className="g-footer__contents g-footer__contents--4">
              {footerMenuBottom.map((item, index) => {
                return item.url.match(/^https?:\/\/\[shop_link\]/) ? (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-custom"><a href="#" onClick={() => link(`${item.url.replace(/^https?:\/\/\[shop_link\]/, '')}`)}>{item.title}</a></li>
                ) : (
                  <li key={index} className="menu-item menu-item-type-post_type menu-item-object-custom"><a href={item.url.replace(/^\//, HP_URL+'/')} target={item.target}>{item.title}</a></li>
                )
              })}
            </ul>
          </div>
        )}
        <div className="g-footer__c">
          <p className="c-sans">©Four Nines Co.Ltd. All Rights Reserved.</p>
        </div>
      </Content>
    )
  }
}
const mapStateToProps = state => {
  return {
    footerMenuTop:state.menus.footerMenuTop,
    footerMenu1:state.menus.footerMenu1,
    footerMenu2:state.menus.footerMenu2,
    footerMenuBottom:state.menus.footerMenuBottom,
    menuSns:state.menus.menuSns,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    getFooterMenuTop: () => {
      dispatch(menusActions.getFooterMenuTop({}))
    },
    getFooterMenu1: () => {
      dispatch(menusActions.getFooterMenu1({}))
    },
    getFooterMenu2: () => {
      dispatch(menusActions.getFooterMenu2({}))
    },
    getFooterMenuBottom: () => {
      dispatch(menusActions.getFooterMenuBottom({}))
    },
    getMenuSns: () => {
      dispatch(menusActions.getMenuSns({}))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WebFooter)

const Content = styled.footer`
margin-bottom: -60px;
${media.lg`
  margin-bottom: 0;
`}
`
