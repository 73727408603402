import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {Provider} from 'react-redux'
import configureStore from './store'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from 'react-router'
import moment from 'moment'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-datepicker/dist/react-datepicker.css'
import './modules/i18n'
import './style.css'
import './index.css'
import { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import * as Common from './constants/common'
import { OPEN_ENV } from './constants/api-config'
import LoginCheckCallback from './views/Layout/LoginCheckCallback'
import PrivacyPolicyModal from './components/molecules/PrivacyPolicyModal'
import TermsOfServiceModal from './components/molecules/TermsOfServiceModal'

// 本番以外ではGA無効化
if (OPEN_ENV !== 'production') {
  window[`ga-disable-${Common.GA}`] = true
}

let state = window.__initialState__ || undefined
const history = createBrowserHistory()
history.listen(({ pathname }) => {
  window.gtag('config', Common.GA, {
    'page_path': pathname
  })
}) 
export const store = configureStore(history, state)

moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});

registerLocale('ja', ja)

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        {/* 4/2リリース用 */}
        <Route exact path='/privacypolicy' component={PrivacyPolicyModal}/>
        <Route exact path='/service' component={TermsOfServiceModal}/>
        {/* 4/2リリース用 */}
        <Route exact path='/authCallback' component={LoginCheckCallback}/>
        <Route exact component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
