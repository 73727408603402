import { createActions } from 'redux-actions'
import { select, put, call } from 'redux-saga/effects'
import { actions as loadingAction } from './loading'
import { actions as errorAction } from './error'
import { API_URL } from '../constants/api-config'
import { isEmpty } from '../util/common'
// import { URLSearchParams } from 'url'

export default function* (payload) {
  const {auth} = yield select()
  const {
    method, 
    headers = {},
    request, 
    isCors, 
    isLoading = true, 
    isDisplayError = true,
  } = payload
  let {url} = payload

  //ローディング表示
  if (isLoading) {
    yield put(loadingAction.openLoading())
  }

  let apiResponse = {
    isSuccess: false,
    status: null,
    json: null
  }
  try {
    let options = {
      method,
      headers,
    }
    if(!url.match(/http/)) {
      url = API_URL + url

      if (url.match(/\/api\/sso\//)) {
        options.headers['Authorization'] = auth.glaVissSso.token_type + ' ' + auth.glaVissSso.token
      } else {
        // glaviss-apiアクセス時はトークン付与
        if (!isEmpty(auth.glaViss.token)) {
          options.headers['Authorization'] = auth.glaViss.token_type + ' ' + auth.glaViss.token
        } else if (!isEmpty(auth.glaVissSso.token)) {
          options.headers['Authorization'] = auth.glaVissSso.token_type + ' ' + auth.glaVissSso.token
        }
      }
    }
    if(isCors){
      options.mode = 'cors'
    } else if (isCors === false) {
      options.mode = 'no-cors'
    }

    if (options.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      const params = new URLSearchParams();
      Object.keys(request).forEach(key => params.append(key, request[key]));
      options.body = params
    } else {
      options.headers['Content-Type'] = 'application/json'
      options.headers.Accept = 'application/json'

      if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        options.body = JSON.stringify(request)
      } else if (method === 'GET' && !isEmpty(request)){
        options.headers['X-HTTP-Method-Override'] = 'GET'
        options.method = 'POST'
        options.body = JSON.stringify(request)
      }
    }

    const response = yield call(fetch, url, options)

    apiResponse.status = response.status
    try {
      apiResponse.json = yield response.json()
    } catch (e) {
      // レスポンスが空の場合、エラーが投げられるのでハンドリングだけ
    }

    if (200 <= response.status && response.status < 300) {
      apiResponse.isSuccess = true
    } else if (response.status === 401) {
      //401--------------------------------------------------------
    } else if (response.status === 504) {
      //504-------------------- ------------------------------------
      apiResponse.json = {
        message: '504: 接続タイムアウトです。'
      }
    }
  } catch (e) {
  } finally {
    if (isLoading) {
      yield put(loadingAction.offContinue())
    }
  }

  if (!apiResponse.isSuccess && isDisplayError) {
    const {message, messages} = apiResponse.json
    if (message || messages) {
      yield put(errorAction.displayError(message || messages))
      yield put(loadingAction.offContinue())
    }
  }

  return apiResponse
}
