import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import styled from 'styled-components'
import { withTranslation, composeInitialProps } from 'react-i18next'
import { reduxForm, Field, change, getFormValues } from 'redux-form'
import nl2br from 'react-nl2br'
import { actions as locationActions } from '../../../modules/location'
import ToggleTab from '../../../components/molecules/ToggleTab'
import * as Common from '../../../constants/common'
import { isEmpty, isEmptyObject } from '../../../util/common'
import moment from 'moment'
import { actions as memberActions } from '../../../modules/member'

class Edit extends Component {

  componentDidMount() {
    this.props.start()
  }

  render() {
    const {
      formValues = {},
      setFormValue,
      member,
      link,
      accountEdit,
    } = this.props

    // アカウント情報
    return (
      <Content>
        <div className="info-body">
          <Container>
            <Row>
              <div className="top">
                <Col xs="12">
                  <Label>お名前</Label>
                  <Field name="name" type="text" className="form-control" component="input" />
                </Col>
              </div>
              <Col xs="12">
                <Label>メールアドレス</Label>
                <Field name="email" type="text" className="form-control" component="input" />
              </Col>
              <Col xs="12">
                <Label>携帯電話</Label>
                <Field name="mobile_phone_num1" type="text" component="input"className="form-control" style={{ width: 'calc((100% - 40px) / 3)' }} />
                <span style={{display: 'inline-block', width: '20px', textAlign: 'center'}}>-</span>
                <Field name="mobile_phone_num2" type="text" component="input"className="form-control" style={{ width: 'calc((100% - 40px) / 3)' }} />
                <span style={{display: 'inline-block', width: '20px', textAlign: 'center'}}>-</span>
                <Field name="mobile_phone_num3" type="text" component="input"className="form-control" style={{ width: 'calc((100% - 40px) / 3)' }} />
              </Col>
              <Col xs="12">
                <Label>お住いの都道府県</Label>
                <Field name="address1" id="address1" component="select" className="form-control" >
                  <option value="北海道">北海道</option>
                  <option value="青森県">青森県</option>
                  <option value="岩手県">岩手県</option>
                  <option value="宮城県">宮城県</option>
                  <option value="秋田県">秋田県</option>
                  <option value="山形県">山形県</option>
                  <option value="福島県">福島県</option>
                  <option value="茨城県">茨城県</option>
                  <option value="栃木県">栃木県</option>
                  <option value="群馬県">群馬県</option>
                  <option value="埼玉県">埼玉県</option>
                  <option value="千葉県">千葉県</option>
                  <option value="東京都">東京都</option>
                  <option value="神奈川県">神奈川県</option>
                  <option value="新潟県">新潟県</option>
                  <option value="富山県">富山県</option>
                  <option value="石川県">石川県</option>
                  <option value="福井県">福井県</option>
                  <option value="山梨県">山梨県</option>
                  <option value="長野県">長野県</option>
                  <option value="岐阜県">岐阜県</option>
                  <option value="静岡県">静岡県</option>
                  <option value="愛知県">愛知県</option>
                  <option value="三重県">三重県</option>
                  <option value="滋賀県">滋賀県</option>
                  <option value="京都府">京都府</option>
                  <option value="大阪府">大阪府</option>
                  <option value="兵庫県">兵庫県</option>
                  <option value="奈良県">奈良県</option>
                  <option value="和歌山県">和歌山県</option>
                  <option value="鳥取県">鳥取県</option>
                  <option value="島根県">島根県</option>
                  <option value="岡山県">岡山県</option>
                  <option value="広島県">広島県</option>
                  <option value="山口県">山口県</option>
                  <option value="徳島県">徳島県</option>
                  <option value="香川県">香川県</option>
                  <option value="愛媛県">愛媛県</option>
                  <option value="高知県">高知県</option>
                  <option value="福岡県">福岡県</option>
                  <option value="佐賀県">佐賀県</option>
                  <option value="長崎県">長崎県</option>
                  <option value="熊本県">熊本県</option>
                  <option value="大分県">大分県</option>
                  <option value="宮崎県">宮崎県</option>
                  <option value="鹿児島県">鹿児島県</option>
                  <option value="沖縄県">沖縄県</option>
                </Field>
              </Col>
              <Col xs="12">
                <Label>各種情報のご連絡</Label>
                <div className="supplement">(フォーナインズより重要なお知らせがある場合は、この指定に限らずご連絡をさしあげる場合がございます。)</div>
                <div className="radio-form-container">

                  <Field name="notification_flag" type="radio" id="notification_flag_1" component="input" value="1" />
                  <label htmlFor={'notification_flag_1'}>希望する</label>

                  <Field name="notification_flag" type="radio" id="notification_flag_0" component="input" value="0" />
                  <label htmlFor={'notification_flag_0'}>希望しない</label>

                </div>
              </Col>
            </Row>
          </Container>
          <Button className="btn-large" block onClick={e => accountEdit()}>登録情報を変更する</Button>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues('sso_edit')(state),

    initialValues: {
      ...props.member,
    },
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    setFormValue: (key, value) => {
      dispatch(change('sso_edit', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    accountEdit: () => {
      dispatch(memberActions.updateSso())
    },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'sso_edit',
    initialValues: {
    }
  })(
    withTranslation()(
      Edit
    )
  )
)

const Content = styled.div`
margin: 0;
font-size: 18px;
background-color: #F5F5F2;

.top{
  border-top: 1px solid;
  border-color: #D8D8D8;
  width:100%;
  padding-top: 20px;
}

.button{
  background-color: black;
  color: white;
}

.info-body {
  margin: 0 26px;
  padding-bottom: 80px;

  .row > div {
    border-bottom: 1px solid;
    border-color: #D8D8D8;
    padding-bottom: 20px;

    margin-bottom: 21px;
  }
  .col-6:nth-child(2n+1) {
    padding-right: 2px;
  }
  .col-6:nth-child(2n) {
    padding-left: 2px;
  }

  .supplement{
    font-size: 12px;
    margin-bottom: 15px;
  }

  label {
    display: block;
    font-size: 15px;
    color: #6F6F6F;
    margin-bottom: 10px;
  }
  input,
  select {
    display: inline-block;
    font-size: 18px;
    background-color: #FFFF;
    border-radius: 4px;
    height: 46px;
    border: 0;
  }

  .radio-form-container{
    display: flex;
    justify-content: space-between;

    label{
      display: block;
      width: calc((100% - 20px) / 2);
      margin: 0;
      padding: 14px;
      border: 1px solid #CBCBCB;
      background: ${Common.COLOR_WHITE};
      color: ${Common.FONT_COLOR};
      font-size: 17px;
      text-align: center;
      transition: .1s;
      border-radius: 4px;
    }

    input[type=radio] {
      display: none; /* ラジオボタン非表示 */
    }
    input[type="radio"]:checked + label  {
      background: #BABABA;
      border-color: #BABABA;
    }
  }
}

.zip-search {
  display:inline-block;
  font-size: 15px;
  color: ${Common.FONT_COLOR};
  padding: 5px 10px;
  margin-top: -5px;
  float: right;
}

  .title{
    font-size: 1.2rem;
    text-align: center;
    margin: 0 15px;
    padding: 14px;
  }

  //入力フォームの縦幅を拡張
  .form-control {
    height: calc(2.5em + .75rem + 2px);
    background: #E9E9E8;
    border-radius: 5px;
    border: none;
  }

  // 性別
  .gender-form {
    padding: 0px 30px 0px 30px;

    label {
      display: block;
      text-align: left;
    }
  }

  // 名前
  .name-form {
    padding: 0px 30px 0px 30px;

    label {
      display: block;
      text-align: left;
      color: #AAAAAA;
    }
    .name-form-container {
      margin: 5px;
    }
  }

  // メールアドレス
  .mail-form {
    padding: 0px 30px 0px 30px;
    color: #AAAAAA;

    .mail-form-container {
      display: inline-block;
    }

    .mark {
      background-color: '#E9E9E8';
      padding: 0px 4px 0px 4px;
      font-size: x-large;
    }

    .mail-form-container-2 {
      display: inline-block;
    }
  }

  // 誕生日
  .birthday-title{

    label{
      padding: 15px 0px 0px 30px;
    }

    .birthday-form {
      padding: 0px 30px 0px 30px;
      width: 412px;
      display:flex;
      // justify-content: center; /* 子要素を中央揃え */

      .birthday-form-container-3{
        float: right; /* 右寄せ */
      }
    }
  }

  // 住所
　.address-form{
    padding: 0px 30px 0px 30px;

    .address-title-container{
      margin-top: 15px;

      label {
        margin-top: 0rem;
      }
      button {
        display:inline-block;
        font-size: 0.9rem;
        color: ${Common.COLOR_BLACK};
        background-color: ${Common.COLOR_WHITE};
        border-color: #ced4da;
        padding: 0.4rem 0.3rem;
        margin-top: -0.3rem;
        float: right;
      }
    }

    .address-flexbox{
      display: flex;
      margin-top: 5px;

      .dash-mark-container{
        font-size: 2rem;
      }

      address-form-container-2{
        float: right; /* 右寄せ */
      }
    }
  }

  // お住いの都道府県
  .refectures-form{
    padding: 0px 30px 0px 30px;

    .refectures-form-container {
      // margin-top: 15px;
      padding: 10px 0px 10px 0px;
    }
  }

  // 電話番号
  .tel-form {
    padding: 0px 30px 0px 30px;

    label{
      margin-top: 15px;
    }

    .tel-from-flexbox{
      display: flex;

      .tel-form-container {
      }

      .dash-mark-container {
        font-size: 2rem;
      }

      .tel-form-container-3 {
        float: right; /* 右寄せ */
      }
    }
  }

  // 通知設定
  .notification-form {
    padding: 0px 30px 0px 30px;

    .notification-form-container{
       label{
        margin: 12px 0px 0px 0px;
      }

      .radio-form-container{
        display: flex;

        .label{
          display: block;
          width: 170px;
          margin: 0 0 14 0;
          padding: 0 0 0 0;
          border: 1px solid #d7d7d7;
          background: ${Common.COLOR_WHITE};
          color: ${Common.COLOR_BLACK};
          font-size: 18px;
          text-align: center;
          line-height: 3;
          transition: .1s;
          border-radius: 8px;

        }

        .label-2 {
          display: block;
          width: 170px;
          margin: 10px 0 0 25px;
          padding: 0 0 0 0;
          border: 1px solid #d7d7d7;
          background: ${Common.COLOR_WHITE};
          color: ${Common.COLOR_BLACK};
          font-size: 18px;
          text-align: center;
          line-height: 3;
          transition: .1s;
          border-radius: 8px;

        }

        input[type=radio] {
          display: none; /* ラジオボタン非表示 */
        }
        input[type="radio"]:checked + label  {
          background: ${Common.COLOR_GLAY};/* マウス選択時の背景色を指定する */
          color: ${Common.COLOR_WHITE}; /* マウス選択時のフォント色を指定する */
        }
        input[type="radio"]:checked + label-2  {
          background: ${Common.COLOR_GLAY};/* マウス選択時の背景色を指定する */
          color: ${Common.COLOR_WHITE}; /* マウス選択時のフォント色を指定する */
        }
      }
    }
  }
  //編集完了ボタン
  .request{
    margin: 30px;
    text-align: center;
    margin-bottom: 0px;
    padding: 20px 0 50px 0px;

    button {
      font-size: 1.2rem;
      color: ${Common.COLOR_WHITE};
      background-color: #0000;
      padding: 1rem 1rem;
      border: none;
      text-align: center;
      border-radius: 5px;
      width: -webkit-fill-available
    }
  }

`
