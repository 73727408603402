import React, { Component } from 'react'
import { Link } from 'react-scroll'
import {
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
  Container,
} from 'reactstrap'
import styled from 'styled-components'
import { IMG_URL } from '../../constants/api-config'


class ProductSize extends Component {

  render() {
    return (
      <Content>
        <div className="back">
          <Button onClick={ () => this.props.history.goBack()}>
            <img src={`${IMG_URL}/img/ico/allow_left.png`}/>
          </Button>
        </div>
        <br/>
        <CardTitle>各種名称・サイズについて</CardTitle>
        <CardSubtitle>
          <Container>
            <Row>
              <Col><div className="metal">メタルフレーム</div></Col>
              <Col><div className="v_line_fix"></div></Col>
              <Col><div className="plastic"><Link
                activeClass="active"
                to="img2"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {800}
              >ネオプラスチック<br/>フレーム</Link></div></Col>
            </Row>
          </Container>
        </CardSubtitle>
        <div className="img1">
          <img src={`${IMG_URL}/img/ico/frame_metal.png`} />
        </div>

        <div className="img2">
          <img src={`${IMG_URL}/img/ico/frame_plastic.png`} />
        </div>
      </Content>
    )
  }
}

export default ProductSize

const Content = styled(CardBody)`
padding-top: 0;
padding-bottom: 0;

.img1{
    text-align: center;
    margin-top: 50px;
}
.img2{
    text-align: center;
    margin-top: 50px;
}
.back{
    border: none;
    float: left;
    margin: 0.8em 0.8em 0.8em 0;
}

.metal{
  white-space: nowrap;
  padding: 10px;
}

.plastic{
    color:#AAAAAA;
    white-space: pre;
}

.card-subtitle {
    text-align: center;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 3px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 3px;
    word-break: normal;
    weight: 50px;
}

.card-title {
    text-align: center;
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 11px;
    margin-top: 20px;
}

.v_line_fix {
  margin-left: 50%;
  width: 2px;
  height: 40px;
  background-color: #AAAAAA;
}
`
