import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import {
  Container,
  Row, 
  Col,
  Card,
  CardBody,
  Button,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'
import { actions as locationActions } from '../../modules/location'
import { actions as PurchaseHistoryAction } from '../../modules/purchaseHistory'
import { actions as authActions } from '../../modules/auth'
import ProductInfo from '../../components/molecules/ProductInfo'
import ProductImage from '../../components/molecules/ProductImage'
import ProductDetail from '../../components/molecules/ProductDetail'
import moment from 'moment'
import { IMG_URL } from '../../constants/api-config'
import { actions as notificationsAction } from '../../modules/informations'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'

class PurchaseHistory extends Component {
  componentWillMount() {
    // 購入履歴を取得
    this.props.getPurchaseHistory()
  }

  render() {
    const {
      purchaseHistories,
      hasMore,
      link,
      getPurchaseHistoryNext,
      isWeb,
      i18n,
    } = this.props

    const onClickHome = () => {
      if(isWeb){
        if(i18n.language === 'en'){
          window.location.href = HP_EN_URL
        }else{
          window.location.href = HP_URL
        }
      }else{
        link('/')
      }
    }

    const onClickMember = () => {
      link('/member')
    }

    return (
      <Content className="purchase_history">
        <div  className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem active>購入履歴</BreadcrumbItem>
          </Breadcrumb>
        </div>
        {/* <div className="back">
          <Button onClick={e => link('/member')}>
            <img src={`${IMG_URL}/img/ico/allow_left.png`}/>
          </Button>
        </div> */}
        <div className="contents-title">購入履歴</div>
        {/* <div className="contents-subtitle">購入履歴</div> */}
        <div>
          {/*NOTE:レンズなどの購入履歴も表示されるためfilterを行う*/}
          {purchaseHistories.map(history => history.sell_dtl.filter(item => item.line_num === 1).map(detail => (
            <Card key={history.id}>
              <ProductImage product={detail.product}/>
              <ProductInfo product={detail.product} isWeb={isWeb}/>

              <CardBody>
                <div className="sell-item">
                  <Container fluid={true}>
                    <Row>
                      <Col xs={4}>ご購入日</Col>
                      <Col xs={8}>{moment(history.record_date).format('YYYY / MM / DD')}</Col>
                    </Row>
                  </Container>
                </div>
                <div className="sell-item">
                  <Container fluid={true}>
                    <Row>
                      <Col xs={4}>お渡し日</Col>
                      <Col xs={8}>{moment(history.hand_over_date).format('YYYY / MM / DD')}</Col>
                    </Row>
                  </Container>
                </div>
                <div className="sell-item">
                  <Container fluid={true}>
                    <Row>
                      <Col xs={4}>ご購入店舗</Col>
                      <Col xs={8}>{history.sell_dept_name}</Col>
                    </Row>
                  </Container>
                </div>                
              </CardBody>
              <ProductDetail product={detail.product}/>
            </Card>
          )))}
        </div>
        {hasMore && (
          <div className="more" block onClick={e => {
            e.target.blur()
            getPurchaseHistoryNext()
          }}>VIEW MORE</div>
        )}
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem><a onClick={() => onClickHome()}>トップページ</a></BreadcrumbItem>
            <BreadcrumbItem><a onClick={() => onClickMember()}>マイアカウント</a></BreadcrumbItem>
            <BreadcrumbItem active>購入履歴</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  return {
    purchaseHistories: state.purchaseHistory.purchaseHistories || [],
    hasMore: state.purchaseHistory.purchaseHistoriesMeta.current_page !== 0 && state.purchaseHistory.purchaseHistoriesMeta.current_page < state.purchaseHistory.purchaseHistoriesMeta.last_page,
    authGlaviss: state.auth.glaViss,
    location: state.auth.location,
    isWeb: state.location.isWeb,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getPurchaseHistory: () => {
      dispatch(PurchaseHistoryAction.getPurchaseHistory())
    },
    getPurchaseHistoryNext: () => {
      dispatch(PurchaseHistoryAction.getPurchaseHistoryNext())
    },
    clickButton: () => {
      dispatch(locationActions.link('/size'))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    setLocation: (url) => {
      dispatch(authActions.setLocation(url))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    PurchaseHistory
  )
)

const Content = styled.div`
.contents-title {
  padding-bottom: 30px;
}

.card {
  padding: 24px 0;
  border-left: 0;
  border-right: 0;
}
.card:not(:last-child) {
  border-bottom: 0;
}

.product-image {
  margin-top: 0;
}

.card-body {
  padding-top: 0;
  padding-bottom: 0;
}
.sell-item {
  border-top: solid 1px #727272;
  margin: 0;
  padding: 20px 0 20px 8px;
  font-size: 15px;
  letter-spacing: 1.5px;
}
`
