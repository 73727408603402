import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { randomString } from '../../util/common'
import {
  SSO_CLIENT_ID,
  SSO_CALLBACK_URL,
  SSO_LOGIN,
} from '../../constants/makeShop'

class Login extends Component {
  componentDidUpdate() {
    if (this.form) {
      this.form.submit()
    }
  }

  render() {
    const {
      member_id,
    } = this.props

    const state = randomString(12)
    const nonce = randomString(12)
    return (
      <div>
        {!member_id && (
          <form method="POST" action={SSO_LOGIN} ref={ref => this.form = ref}>
            <input type="hidden" name="response_type" value="code"/>
            <input type="hidden" name="client_id" value={SSO_CLIENT_ID}/>
            <input type="hidden" name="redirect_uri" value={SSO_CALLBACK_URL}/>
            <input type="hidden" name="scope" value="openid"/>
            <input type="hidden" name="state" value={state}/>
            <input type="hidden" name="nonce" value={nonce}/>
          </form>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    member_id: state.auth.makeShop && state.auth.makeShop.member_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
