import { connect } from 'react-redux'
import React, { Component } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, Field, change, formValueSelector, getFormValues } from 'redux-form'
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosAdd,
  IoIosRemove,
} from 'react-icons/io'
import ToggleTab from '../../components/molecules/ToggleTab'
import Search from './Search'
import Favorites from './Favorites'
import { actions as ProductsAction } from '../../modules/products'

class Items extends Component {
  componentDidMount() {
    this.props.start()
    this.firstOpenTag(this.props.location.pathname)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.firstOpenTag(nextProps.location.pathname)
    }
  }

  firstOpenTag(pathname) {
    if (pathname === '/items') {
      this.props.set('isOpenSearch', true)
      this.props.set('isOpenFavorite', false)
    } else if (pathname === '/favorites') {
      this.props.set('isOpenSearch', false)
      this.props.set('isOpenFavorite', true)
    }
  }

  render() {
    const {
      isOpenSearch,
      isOpenFavorite,
      set,
    } = this.props

    return (
      <Content>
        <ToggleTab
          title="アイテムを探す"
          isOpen={isOpenSearch}
          OpenIcon={IoIosArrowDown}
          CloseIcon={IoIosArrowUp}
          toggle={(nextIsOpen) => set('isOpenSearch', nextIsOpen)}
        >
          <Search/>
        </ToggleTab>
        <ToggleTab
          title="お気に入り"
          isOpen={isOpenFavorite}
          OpenIcon={IoIosArrowDown}
          CloseIcon={IoIosArrowUp}
          toggle={(nextIsOpen) => set('isOpenFavorite', nextIsOpen)}
        >
          <Favorites/>
        </ToggleTab>
      </Content>
    )
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector('items')
  return {
    isOpenSearch: selector(state, 'isOpenSearch'),
    isOpenFavorite: selector(state, 'isOpenFavorite'),
  }
}
const mapDispatchToProps = dispatch => {
  return {
    start: () => {
    },
    set: (key, value) => {
      dispatch(change('items', key, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'items',
    initialValues: {
      isOpenSearch: true,
      isOpenFavorite: false,
    }
  })(
    withTranslation()(
      Items
    )  
  )
)

const Content = styled.div`
  > .toggle-tab {
    > .toggle-tab-title {
      font-size: 1.2rem;
      text-align: center;
      border-bottom: 0;
    }
  }

  .search-products {
    padding: 10px;
  }
  .favorites {
    padding: 10px;
  }
`
