import { createActions, handleActions } from 'redux-actions'
import { select, put, call, takeEvery } from 'redux-saga/effects'
import api from './api'
import { isEmpty } from '../util/common'
import { SSO_STORAGE, PRODUCTS } from '../constants/api-url'
import { waitAuth } from './auth'

/*********************************
 * ACTION CREATOR
 ********************************/
export const actions = createActions(
  {
    init: () => ({}),
    set: (key, value) => ({ key, value }),
    add: (payload) => payload,
    sub: (payload) => payload,
    toggle: (payload) => payload,
  },
  { prefix: '9999/favorites' }
)
/*********************************
 * REDUCER
 ********************************/
const initialState = {
  products: [],
  isSearching: true,
  showCountAdd: 0,
}
const reducer = handleActions(
  {
    [actions.init]: (state, action) => ({
      ...initialState,
      ...state,
      showCountAdd: initialState.showCountAdd,
    }),
    [actions.set]: (state, action) => ({
      ...state,
      isSearching: false,
      [action.payload.key]: action.payload.value,
    }),
    [actions.add]: (state, action) => {
      if (state.products.find((product) => product.id === action.payload.id)) {
        return state
      }

      return {
        ...state,
        products: [action.payload, ...state.products],
      }
    },
    [actions.sub]: (state, action) => {
      if (state.products.find((product) => product.id === action.payload.id)) {
        return {
          ...state,
          products: state.products.filter((product) => product.id !== action.payload.id),
        }
      }

      return state
    },
    [actions.toggle]: (state, action) => {
      if (state.products.find((product) => product.id === action.payload.id)) {
        return {
          ...state,
          products: state.products.filter((product) => product.id !== action.payload.id),
        }
      }

      return {
        ...state,
        products: [action.payload, ...state.products],
      }
    },
  },
  initialState
)

export default reducer

/***************************************************************
 *SAGA
 ***************************************************************/
export function* favoritesSaga() {
  yield takeEvery(actions.init, load)
  yield takeEvery(actions.add, save)
  yield takeEvery(actions.sub, save)
  yield takeEvery(actions.toggle, save)
}

function* load() {
  if (!(yield call(waitAuth))) {
    yield put(actions.set('products', []))
    return
  }

  const storageResult = yield call(api, {
    url: SSO_STORAGE,
    method: 'GET',
    request: {
      key: 'mypage.favorites',
    },
    isLoading: false,
    isDisplayError: false,
  })
  if (!storageResult.isSuccess) {
    yield put(actions.set('products', []))
    return
  }
  if (storageResult.json.storages.length === 0) {
    yield put(actions.set('products', []))
    return
  }

  // タイミングにより複数取れることがあるのでマージする
  // NOTE: emailを編集したあと、glaViss連携が起きた際など
  const unique_ids = []
  storageResult.json.storages.forEach((storage) => {
    const ids = JSON.parse(storage.value)
    ids.forEach((id) => {
      if (unique_ids.indexOf(id) < 0) {
        unique_ids.push(id)
      }
    })
  })
  if (unique_ids.length === 0) {
    yield put(actions.set('products', []))
    return
  }

  const productsResult = yield call(api, {
    url: PRODUCTS,
    method: 'GET',
    request: {
      id_in: unique_ids,
      q: '',
      page: 0,
    },
    isLoading: false,
  })
  if (!productsResult.isSuccess) {
    return
  }
  yield put(actions.set('products', productsResult.json.products))
}

function* save() {
  const {
    favorites: { products },
  } = yield select()

  const request = {
    key: 'mypage.favorites',
    value: JSON.stringify(products.map((product) => product.id)),
  }
  yield call(api, {
    url: SSO_STORAGE,
    method: 'POST',
    request,
    isLoading: false,
  })
}
