import React, { Component } from 'react'
import {
  Collapse,
} from 'reactstrap'

class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.firstOpen || false,
    };
  }

  close = () => {
    this.setState({isOpen: false})
  }

  render() {
    const {
      isOpen: isOpenLocal
    } = this.state

    const {
      isOpen: isOpenManual,
      toggle = (nextOpen) => { this.setState({isOpen: nextOpen}) },
      title,
      children,
      className = '',
      onEntering = () => {},
      onEntered = () => {},
      onExiting = () => {},
      onExited = () => {},
    } = this.props

    let isOpen = isOpenLocal
    if (isOpenManual !== void 0) {
      isOpen = isOpenManual
    }

    return (
      <div className={`toggle ${isOpen ? 'toggle-open' : 'toggle-close'} ${className}`}>
        <div className="toggle-title" onClick={e => toggle(!isOpen)}>
          {title}
        </div>
        <Collapse
          className="toggle-body"
          isOpen={isOpen}
          onEntering={onEntering}
          onEntered={onEntered}
          onExiting={onExiting}
          onExited={onExited}
        >
          {children}
        </Collapse>
      </div>
    )
  }
}

export default Toggle

