import { connect } from 'react-redux'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Container, Row, Col, Input, Label, InputGroup, InputGroupText, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { reset } from 'redux-form'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { reduxForm, Field, change, getFormValues } from 'redux-form'
import { IoIosSearch } from 'react-icons/io'
import nl2br from 'react-nl2br'
import { actions as locationActions } from '../../modules/location'
import Toggle from '../../components/atomics/Toggle'
import Products from '../../components/molecules/Products'
import { actions as productsActions } from '../../modules/products'
import { isEmptyObject } from '../../util/common'
import { media } from '../../components/atomics/Media'
import { IMG_URL } from '../../constants/api-config'
import { scroller } from 'react-scroll'
import queryString from 'query-string'
import { HP_URL, HP_EN_URL } from '../../constants/api-config'

class Search extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // 戻ってきたら検索しない
    // MEMO: HistoryBackで戻ったら history.action == POP になる。
    //　　　　一覧へで戻ったら isReturnItems == true で戻している。
    this.props.locationSet('isReturnItems', false)
    if (!isEmptyObject(this.props.searchedProducts)) {
      if (this.props.isReturnItems) {
        // スクロール位置を戻す
        // @@router/LOCATION_CHANGE イベントでスクロール位置を変えても、上手く行かない。
        window.scrollTo(0, this.props.referenceScroll)
        return
      } else if (this.props.history.action === 'POP') {
        return
      }
    }

    this.props.start()
    if (decodeURIComponent(window.location.search) === '' || !isEmptyObject(this.props.formValues)) {
      this.props.searchProduct({
        ...this.props.formValues,
      })
    } else {
      this.firstSearch()
    }
  }

  componentDidUpdate(prevProps) {
    // 検索項目のマスタ読み込みが遅れていたときに、マスタが揃ったタイミングで再検索
    if (decodeURIComponent(window.location.search) === '') {
      return
    }
    if (!isEmptyObject(prevProps.productSearchEntry1)) {
      return
    }
    if (isEmptyObject(this.props.productSearchEntry1)) {
      return
    }

    this.firstSearch()
  }

  // 初期検索
  firstSearch() {
    const parameters = this.getSelectedItem()
    for (const key in parameters) {
      this.props.set(key, parameters[key])
    }
    this.props.searchProduct(parameters)
  }

  // GETパラメータから検索項目を作成する
  getSelectedItem() {
    let obj = queryString.parse(window.location.search)

    let freeInput = obj.q
    if (Array.isArray(obj.q)) {
      freeInput = obj.q.join(' ')
    }

    let searchParams = {
      words: freeInput,
    }

    const { productSearchEntry1, productSearchEntry2, productSearchEntry3, productSearchEntry4 } = this.props
    const searchDetailTags = [productSearchEntry1, productSearchEntry2, productSearchEntry4, productSearchEntry3]

    searchDetailTags.forEach((entity) => {
      if (!isEmptyObject(obj[entity.class_name])) {
        searchParams[entity.class] = this.convertParams(entity, obj[entity.class_name])
      }
    })

    return searchParams
  }

  convertParams(productSearchEntry, obj) {
    const maxCode = productSearchEntry.codevalues.reduce((max, item) => Math.max(max, parseInt(item.cd)), 0)

    return productSearchEntry.codevalues.reduce((paramArr, item) => {
      if (typeof obj === 'string') {
        paramArr[parseInt(item.cd)] = obj === item.cd_name
      } else if (Array.isArray(obj)) {
        paramArr[parseInt(item.cd)] = obj.includes(item.cd_name)
      }
      return paramArr
    }, Array(maxCode + 1))
  }

  render() {
    const {
      formValues = {},
      set,
      link,
      searchProduct,
      searchProductNext,
      searchedProducts,
      isSearching,
      hasMore,
      productSearchEntry1,
      productSearchEntry2,
      productSearchEntry3,
      productSearchEntry4,
      resetForm,
      isWeb,
      t,
      i18n,
    } = this.props

    const searchDetailTags = [productSearchEntry1, productSearchEntry2, productSearchEntry4, productSearchEntry3]

    const onClickHome = () => {
      if (isWeb) {
        if (i18n.language === 'en') {
          window.location.href = HP_EN_URL
        } else {
          window.location.href = HP_URL
        }
      } else {
        link('/')
      }
    }

    /**
     * 検索フォーム変更時の処理
     */
    const changeForm = (e) => {
      searchProduct({
        ...this.props.formValues,
        [e.target.name]: e.target.value,
      })
    }

    /**
     * 詳細検索のボタンを押した時の処理
     */
    const onClickField = (group, item, value) => {
      // 一応ディープコピー
      let searchParams = JSON.parse(JSON.stringify(formValues))

      if (!searchParams[group.class]) {
        searchParams[group.class] = []
      }
      searchParams[group.class][item.cd] = value

      searchProduct(searchParams)
    }

    return (
      <Content className="search-products">
        <div className="list-sp">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t('商品検索')}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="contents-title">ITEM SEARCH</div>
        {i18n.language !== 'en' && <div className="contents-subtitle">商品検索</div>}
        <div className="free-word">
          <InputGroup>
            <InputGroupText className="search-icon">
              <IoIosSearch />
            </InputGroupText>
            <Input
              onChange={changeForm}
              name="words"
              tag={Field}
              component="input"
              placeholder={t('品番・フリーワードで検索')}
              className="search-word"
            />
          </InputGroup>
        </div>
        <div className="detail">
          <Toggle
            title={
              <>
                <div className="detail-search">{t('詳細検索')}</div>
                {formValues.isOpenSearchDetail && <img src="/images/arrow-up-small.svg" className="arrow-up" />}
                {!formValues.isOpenSearchDetail && <img src="/images/arrow-small.svg" className="arrow-down" />}
              </>
            }
            isOpen={formValues.isOpenSearchDetail}
            toggle={(nextIsOpen) => set('isOpenSearchDetail', nextIsOpen)}
            className="title"
            onExiting={() =>
              scroller.scrollTo('contents-title', {
                duration: 800,
                delay: 0,
                smooth: 'true',
              })
            }
          >
            {searchDetailTags
              .filter((group) => !isEmptyObject(group))
              .map((group) => (
                <div key={group.id}>
                  <div className="groupClass">
                    {/* <div className={`caption ${group.class}`} > */}
                    {i18n.language === 'en' ? nl2br(group.class_name_eng) : nl2br(group.class_name)}
                  </div>
                  <div className={`select ${group.class}`}>
                    <Container fluid={true}>
                      <Row xs={4} lg={6}>
                        {group.codevalues &&
                          group.codevalues.map((item) => (
                            <Col key={item.id}>
                              <Field
                                onChange={(e) => onClickField(group, item, e.target.checked)}
                                id={`item-search-${item.id}`}
                                name={`${group.class}[${item.cd}]`}
                                component="input"
                                type="checkbox"
                              />
                              <Label
                                htmlFor={`item-search-${item.id}`}
                                className={`${item.attribute2 ? 'with-image' : ''}`}
                              >
                                {/* item.attribute1 && (
                              <div
                                className="color"
                                style={{
                                  backgroundColor:item.attribute1,
                                  border: (item.attribute1 === Common.COLOR_LIGHT_GLAY) ? `1px solid ${Common.COLOR_GLAY}` : '0',
                                }}
                              />
                              ) */}
                                {item.attribute2 && (
                                  <div className="list-pc">
                                    <img className="style-img" src={IMG_URL + item.attribute2} alt={item.cd_name} />
                                  </div>
                                )}
                                <div className="lavel">
                                  {i18n.language === 'en' ? nl2br(item.cd_name_eng) : nl2br(item.cd_name)}
                                </div>
                              </Label>
                            </Col>
                          ))}
                      </Row>
                    </Container>
                  </div>
                </div>
              ))}
            <div className="detail-close" onClick={(e) => set('isOpenSearchDetail', false)}>
              <img className="arrow-up" src="/images/arrow-up-small.svg" />
              <div className="detail-search">{t('閉じる')}</div>
            </div>
          </Toggle>
        </div>
        <div className="result">
          {searchedProducts.length === 0 && isSearching === false && (
            <div className="not-found">{t('該当の商品はありませんでした。')}</div>
          )}
          <Products
            products={searchedProducts}
            isSearching={isSearching}
            hasMore={hasMore}
            onClickMore={() => searchProductNext(formValues)}
          />
        </div>
        <hr className="list-pc-line"></hr>
        <div className="list-pc">
          <Breadcrumb>
            <BreadcrumbItem>
              <a onClick={() => onClickHome()}>{t('トップページ')}</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t('商品検索')}</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues('search_products')(state),
    searchedProducts: state.products.searchedProducts || [],
    isSearching: state.products.isSearching || false,
    hasMore:
      state.products.searchedMeta.current_page !== 0 &&
      state.products.searchedMeta.current_page < state.products.searchedMeta.last_page,
    productSearchEntry1: state.master.productSearchEntry1 || {},
    productSearchEntry2: state.master.productSearchEntry2 || {},
    productSearchEntry3: state.master.productSearchEntry3 || {},
    productSearchEntry4: state.master.productSearchEntry4 || {},
    isWeb: state.location.isWeb,

    isReturnItems: state.location.isReturnItems,
    referenceScroll: state.location.referenceScroll,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    start: () => {
      dispatch(productsActions.init())
    },
    set: (key, value) => {
      dispatch(change('search_products', key, value))
    },
    link: (url) => {
      dispatch(locationActions.link(url))
    },
    searchProduct: (formValues) => {
      dispatch(productsActions.searchProduct(formValues))
    },
    searchProductNext: (formValues) => {
      dispatch(productsActions.searchProductNext(formValues))
    },
    detailedSearch: (value) => {
      dispatch(productsActions.detailedSearch(value))
    },
    clickButton: () => {
      dispatch(locationActions.link('/size'))
    },
    resetForm: (form) => {
      dispatch(reset(form))
    },
    locationSet: (key, value) => {
      dispatch(locationActions.set(key, value))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'search_products',
    initialValues: {
      words: '',
      isOpenSearchDetail: false,
    },
    destroyOnUnmount: false,
  })(withTranslation()(withRouter(Search)))
)

const Content = styled.div`
  .groupClass {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
  }
  .contents-title {
    margin-bottom: 0px;
  }

  > div {
    margin-bottom: 27px;
  }

  .name-and-size {
    margin: 5px 0 20px;
    text-align: center;

    a {
      color: black;
      font-size: 0.8rem;
    }
  }

  .groupClass {
    text-indent: 15px;
  }

  .free-word {
    border: 1px solid;
    border-color: #dddddb;
    width: 340px;
    margin: 0 auto;
    margin-bottom: 31px;
    flex-flow: row-reverse;

    .search-icon {
      margin-left: auto;
      padding-right: 17px;
      z-index: 2;
    }

    .search-word {
      position: absolute;
      float: left;
      padding-left: 20px;
      z-index: 1;
    }

    .input-group-text {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 0;
      background-color: #ffffff;
      color: #2b2b2b;
      font-size: 16px;
      height: 50px;
    }

    input {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 0;
      float: right;
      font-size: 16px;
      height: 50px;

      background-color: #ffffff;
      margin-right: auto;
      ::placeholder {
        color: #a7a7a7;
      }
    }
  }

  .toggle {
    border: 0;
  }
  .toggle-title {
    font-size: 13px;
    line-height: 15px;
    background-color: #ebebeb;
    text-align: center;
    height: 53px;

    img {
    }
  }
  .toggle-open .toggle-title {
    background-color: #ffffff;
  }
  .detail-close {
    font-size: 13px;
    line-height: 15px;
    background-color: #ebebeb;
    height: 53px;
    text-align: left;

    img {
    }
  }

  .detail {
    width: 100%;

    .detail-search {
      float: left;
      padding-top: 20px;
      margin-left: 20px;
    }
    .arrow-up {
      float: right;
      margin-top: 24px;
      margin-right: 22px;
    }
    .arrow-down {
      float: right;
      margin-top: 24px;
      margin-right: 22px;
    }
    .toggle-open {
      background-color: #ffffff;
      border: 0;
    }

    .toggle-body {
      .caption {
        font-size: 13px;
        margin: 14px 8px 6px;
      }

      .select {
        margin-bottom: 5px;

        .container-fluid {
          .col {
            padding: 1px;
          }
          .row {
            margin: 10px -15px 30px -15px;
          }
        }

        label {
          cursor: pointer;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          height: 60px;
          margin: 0;
          background-color: #ffffff;
          border: 1px solid;
          border-color: #dddddb;

          .color {
            position: absolute;
            left: 5px;
            display: inline-block;
            width: 10px;
            height: 10px;
          }
          .lavel {
            text-align: center;
            font-size: 12px;
            line-height: 10px;
            flex: 1;
          }
          .color + .lavel {
            margin-left: 5px;
          }
          .list-pc {
            max-width: calc(100% - 100px);
            margin-left: 20px;

            img {
              width: 100%;
            }
          }
        }
        input {
          display: none;

          :checked + label {
            background-color: #ebebeb;
          }
        }
      }
    }
  }

  .result {
    .caption {
      font-size: 19px;
      margin: 27px 0;
      text-align: center;
    }
    .not-found {
      text-align: center;
    }
    .products {
      cursor: pointer;
      margin: 0;
      width: 100vw;
    }
  }

  ${media.sm`
    .detail {
      .toggle-body {
        .select {
          .container-fluid {
            width: 100%;
            .row{
              margin: 10px -15px 30px -15px;
            }
          }
        }
      }
    }

    .result .products {
      margin: 0;
      width: 100%;
    }
  `}

  ${media.lg`
  .toggle {
    border: 0;
  }
  .toggle-title{
    font-size: 13px;
    line-height: 15px;
    background-color: #EBEBEB;
    text-align: center;
    height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      margin-top: 10px;
    }
  }
  .toggle-open .toggle-title{
    background-color: #FFFFFF;
  }
  .detail-close {
    font-size: 13px;
    line-height: 15px;
    background-color: #EBEBEB;
    text-align: center;
    height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      margin-bottom: 10px;
    }
  }

  .groupClass {
    font-size: 13px;    
    font-weight: bold;
  }
    ToggleTab{
      text-align:center
    }

    .detail {
      background-color: #ffffff;
      width: auto;
      .detail-search {
        padding-top: 0px;
        margin-left: 0px;
      }

      .arrow-up {
        margin-top: 11px;
        margin-right: 0px;
      }
      .arrow-down {
        margin-top: 11px;
        margin-right: 0px;
      }

      .toggle-open {
        border-bottom: 0;
      }
      
      .toggle-body {
  
        .caption {
          font-size: 13px;
          margin: 14px 8px 6px;
        }
  
        .select {
          margin-bottom: 5px;
  
          .container-fluid {
            width: 100%;
            .row{
              margin: 10px 0 30px 0;
            }
            .col {
              padding: 1px;
            }
          }
  
          label {
            cursor:pointer;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            height: 60px;
            margin: 0;
            background-color: #ffffff;
            border: 1px solid;
            border-color: #DDDDDB;
  
            .color {
              position: absolute;
              left: 5px;
              display: inline-block;
              width: 10px;
              height: 10px;
            }
            .lavel {
              text-align: center;
              font-size: 12px;
              line-height: 10px;
            }
            .color+.lavel {
              margin-left: 5px;
            }

            &.with-image .lavel {
              text-align: left;
              padding-left: 11px;
            }
          }
          input {
            display: none;
  
          }
        }
      }
    }

    .free-word {
      border: 1px solid;
      border-color: #DDDDDB;
      width: 340px; 
      margin-right: 0px;
      margin-bottom: 21px;
      flex-flow: row-reverse; 
  
      .search-icon{
        margin-left: auto;
        padding-right: 17px;
        z-index: 2;
      }
  
      .search-word{
        position: absolute;
        float: left;
        padding-left: 20px;
        z-index: 1;  
      }
  
      .input-group-text {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 0;
        background-color: #ffffff;
        color: #2b2b2b;
        font-size: 16px;
        height: 50px;
      }
  
      input {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 0;
        float: right;
        font-size: 16px;
        height: 50px;
  
        background-color: #ffffff;
        margin-right: auto;
        ::placeholder{
          color: #A7A7A7;
        }
      }
    }

    .select {
      margin-bottom: 5px;

      .container-fluid {

        .col {
          padding: 1px;
        }
      }
    }

  `}
`
